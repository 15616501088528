import React, {Component} from "react";
import SavedBadge from "../Badges";
import {Button, Jumbotron, Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSave, faUndo} from "@fortawesome/free-solid-svg-icons";
import AuthenticationService from "../../services/AuthenticationService";

export default class JumbotronDataEntry extends Component {
  async handleOnSave() {
    if (Array.isArray(this.props.handleOnSave)) {
      this.props.handleOnSave.forEach((f) => f());
    } else {
      this.props.handleOnSave();
    }
  }

  render() {
    const {user_group} = AuthenticationService.getData();
    return (
      <Jumbotron className="py-3">
        <h1>{this.props.title}</h1>
        <hr/>
        {user_group < 4 &&
          <h3>
          <Row className={"d-flex justify-content-between ml-0 mr-0"}>

          <SavedBadge isSaved={this.props.isSaved}/>
          <div>
          {this.props.handleOnCancel &&
          <Button
              className={"mr-2 btn-secondary"}
              disabled={this.props.isSaved}
              onClick={this.props.handleOnCancel}
          >
            <FontAwesomeIcon icon={faUndo}/> Annulla
          </Button>
          }
          <Button
          disabled={this.props.isSaved}
          onClick={this.handleOnSave.bind(this)}
          >
          <FontAwesomeIcon icon={faSave}/> Salva
          </Button>
          </div>
          </Row>
          </h3>
        }
      </Jumbotron>
    );
  }
}
