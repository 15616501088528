import React from "react";

import BooleanField from "./BooleanField";
import NumberField from "./NumberField"
import SelectField from "./SelectField";
import TextField from "./TextField";
import MinMaxRange from "./MinMaxRange";

export default function RenderField(props) {
  switch (props.type) {
    case "text":
      return <TextField {...props} />;

    case "number":
      return <NumberField {...props} />;

    case "boolean":
      return <BooleanField {...props} />;

    case "select":
      return <SelectField {...props} />;

    case "range":
      return <MinMaxRange {...props} />;

    default:
      throw new Error("Unknown field type");
  }
}
