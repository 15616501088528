/*
demandForecasting.jsx
cir_deploy
Author: Jacopo Gasparetto
Date: 20/07/2020
Organization: Energy Way S.r.l
*/
import React, {Component} from 'react';
import * as am4core from "@amcharts/amcharts4/core";
import {ValueAxis} from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import Carousel from "react-bootstrap/Carousel";
import "./demandForecasting.css"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronLeft, faChevronRight} from "@fortawesome/free-solid-svg-icons";
import DemandForecastingTable from "../Tables/DemandForecastingTable";
import {lineChartColors, lineChartColorsLight, lineChartColorsSuperLight} from "../../Utils/constants";
import {makeLineChart, createSeries} from "./LineChartSkeleton";
import {getUniqueProducts} from "../../Utils/commons";

am4core.useTheme(am4themes_animated);

class DemandForecastingChart extends Component {

  chartID = "demand-chart-div"

  constructor(props) {
    super(props);

    this.state = {
      index: 0,
      tableData: []
    }
  }


  handleSelect = (selectedIndex, _) => {
    this.setState({index: selectedIndex});
  };

  /* -------- Helpers ------- */

  sumBy(data, xKey, yKey, yKeyAp, yKeyApp, groupKey) {
    return data.reduce((acc, el) => {
      const x = el[xKey]
      const y = el[yKey]
      const yAp = el[yKeyAp]
      const yApp = el[yKeyApp]
      const yLabel = el[groupKey]
      const yLabelAp = `${yLabel}_ap`
      const yLabelApp = `${yLabel}_app`
      const groupLabel = el[groupKey]

      if (!(groupLabel in acc)) {
        acc[groupLabel] = {
          [x]: {
            [yLabel]: y,
            [yLabelAp]: yAp,
            [yLabelApp]: yApp
          }
        }

      } else if (!(x in acc[groupLabel])) {
        acc[groupLabel][x] = {
          [yLabel]: y,
          [yLabelAp]: yAp,
          [yLabelApp]: yApp
        }
      } else {
        acc[groupLabel][x][yLabel] += y
        acc[groupLabel][x][yLabelAp] += yAp
        acc[groupLabel][x][yLabelApp] += yApp
      }
      return acc
    }, {})
  }

  getChartData(data) {
    /*
        Returns a list ready for amCharts in for of:
            [{x0, v0, u0}, {x1, v1, u1}, ... , { })]
     */

    // FIXME: resolve these warnings
    let reducedData = Object.values(data).reduce((acc, obj, i) => {
      if (i === 0)
        return obj

      for (const key in obj) {
        acc[key] = Object.assign({}, obj[key], acc[key])   // TODO: can it be done better?
      }
      return acc
    }, {})

    return Object.entries(reducedData)
      .map(el => {
        return {x: new Date(el[0]), ...el[1]}
      })
    // .sort((a, b) => a.x > b.x)
  }

  removeAllSeries() {
    let l = this.chart.series.length
    if (l > 0) {
      for (let i = l - 1; i >= 0; i--) {
        this.chart.series.removeIndex(i).dispose();
      }
    }
  }

  drawChart(data) {
    const seriesNames = Object.keys(data)

    data = this.getChartData(data)
    let previousYearSeriesList = [];
    let previousPreviousYearSeriesList = [];

    // Create series
    for (let i = 0; i < seriesNames.length; i++) {
      let field = seriesNames[i]
      let name = this.props.groupKey === 'dettaglio' ? "Cumulato" : seriesNames[i]

      const s1 = createSeries(this.chart, this.valueAxis, field, name, lineChartColors[i])        // Current year series
      const s2 = createSeries(
        this.chart,
        this.valueAxis,
        `${field}_ap`,
        `${name}`,
        lineChartColorsLight[i],
        true,
        "5,4"
      )
      const s3 = createSeries(
        this.chart,
        this.valueAxis,
        `${field}_app`,
        `${name}`,
        lineChartColorsSuperLight[i],
        true,
        "2,4"
      )

      previousYearSeriesList.push(s2)
      previousPreviousYearSeriesList.push(s3)
      s1.events.on("hidden", function () {
        s2.hide();
        s3.hide();
      });

      s1.events.on("shown", function () {
        s2.show();
        s3.show();
      });

    }

    let pySeries = createSeries(
      this.chart, this.valueAxis,
      "void", "Anno -1",
      "#4c4c4c", false, "5,4"
    )
    pySeries.bullets.each(e => {e.copyToLegendMarker = false})

    for (let i = 0; i < previousYearSeriesList.length; i++) {
      pySeries.events.on("hidden", function () {
        previousYearSeriesList[i].hide();
      });

      pySeries.events.on("shown", function () {
        previousYearSeriesList[i].show();
      });
    }

    let ppySeries = createSeries(
      this.chart, this.valueAxis,
      "void", "Anno -2",
      "#4c4c4c", false, "2,4"
    )
    ppySeries.bullets.each(e => {e.copyToLegendMarker = false})

    for (let i = 0; i < previousPreviousYearSeriesList.length; i++) {
      ppySeries.events.on("hidden", function () {
        previousPreviousYearSeriesList[i].hide();
      });

      ppySeries.events.on("shown", function () {
        previousPreviousYearSeriesList[i].show();
      });
    }

    this.chart.data = data
  }

  draw() {
    this.removeAllSeries()
    let data = getUniqueProducts(this.props.data)

    console.log("Summing data")
    data = this.sumBy(
      data,
      "dat_forecast",
      "val_quantita",
      "val_quantita_ap",
      "val_quantita_app",
      this.props.groupKey
    )

    if (!this.props.disabledButtons.includes(this.props.groupKey)) {
      console.log("Drawing chart")
      this.drawChart(data);
    } else {
      this.drawChart([]);
      console.log('do not draw chart: too many dimensions')
    }

    this.setState({
      tableData: this.tableData(data)
    })
    console.log("All data drawn")
  }


  tableData(data) {
    console.log(data)
    return Object.keys(data).reduce((acc, key) => {
      let dates = Object.keys(data[key])
      for (let date of dates) {
        let entry = {
          id: `${date}_${key}`,
          series_name: key,
          date: date,
          val_quantita: data[key][date][key],
          val_quantita_ap: data[key][date][`${key}_ap`],
          val_quantita_app: data[key][date][`${key}_app`]
        }

        acc.push(entry)
      }
      return acc
    }, [])
  }

  /* -------- Life Cycle ------- */

  componentDidMount() {
    this.chart = makeLineChart(this.chartID, true)
    this.valueAxis = this.chart.yAxes.push(new ValueAxis());
    this.valueAxis.title.text = "Quantità (KG)"

    if (this.props.data.length > 0)
      this.draw()
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      prevProps.data.length !== this.props.data.length ||
      prevProps.groupKey !== this.props.groupKey ||
      prevProps.day !== this.props.day ||
      prevProps.runType !== this.props.runType
    )
      this.draw()
  }

  componentWillUnmount() {
    if (this.chart) {
      this.chart.dispose();
    }
  }

  /* -------- Render ------- */

  render() {
    return (
      <div style={{width: "100%", height: "600px"}}>
        <Carousel
          interval={9e6}
          activeIndex={this.state.index}
          onSelect={this.handleSelect}
          style={{width: "100%", height: "600px"}}
          nextIcon={<FontAwesomeIcon icon={faChevronRight} style={{color: "#404040"}}/>}
          prevIcon={<FontAwesomeIcon icon={faChevronLeft} style={{color: "#404040"}}/>}
        >
          <Carousel.Item style={{alignContent: "center"}}>
            <div>
              <div
                id={this.chartID}
                style={{
                  width: "80%", margin: "auto",
                  height: !this.props.disabledButtons.includes(this.props.groupKey) ? '500px' : '260px',
                  visibility: !this.props.disabledButtons.includes(this.props.groupKey) ? 'visible' : 'hidden'
                }}
              />
              {this.props.disabledButtons.includes(this.props.groupKey) &&
              <div style={{textAlign: "center"}}>
                Il grafico è disponibile se sono filtrati fino a <b>5 elementi</b> della dimensione. <br/>
                È comunque possibile consultare il <b>dato tabellare</b>
              </div>
              }
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div style={{width: "80%", margin: "auto"}} className={"mt-3"}>
              <DemandForecastingTable
                data={this.state.tableData}
                productArticles={this.props.productArticles}
                groupKey={this.props.groupKey}
              />
            </div>
          </Carousel.Item>

        </Carousel>
      </div>
    )
  }
}

export default DemandForecastingChart