/*
parametri.jsx
cir_deploy
Author: Jacopo Gasparetto
Date: 14/07/2020
Organization: Energy Way S.r.l
*/

import React from "react";
import JumbotronDataEntry from "../../components/Jumbotron/DataEntry";
import Container from "react-bootstrap/Container";
import BaseComponent from "../../components/BaseComponent";
import 'bootstrap/dist/css/bootstrap.css';
import {Col, Row, Form, FormControl, FormGroup} from 'react-bootstrap'
import ApiService from "../../services/ApiService";


class DataEntryParametri extends BaseComponent {

    /* -------- Init ------- */

    constructor(props) {
        super(props);

        this.state = {
            data: {
                max_pallet_freschi: 0,
                max_pallet_secchi: 0,
                max_pallet_surgelati: 0,
                peso_volume_valore: 0,
                peso_frequenza_ordini: 0,
            },
            lastUpdate: new Date(),
            isSaved: true
        }

        this.peso_frequenza_ordini_map = {
            0: "bassa",
            1: "media",
            2: "alta"
        }

        this.peso_volume_valore_map = {
            0: "peso",
            1: "bilanciato",
            2: "valore"
        }

        // String to integer
        this.peso_frequenza_ordini_map_reverse = Object.assign(
            {}, ...Object.entries(this.peso_frequenza_ordini_map).map(([a, b]) => ({[b]: a}))
        )

        this.peso_volume_valore_map_reverse = Object.assign(
            {}, ...Object.entries(this.peso_volume_valore_map).map(([a, b]) => ({[b]: a}))
        )
    }

    /* -------- Handlers ------- */


    onChangeHandler(name, value) {
        let data = this.state.data
        data[name] = Number(value)

        this.setState(state => ({
            data: data,
            isSaved: false
        }))
    }

    onSaveHandler() {
        console.log("Saving...")

        let data = JSON.parse(JSON.stringify(this.state.data))
        data.peso_volume_valore = this.peso_volume_valore_map[data.peso_volume_valore]
        data.peso_frequenza_ordini = this.peso_frequenza_ordini_map[data.peso_frequenza_ordini]

        ApiService.updateEntry('parametri', undefined, data)
            .then(() => {
                console.log("Successfully saved")
                this.setState({
                    isSaved: true,
                    lastUpdate: new Date()
                })
            }).catch(err => console.log(err))
    }

    /* -------- Life Cycle ------- */

    componentDidMount() {
        ApiService.getEntries('parametri')
            .then(result => {
                result = result[0]
                const data = {
                    max_pallet_freschi: result.max_pallet_freschi,
                    max_pallet_secchi: result.max_pallet_secchi,
                    max_pallet_surgelati: result.max_pallet_surgelati,
                    peso_volume_valore: this.peso_volume_valore_map_reverse[result.peso_volume_valore],
                    peso_frequenza_ordini: this.peso_frequenza_ordini_map_reverse[result.peso_frequenza_ordini]
                }
                this.setState({
                    data: data
                })
            })
    }

    render() {
        const {
            max_pallet_freschi,
            max_pallet_secchi,
            max_pallet_surgelati,
            peso_volume_valore,
            peso_frequenza_ordini,
        } = this.state.data;

        const {
            isSaved,
            lastUpdate
        } = this.state

        return (
            <Container fluid className="mt-3">
                <JumbotronDataEntry
                    title={"Parametri Modello"}
                    isSaved={isSaved}
                    handleOnSave={this.onSaveHandler.bind(this)}
                    lastUpdate={lastUpdate}
                />
                <Form.Row style={{margin: '3vw'}}>
                    <Col>
                        <Form.Group style={{marginLeft: '4vw', marginRight: '3vw'}}>
                            <Form.Label class={"font-weight-bold"}>Massimo Pallet Freschi e Deperibili</Form.Label>
                            <Form.Control
                                name={"max_pallet_freschi"}
                                required
                                type={"number"}
                                value={max_pallet_freschi}
                                onChange={event => this.onChangeHandler(event.target.name, event.target.value)}
                            />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group style={{marginLeft: '4vw', marginRight: '3vw'}}>
                            <Form.Label class={"font-weight-bold"}>Massimo Pallet Secchi</Form.Label>
                            <Form.Control
                                name={"max_pallet_secchi"}
                                required
                                type={"number"}
                                value={max_pallet_secchi}
                                onChange={event => this.onChangeHandler(event.target.name, event.target.value)}
                            />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group style={{marginLeft: '4vw', marginRight: '3vw'}}>
                            <Form.Label class={"font-weight-bold"}>Massimo Pallet Surgelati</Form.Label>
                            <Form.Control
                                name={"max_pallet_surgelati"}
                                required
                                type={"number"}
                                value={max_pallet_surgelati}
                                onChange={event => this.onChangeHandler(event.target.name, event.target.value)}
                            />
                        </Form.Group>
                    </Col>
                </Form.Row>
                <br/>
                <Form.Row style={{fontSize: 'large'}}>
                    <Col style={{marginLeft: '8vw', marginRight: '8vw'}}>
                        <Form.Group>
                            <Form.Label class={"font-weight-bold"}>Minimizzazione volume e valore economico</Form.Label>
                            <Form.Control
                                name={"peso_volume_valore"}
                                type="range"
                                min={0}
                                max={2}
                                value={peso_volume_valore}
                                onChange={event => this.onChangeHandler(event.target.name, event.target.value)}
                            />
                            <Row>
                                <Col>KG</Col>
                                <Col align={"center"}>Bilanciato</Col>
                                <Col align={"right"}>€</Col>
                            </Row>
                        </Form.Group>
                    </Col>
                    <Col style={{marginLeft: '8vw', marginRight: '8vw'}}>
                        <FormGroup>
                            <Form.Label class={"font-weight-bold"}>Frequenza degli Ordini</Form.Label>
                            <FormControl
                                name={"peso_frequenza_ordini"}
                                type={"range"}
                                min={0}
                                max={2}
                                value={peso_frequenza_ordini}
                                onChange={event => this.onChangeHandler(event.target.name, event.target.value)}
                            />
                            <Row>
                                <Col>Bassa</Col>
                                <Col align={"center"}>Media</Col>
                                <Col align={"right"}>Alta</Col>
                            </Row>
                        </FormGroup>
                    </Col>
                </Form.Row>
            </Container>
        )
    }
}

export default DataEntryParametri