import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import {csvFloatFormatter, numberFormatter} from "../../Utils";
import ButtonExportCSV from "../Buttons/ExportCSVButton";

const DemandForecastingTable = (props) => {

  const getColumns = () => {
    let columns = [
      {
        dataField: "id",
        text: "product_id",
        hidden: true,
        csvExport: false
      }]

    if (props.groupKey === 'articolo') {
      columns = columns.concat([
        {
          dataField: "articoli",
          text: "Articoli",
          hidden: true,
        }
      ])
    }
    columns = columns.concat([
      {
        dataField: "series_name",
        text: "Dettaglio",
        csvFormatter: cell => {
          return ['daily', 'monthly', 'weekly'].includes(cell) ? 'Cumulato' : cell
        },
        formatter: cell => {
          return ['daily', 'monthly', 'weekly'].includes(cell) ? 'Cumulato' : cell
        }
      },
      {
        dataField: "date",
        text: "Data",
        type: "number",
        headerStyle: {width: "12%"},
      },
      {
        dataField: "val_quantita",
        text: "Quantità (KG)",
        type: "number",
        headerStyle: {width: "12%"},
        csvFormatter: csvFloatFormatter,
        formatter: cell => {
          return numberFormatter(cell.toFixed(2), ".")
        }
      },
      {
        dataField: "val_quantita_ap",
        text: "Qta Anno -1 (KG)",
        type: "number",
        headerStyle: {width: "15%"},
        csvFormatter: csvFloatFormatter,
        formatter: cell => {
          return numberFormatter(cell.toFixed(2), ".")
        }
      },
      {
        dataField: "val_quantita_app",
        text: "Qta Anno -2 (KG)",
        type: "number",
        headerStyle: {width: "15%"},
        csvFormatter: csvFloatFormatter,
        formatter: cell => {
          return numberFormatter(cell.toFixed(2), ".")
        }
      }
    ])
    return columns
  }

  const paginatorOptions = {
    sizePerPage: 8,
    hideSizePerPage: true,
  };

  const _data = props.groupKey === 'articolo'
    ? props.data.map(i => ({...i, articoli: props.productArticles[i['series_name']]}))
    : props.data

  return (
    <ToolkitProvider
      keyField="id"
      data={_data}
      columns={getColumns()}
      exportCSV={{
        fileName: 'demand_forecasting.csv',
        separator: ';',
        onlyExportFiltered: true
      }}
    >
      {innerProps => (
        <div>

          <BootstrapTable
            bootstrap4
            {...innerProps.baseProps}
            pagination={paginationFactory(paginatorOptions)}
          />
          <ButtonExportCSV
            className={"export-csv-button"}
            {...innerProps.csvProps}
            disabled={props.data.length === 0}
          >
            Esporta CSV
          </ButtonExportCSV>
        </div>
      )}
    </ToolkitProvider>
  )
};

export default DemandForecastingTable;