/*
ButtonClearFilters.jsx
cir_deploy
Author: Jacopo Gasparetto
Date: 06/08/2020
Organization: Energy Way S.r.l
*/
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEraser} from "@fortawesome/free-solid-svg-icons";
import {Button} from "react-bootstrap";
import React from "react";

export default function(props) {
  return (
  <Button
    variant="secondary"
    { ...props }
  >
    <FontAwesomeIcon icon={faEraser}/> Cancella tutti i filtri
  </Button>
  )
}