/*
Loading.jsx
cir_deploy
Author: Jacopo Gasparetto
Date: 04/08/2020
Organization: Energy Way S.r.l
*/

import Spinner from "react-bootstrap/Spinner";
import {Row} from "react-bootstrap";
import React from "react";

export const LoadingSpinner = () => {
  return (
  <Row className={"justify-content-center align-items-center h-100"}>
      <h1>Caricamento...</h1>
      <Spinner className={"ml-2"} animation={"border"}/>
  </Row>
  )
}