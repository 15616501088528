import React from "react";
import { version } from "../../release.json"

function Footer() {
  return (
    <div
      className="w-100 bg-light text-center"
      style={{ position: "fixed", bottom: 0, zIndex: 999, height: "2em" }}
    >
      <p style={{ marginTop: "0.6em", fontSize: "11px" }}>Cirfood Inventory Optimization | version {version} | Powered by <a href='https://www.ammagamma.com' target="_blank" rel="noopener noreferrer" >Ammagamma</a></p>
    </div>
  );
}

export default Footer;
