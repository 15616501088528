/*
AdminUsersTable.jsx
cir_deploy
Author: Jacopo Gasparetto
Date: 22/09/2020
Organization: Energy Way S.r.l
*/
import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUserEdit} from "@fortawesome/free-solid-svg-icons";
import cellEditFactory, {Type} from 'react-bootstrap-table2-editor';
import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory, {textFilter, selectFilter} from "react-bootstrap-table2-filter";


import "./AdminUsersTable.css"

export default function AdminUsersTable(props) {

  let selected = props.service.selectedUsers;


  const user_group_map = {
    1: "Amministratore",
    2: "Super User",
    3: "Operative User",
    4: "Audit User"
  }

  const editableCellFormatter = (cell) => {
    return (
      <span>
        <span className="text-primary">
          <FontAwesomeIcon icon={faUserEdit} size="xs"/>
        </span>
        {" "}{user_group_map[cell]}
      </span>
    );
  }

  const columns = [
    {
      dataField: "email",
      text: "Email",
      type: "string",
      sort: true,
      editable: false,
      filter: textFilter({
        placeholder: "...",
      }),
    },
    {
      dataField: "username",
      text: "Username",
      type: "string",
      sort: true,
      editable: false,
      filter: textFilter({
        placeholder: "...",
      }),
    },
    {
      dataField: "user_group",
      text: "Gruppo",
      type: "string",
      sort: true,
      formatter: cell => editableCellFormatter(cell),
      headerStyle: {width: '20%'},
      filter: selectFilter({
        options: Object.keys(user_group_map).map(key => {
          return {value: key, label: user_group_map[key]}
        }),
        placeholder: "...",
      }),
      editor: {
        type: Type.SELECT,
        options: Object.keys(user_group_map).map(key => {
          return {value: key, label: user_group_map[key]}
        })
      }
    }
  ]

  const onStartEdit = () => {
    Array.from(document.getElementsByClassName("react-bootstrap-table-editing-cell")).forEach(el => {
      el.addEventListener("change", event => {event.target.blur()})
    })
  }
  const afterSaveCell = (oldValue, newValue, row) => {
    props.service.updateUser(oldValue, newValue, row)
  }

  const handleOnSelect = (row, isSelect) => {
    selected = isSelect ? [...selected, row] : selected.filter(el => el.email !== row.email)
    props.service.selectedUsers = selected;
  }

  const handleOnSelectAll = (areSelected, rows) => {
    selected = areSelected ? rows : []
    props.service.selectedUsers = selected;
  }

  const selectRow = {
    mode: 'checkbox',
    clickToSelect: false,
    style: {width: "100px"},
    onSelect: handleOnSelect,
    onSelectAll: handleOnSelectAll,
    selected: selected.map(r => r.email)
  };

  const cellEdit = {
      mode: 'click',
      blurToSave: true,
      onStartEdit,
      afterSaveCell,
    }


  return (
    <BootstrapTable
      bootstrap4
      keyField={"email"}
      columns={columns}
      data={props.service.users}
      cellEdit={cellEditFactory(cellEdit)}
      selectRow={selectRow}
      pagination={paginationFactory()}
      filter={filterFactory()}
    />
  )
}