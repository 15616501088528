/*
index.jsx
cir_deploy
Author: Jacopo Gasparetto
Date: 28/09/2020
Organization: Energy Way S.r.l
*/
import React from "react";
import AuthenticationService from "../../services/AuthenticationService";
import moment from "moment";
import {Container, Jumbotron, Row, Col, Form, Button} from "react-bootstrap";
import {faUserCircle} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {ModalChangePassword} from "../../components/Modals/ModalChangePassword";

export default class Profile extends React.Component {

  TIMES = {
    morning: "05:00",
    noon: "13:00",
    evening: "18:00"
  }

  constructor(props) {
    super(props);

    this.state = {
      showNewPassword: false
    }
  }

  getWelcomeMessage() {
    const now = moment();
    const morningTime = moment(this.TIMES.morning, "hh:mm");
    const noonTime = moment(this.TIMES.noon, "hh:mm");
    const eveningTime = moment(this.TIMES.evening, "hh:mm");

    if (now.isBetween(morningTime, noonTime))
      return "Buongiorno"
    else if (now.isBetween(noonTime, eveningTime))
      return "Buon pomeriggio"
    else
      return "Buonasera"
  }

  toggleShowNewPassword() {
    this.setState({showNewPassword: !this.state.showNewPassword})
  }

  render() {
    const {email, username} = AuthenticationService.getData()
    const welcomeMessage = this.getWelcomeMessage()

    const {showNewPassword} = this.state;

    return (
      <Container fluid className={"mt-4"}>
        <Jumbotron className={"py-3"}>
          <Row>
            <div>
              <FontAwesomeIcon
                icon={faUserCircle}
                style={{fontSize: "4em"}}
              />
            </div>
            <div className={"ml-4"}>
              <h1>{welcomeMessage}, {username}</h1>
              <span>Profilo utente</span>
            </div>
          </Row>
        </Jumbotron>
        <hr/>

        <ModalChangePassword
          show={showNewPassword}
          onHide={this.toggleShowNewPassword.bind(this)}
          email={email}
          username={username}
        />

        <Col lg={4}>
          <Form>
            <Form.Group controlId={"user-email"}>
              <Form.Label>Email</Form.Label>
              <Form.Control disabled value={email}/>
            </Form.Group>
            <Form.Group controlId={"user-name"}>
              <Form.Label>Username</Form.Label>
              <Form.Control disabled value={username}/>
            </Form.Group>
          </Form>
          <Button onClick={_ => this.toggleShowNewPassword()}>
            Modifica password
          </Button>
        </Col>
      </Container>
    )
  }
}