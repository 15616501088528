/*
Author: Jacopo Gasparetto
Date: 13/07/2020
Filename: destinatari.jsx
Description:
*/
import React from "react";
import JumbotronDataEntry from "../../components/Jumbotron/DataEntry";
import Container from "react-bootstrap/Container";
import BaseComponent from "../../components/BaseComponent";
import DataEntryService from "../../services/DataEntryService";
import DestinatariTable from "../../components/Tables/DestinatariTable";

class DataEntryDestinatari extends BaseComponent {

    constructor(props) {
        super(props);

        [this.dataentryService] = this.props.states;
    }

    render() {
        return (
            <Container fluid className="mt-3">
                <JumbotronDataEntry
                    title="Anagrafica Destinatari"
                    isSaved={this.dataentryService.isDestinatariUpdated()}
                    lastUpdate={this.dataentryService.state.lkp_articoli.lastUpdate}
                    handleOnSave={this.dataentryService.updateModifiedDestinatari.bind(this)}
                />
                <DestinatariTable/>
            </Container>
        );
    }
}

export default DataEntryDestinatari.withStates([DataEntryService]);