import React from "react";
import BaseComponent from "./components/BaseComponent";
import { BrowserRouter as Router, Route, Redirect } from "react-router-dom";
import "./App.css";
import { PrivateRoute } from "./components/PrivateRoute";
import Sidenav from "./components/Sidenav";

import Home from "./containers/Home";
import Login from "./containers/Login";
import DemandForecasting from "./containers/DemandForecasting";
import InventoryOptimization from "./containers/InventoryOptimization";
import InventoryAlert from "./containers/InventoryAlert";
import DataEntryArticoli from "./containers/Dataentry/articoli";
import DataEntryBancalizzazione from "./containers/Dataentry/bancalizzazione";
import DataEntryDestinatari from "./containers/Dataentry/destinatari";
import DataEntryParametri from "./containers/Dataentry/parametri";
import AdminPanel from "./containers/Admin";
import Profile from "./containers/Profile";

import styled from "styled-components";
import Footer from "./components/Footer";

const Main = styled.main`
  position: relative;
  transition: all 0.15s;
  padding: 0 20px;
  margin-left: ${(props) => (props.expanded ? 240 : 64)}px;
`;

class App extends BaseComponent {
  state = {
    expanded: false,
  };

  toggle = (expanded) => {
    this.setState({ expanded: expanded });
  };

  render() {
    return (
      <>
        <Router>
          <PrivateRoute
            exact
            path="/"
            component={() => <Redirect to={{ pathname: "/home" }} />}
          />
          <Route path="/login" component={Login} />

          <Route
            path={[
              "/home",
              "/demand",
              "/new_demand",
              "/inventory",
              "/alert",
              "/dataentry",
              "/profile",
              "/admin",
            ]}
            render={({ location, history }) => (
              <React.Fragment>
                <Sidenav
                  location={location}
                  history={history}
                  toggle={this.toggle}
                />
                <Main
                  expanded={this.state.expanded}
                  style={{ paddingBottom: "10vh" }}
                >
                  <PrivateRoute path="/home" component={Home} />
                  <PrivateRoute path="/demand" component={DemandForecasting} />
                  <PrivateRoute
                    path="/inventory"
                    component={InventoryOptimization}
                  />
                  <PrivateRoute path="/alert" component={InventoryAlert} />
                  <PrivateRoute
                    path="/dataentry/articoli"
                    component={DataEntryArticoli}
                  />
                  <PrivateRoute
                    path="/dataentry/bancalizzazione"
                    component={DataEntryBancalizzazione}
                  />
                  <PrivateRoute
                    path={"/dataentry/destinatari"}
                    component={DataEntryDestinatari}
                  />
                  <PrivateRoute
                    path={"/dataentry/parametri"}
                    component={DataEntryParametri}
                  />
                  <PrivateRoute
                    path={"/profile"}
                    component={Profile}
                  />
                  <PrivateRoute
                    path={"/admin"}
                    component={AdminPanel}
                    level={1}
                  />
                </Main>
                <Footer />
              </React.Fragment>
            )}
          />
        </Router>
      </>
    );
  }
}

export default App;
