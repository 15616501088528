/*
RangeMinMax.jsx
cir_deploy
Author: Jacopo Gasparetto
Date: 18/09/2020
Organization: Energy Way S.r.l
*/

import React, {useState, useRef} from "react";
import {Col, Form, Overlay, Tooltip} from "react-bootstrap";

export default function MinMaxRange(props) {

  const TIMEOUT = 3500; // ms
  const timers = {min: null, max: null}

  const minTarget = useRef(null);
  const maxTarget = useRef(null);

  const fieldPath = (type) => {
    const name = [...props.fieldPath];
    name[2] = `${name[2]}_${type}`; // E.g. val_peso_max
    return name
  }

  const minFieldPath = fieldPath("min");
  const maxFieldPath = fieldPath("max");

  let minValue = props.service.getField(minFieldPath);
  let maxValue = props.service.getField(maxFieldPath);

  const [showMinTooltip, setShowMinTooltip] = useState(false);
  const [showMaxTooltip, setShowMaxTooltip] = useState(false);

  const setShowTooltip = {min: setShowMinTooltip, max: setShowMaxTooltip}

  function toggleTooltip(name) {
    clearTimeout(timers[name]);
    setShowTooltip[name](true);
    timers[name] = setTimeout(_ => setShowTooltip[name](false), TIMEOUT);
  }

  const onChangeMin = (value) => {
    let min;

    if (value !== "") {
      min = value;
      min = Math.max(0, min);

      if (typeof (maxValue) == "number" && min > maxValue) {
        min = Math.min(min, maxValue);
        toggleTooltip("min");
      }
    } else {
      min = null;
    }
    props.service.setField(minFieldPath, min)
  }

  const onChangeMax = (value) => {
    let max;
    if (value !== "") {
      max = value;
      max = Math.max(0, max);

      if (typeof (minValue) == "number" && value < minValue) {
        max = Math.max(minValue, max);
        toggleTooltip("max");
      }
    } else {
      max = null;
    }
    props.service.setField(maxFieldPath, max);
  }

  minValue = typeof (minValue) == "number" ? minValue : "";
  maxValue = typeof (maxValue) == "number" ? maxValue : "";

  return (
    <>
      <>
        <Col>
          <Form.Group controlId={`${props.fieldPath[2]}-min`.replace("_", "-")}>
            <Form.Label>{props.label ? `min ${props.label}` : "min"}</Form.Label>
            <Form.Control
              ref={minTarget}
              size={"sm"}
              type={"number"}
              value={minValue}
              onChange={e => onChangeMin(e.target.value)}
              disabled={props.disabled}
              required={props.required ? props.required : undefined}
            />
            <Form.Control.Feedback type="invalid">
              Inserire almeno un campo
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId={`${props.fieldPath[2]}-max`.replace("_", "-")}>
            <Form.Label>{props.label ? `max ${props.label}` : "max"}</Form.Label>
            <Form.Control
              ref={maxTarget}
              size={"sm"}
              type={"number"}
              value={maxValue}
              onChange={e => onChangeMax(e.target.value)}
              disabled={props.disabled}
              required={props.required ? props.required : undefined}
            />
            <Form.Control.Feedback type="invalid">
              Inserire almeno un campo
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </>
      <>
        <Overlay
          target={minTarget.current}
          show={showMinTooltip}
          placement={'bottom'}
        >
          <Tooltip id={'min-tooltip'}>
            La quantità minima non può essere maggiore della quantità massima
          </Tooltip>
        </Overlay>
        <Overlay
          target={maxTarget.current}
          show={showMaxTooltip}
          placement={'bottom'}
        >
          <Tooltip id={'max-tooltip'}>
            La quantità massima non può essere inferiore alla quantità minima
          </Tooltip>
        </Overlay>
      </>
    </>
  )
}