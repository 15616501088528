import axios from "axios";
import AuthService from "../AuthenticationService";
import {API_URL} from "../../Utils/constants";

export default class ApiService {

  static config = {
    headers: { ...AuthService.authHeader()}
  }

  static async getEntries(typeObject, args) {
    const baseUrl = `${API_URL}/${typeObject}/`;
    const url = args === undefined ? baseUrl : baseUrl + `${args}`;
    return await axios
      .get(url, this.config)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  }

  static async getEntriesWithData(typeObject, data) {
    const baseUrl = `${API_URL}/${typeObject}/`;
    return await axios
      .post(baseUrl, data, this.config)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  }

  static async createEntry(typeObject, data) {
    const baseUrl = `${API_URL}/${typeObject}/`;
    return await axios
      .post(baseUrl, data, this.config)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  }

  static async updateEntry(typeObject, id, data) {
    const baseUrl = `${API_URL}/${typeObject}/`;
    const url = id === undefined ? baseUrl : baseUrl + `${id}`;
    return await axios
      .post(url, data, this.config)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  }

  static async postData(route, data = {}) {
    return await axios.post(`${API_URL}/${route}`, data)
      .then((response) => response.data)
      .catch((error) => { throw error;})
  }
}
