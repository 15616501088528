import React from "react";

import Button from "react-bootstrap/Button";

import { faEraser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function ButtonCancelEntry(props) {
  return (
    <Button variant="success" disabled={props.disabled} onClick={props.onClick}>
      <FontAwesomeIcon icon={faEraser} />
    </Button>
  );
}
