import React, {Component} from "react";
import SideNav, {NavIcon, NavItem, NavText} from "@trendmicro/react-sidenav";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faHome,
  faChartLine,
  faShoppingCart,
  faSlidersH,
  faUserCircle, faUsersCog
} from "@fortawesome/free-solid-svg-icons";
import {faSignInAlt} from "@fortawesome/free-solid-svg-icons";
import ApiService from "../../services/ApiService";
import AuthenticationService from "../../services/AuthenticationService";
import moment from "moment";
import styled from "styled-components";


const NavInfoPane = styled.div`
  padding: 10px 20px;
  position: fixed;
  bottom: 0;
  color: #f9dcdd;
  font-weight: bolder;
  font-size: 14px;
`;

class Sidenav extends Component {
  constructor(props) {
    super(props);

    // Getting the user_group of the logged user
    if (AuthenticationService.isUserAuthenticated()) {
      this.user_group = AuthenticationService.getData().user_group;
    }
    this.state = {
      expanded: false,
      tms_ultimo_aggiornamento: "--:-- --/--/--"
    }
  }

  componentDidMount() {
    ApiService.getEntries('misc', 'dateAggiornamento')
      .then(data => this.setState({
        tms_ultimo_aggiornamento: `${moment.utc(data[0]["tms_ultimo_aggiornamento"]).format("HH:mm DD/MM/YYYY")}`
      }))
  }

  level = (_level) => this.user_group <= _level;

  onSelect = (selected) => {
    /**
     * @handler LOGOUT
     */
    if (selected === "logout") {
      AuthenticationService.logout();
      this.props.history.push("/");

      return;
    }

    const to = "/" + selected;
    if (this.props.location.pathname !== to) {
      this.props.history.push(to);
    }
  };

  onToggle = (expanded) => {
    this.props.toggle(expanded)
    this.setState({expanded: expanded})
  }

  render() {
    const level = this.level;
    return (
      <SideNav
        style={{background: "#076939", position: "fixed"}}
        onSelect={this.onSelect}
        onToggle={this.onToggle}
      >
        <SideNav.Toggle/>
        <SideNav.Nav defaultSelected="home">
          {level(4) && (
            <NavItem eventKey="home">
              <NavIcon>
                <FontAwesomeIcon icon={faHome} style={{fontSize: "1.4em"}}/>
              </NavIcon>
              <NavText className="py-3">
                <strong>Home</strong>
              </NavText>
            </NavItem>
          )}

          {level(4) && (
            <NavItem eventKey="demand">
              <NavIcon>
                <FontAwesomeIcon
                  icon={faChartLine}
                  style={{fontSize: "1.4em"}}
                />
              </NavIcon>
              <NavText className="py-3">
                <strong>Demand Forecasting</strong>
              </NavText>
            </NavItem>
          )}

          {level(4) && (
            <NavItem eventKey="inventory">
              <NavIcon>
                <FontAwesomeIcon
                  icon={faShoppingCart}
                  style={{fontSize: "1.4em"}}
                />
              </NavIcon>
              <NavText className="py-3">
                <strong>Inventory</strong>
              </NavText>
              <NavItem eventKey="inventory">
                <NavText>Ottimizzatore</NavText>
              </NavItem>
              <NavItem eventKey="alert">
                <NavText>Alert</NavText>
              </NavItem>
            </NavItem>
          )}

          {level(4) && (
            <NavItem eventKey="dataentry">
              <NavIcon>
                <FontAwesomeIcon
                  icon={faSlidersH}
                  style={{fontSize: "1.4em"}}
                />
              </NavIcon>
              <NavText className="py-3">
                <strong>Data Entry</strong>
              </NavText>
              <NavItem eventKey="dataentry/articoli">
                <NavText>Anagrafica Articoli</NavText>
              </NavItem>
              <NavItem eventKey="dataentry/bancalizzazione">
                <NavText>Bancalizzazione</NavText>
              </NavItem>
              <NavItem eventKey="dataentry/destinatari">
                <NavText>Destinatari</NavText>
              </NavItem>
              {level(2) && (
                <NavItem eventKey="dataentry/parametri">
                  <NavText>Parametri</NavText>
                </NavItem>
              )}
            </NavItem>
          )}

          <hr style={{marginTop: "4vw"}}/>
          <NavItem eventKey="profile">
            <NavIcon>
              <FontAwesomeIcon
                icon={faUserCircle}
                style={{fontSize: "1.4em"}}
              />
            </NavIcon>
            <NavText>Profilo</NavText>
          </NavItem>

          {level(1) && (
            <NavItem eventKey="admin">
              <NavIcon>
                <FontAwesomeIcon
                  icon={faUsersCog}
                  style={{fontSize: "1.4em"}}
                />
              </NavIcon>
              <NavText>Admin</NavText>
            </NavItem>
          )}

          <NavItem eventKey="logout">
            <NavIcon>
              <FontAwesomeIcon
                icon={faSignInAlt}
                style={{fontSize: "1.4em"}}
              />
            </NavIcon>
            <NavText>Logout</NavText>
          </NavItem>
          <NavInfoPane style={{opacity: this.state.expanded ? 0.66 : 0, transition: "0.2s"}}>
            Ultimo aggiornamento <br/>
            {this.state.tms_ultimo_aggiornamento}
          </NavInfoPane>
        </SideNav.Nav>
      </SideNav>
    );
  }
}

export default Sidenav;
