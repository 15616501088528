import React from "react";
import {Col, Container, Jumbotron, Row} from "react-bootstrap";
import ButtonList from "../../components/ButtonList";
import LocalizedDayInputPicker from "../../components/LocalizedDayInputPicker";
import DemandForecastingChart from "../../components/Charts/demandForecasting";
import ButtonGroupDF from "./ButtonGroupDF";
import {LoadingSpinner} from "../../components/Misc/LoadingSpinner";
import DetailSelector from "./DetailSelector";
import ButtonClearFilters from "../../components/Buttons/ButtonClearFilters";
import {demandService} from "../../services/DemandInventoryService/DemandInventoryService";
import BaseComponent from "../../components/BaseComponent";
import DemandInventoryFilterColumns from "../../components/DemandInventoryFiltersColumns/DemandInventoryFilterColumns";
import {AmPmSelector} from "../../components/Misc/AmPmSelector";

class DemandForecasting extends BaseComponent {

  /* -------- Init ------- */

  defaultChartType = 'dettaglio'
  demandForecasting;

  constructor(props) {
    super(props);

    this.demandForecasting = this.props.states[0]
    this.demandForecasting.serviceType = "demandForecasting"

    this.state = {
      chartDetailKey: this.defaultChartType
    }
  }

  /* -------- Life Cycle ------- */

  componentDidMount() {
    console.log("Component Did Mount")
    if (this.demandForecasting.state.data.length > 0)
      return
    this.demandForecasting.fetchLastAvailableData()
    this.demandForecasting.fetchProductArticles()
  }

  /* -------- Render ------- */
  render() {
    const data = this.demandForecasting.filteredData
    console.log("Render: Data Length:", data.length)

    const {
      gruppiMerceologici,
      selectedGruppiMerceologici,
      isLoading,
      disabledChartTypes,
      isClearFilterDisabled,
      datiAl,
      lastAvailableDate,
    } = this.demandForecasting


    return (
      <Container fluid className={"mt-4"}>
        <Jumbotron className={"py-3"}>
          <h1 >Demand Forecasting</h1>
          <span>Analisi storica e previsionale della domanda</span>
        </Jumbotron>

        <hr/>

        <Container fluid className={isLoading ? "" : "d-none"}>
          <LoadingSpinner/>
        </Container>

        <Container fluid className={data.length > 0 ? "" : "d-none"}>
          <Row>
            {/* ---- FILTRO CATEGORIA ---- */}
            <Col lg={4} className={"text-center"}>
              <h5>Gruppo Merceologico</h5>
              <ButtonList
                className="mt-1"
                selected={selectedGruppiMerceologici}
                options={gruppiMerceologici}
                onChange={value => this.demandForecasting.onChangeGruppoMerceologico(value)}
              />
            </Col>

            {/* ---- FILTRO DETTAGLIO ---- */}
            <Col lg={3} className={"text-center"}>
              <h5>Periodo</h5>
              <DetailSelector onChange={e => this.demandForecasting.onChangeDettaglio(e.target.id)}/>
            </Col>

            {/* ---- FILTRO DATI_AL ---- */}
            <Col lg={3} className={"text-center"}>
              <h5>Dati Al</h5>
              <LocalizedDayInputPicker
                value={new Date(datiAl)}
                onDayChange={day => this.demandForecasting.onChangeDatiAl(day)}
                lastAvailableDate={lastAvailableDate ? new Date(lastAvailableDate) :  new Date()}
                disabledDays={this.demandForecasting.disabledDays}
              />
              <AmPmSelector
                onChange={e => this.demandForecasting.onChangeRunType(e.target.id)}
                runType={this.demandForecasting.runType}
                isAMDisable={this.demandForecasting.isAMDisabled}
                isPMDisabled={this.demandForecasting.isPMDisabled}
              />
            </Col>

            {/* ---- CLEAR FILTERS ---- */}
            <Col lg={2} className={"text-center"} style={{margin: "auto"}}>
              <ButtonClearFilters
                onClick={() => this.demandForecasting.clearFilters()}
                disabled={isClearFilterDisabled}
              />
            </Col>
          </Row>

          {/* ---- FILTRI COLONNE ----*/}

          <Row className={"mt-4 bg-light"} style={{borderRadius: "25px"}}>
            <DemandInventoryFilterColumns service={this.demandForecasting} />
          </Row>
          {/* -- CHART --*/}
          <Row className={"mt-5 p-2 bg-light justify-content-center"} style={{borderRadius: "25px"}}>
            <ButtonGroupDF
              radioValue={this.state.chartDetailKey}
              onChange={e => this.setState({chartDetailKey: e.currentTarget.value})}
            />
            <DemandForecastingChart
              data={data}
              groupKey={this.state.chartDetailKey}
              disabledButtons={disabledChartTypes}
              day={datiAl}
              runType={this.demandForecasting.runType}
              productArticles={this.demandForecasting.state.productArticles}
            />
          </Row>
        </Container>
      </Container>
    )
  }
}

export default DemandForecasting.withStates([demandService]);
