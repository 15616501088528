import { Container } from "unstated";
import ApiService from "./ApiService";

import _ from "lodash";
import moment from "moment";

export default class KPIService extends Container {
  state = {
    // DATA
    data: {
      entrate: [],
      uscite: [],
    },
    immutableData: {
      entrate: [],
      uscite: [],
    },

    // FILTERS
    filterAreaMerceologica: [],
    filterFornitore: [],
    filterArticolo: [],
    filterBusiness: [],
    filterAreaTerritoriale: [],

    // UTILITIES
    ready: false,
    start: undefined,
    to: undefined
  };

  retrieveDefaultPeriod() {
    ApiService.getEntries('kpi/maxDate')
      .then(data => {
        const maxDate = moment(data['max_date']).format("YYYY-MM-DD")
        const minDate = moment(maxDate, "YYYY-MM-DD").subtract(30, "d").format("YYYY-MM-DD")
        console.log('Load default 30 (available) days period:', minDate, maxDate)
        this.setState({from: minDate, to: maxDate})
        .then(_ => this.retrieveSpecificData(minDate, maxDate))
      })
      .catch(err => console.log(err))
  }

  retrieveSpecificData(from, to) {
    return new Promise((resolve, reject) => {
      Promise.all([
        ApiService.getEntriesWithData("kpi/entrate", {
          start_date: from,
          end_date: to,
        }),
        ApiService.getEntriesWithData("kpi/uscite", {
          start_date: from,
          end_date: to,
        }),
      ])
        .then((values) => {
          const entrate = values[0];
          const uscite = values[1];

          this.setState({
            data: {
              entrate,
              uscite,
            },
            immutableData: {
              entrate: _.cloneDeep(entrate),
              uscite: _.cloneDeep(uscite),
            },
            ready: true
          });

          resolve(values);
        })
        .catch((err) => reject(err));
    });
  }

  /** --- GETTERS --- */

  get filterAreaMerceologica() {
    return this.state.filterAreaMerceologica;
  }

  get filterFornitore() {
    return this.state.filterFornitore;
  }

  get filterArticolo() {
    return this.state.filterArticolo;
  }

  get filterBusiness() {
    return this.state.filterBusiness;
  }

  get filterAreaTerritoriale() {
    return this.state.filterAreaTerritoriale;
  }

  get data() {
    return this.state.data;
  }

  /** ---- OPTIONS for select ---- */
  getOptionsAreaMerceologica() {
    console.count("I called _applyFilter [getOptionsAreaMerceologica]");

    const { entrate, uscite } = this._applyFilter(
      {
        ...this.state,
        filterAreaMerceologica: [],
      },
      false
    );

    const result = entrate
      .concat(uscite)
      .reduce((acc, actual) => {
        const key = `${actual.cod_area_merceologica} - ${actual.des_area_merceologica}`;
        if (!acc.includes(key)) {
          acc.push(key);
        }

        return acc;
      }, [])
      .sort((a, b) => a.split(" - ")[0] - b.split(" - ")[0]);

    return result;
  }

  getOptionsFornitore() {
    console.time("getOptionsFornitore");

    const { entrate } = this._applyFilter(
      {
        ...this.state,
        filterFornitore: [],
      },
      false
    );

    const result = entrate
      .reduce((acc, actual) => {
        const key = `${actual.cod_fornitore_alt} - ${actual.ragione_sociale}`;
        if (!acc.includes(key)) {
          acc.push(key);
        }

        return acc;
      }, [])
      .sort((a, b) => a.split(" - ")[0] - b.split(" - ")[0]);

    console.timeEnd("getOptionsFornitore");
    return result;
  }

  getOptionsArticolo() {
    console.time("_getOptionsArticolo");
    const { entrate, uscite } = this._applyFilter(
      {
        ...this.state,
        filterArticolo: [],
      },
      false
    );

    const result = entrate.concat(uscite).reduce((acc, actual) => {
      const key = `${actual.cod_articolo_alt} - ${actual.des_articolo}`;
      if (!acc.includes(key)) {
        acc.push(key);
      }

      return acc;
    }, []);

    console.timeEnd("_getOptionsArticolo");
    return result;
  }

  getOptionsBusiness() {
    console.time("getOptionsBusiness");

    const { uscite } = this._applyFilter(
      {
        ...this.state,
        filterBusiness: [],
      },
      false
    );

    const result = uscite
      .reduce((acc, actual) => {
        const key = `${actual.business}`;
        if (!acc.includes(key)) {
          acc.push(key);
        }

        return acc;
      }, [])
      .sort((a, b) => a - b);

    console.timeEnd("getOptionsBusiness");

    return result;
  }

  getOptionsAreaTerritoriale() {
    console.time("getOptionsAreaTerritoriale");

    const { uscite } = this._applyFilter(
      {
        ...this.state,
        filterAreaTerritoriale: [],
      },
      false
    );

    const result = uscite
      .reduce((acc, actual) => {
        const key = `${actual.area_territoriale}`;
        if (!acc.includes(key)) {
          acc.push(key);
        }

        return acc;
      }, [])
      .sort((a, b) => a - b);

    console.timeEnd("getOptionsAreaTerritoriale");

    return result;
  }

  // ------------------------------- \\
  // Validazione articoli per filtri \\
  // ------------------------------- \\

  validFilterAreaMerceologica(article, newState) {
    if (
      newState.filterAreaMerceologica.length > 0 &&
      !newState.filterAreaMerceologica.includes(
        `${article.cod_area_merceologica} - ${article.des_area_merceologica}`
      )
    ) {
      return false;
    }

    return true;
  }

  validFilterArticolo(article, newState) {
    if (
      newState.filterArticolo.length > 0 &&
      !newState.filterArticolo.includes(
        `${article.cod_articolo_alt} - ${article.des_articolo}`
      )
    ) {
      return false;
    }

    return true;
  }

  validFilterFornitore(article, newState) {
    if (
      newState.filterFornitore.length > 0 &&
      !newState.filterFornitore.includes(
        `${article.cod_fornitore_alt} - ${article.ragione_sociale}`
      )
    ) {
      return false;
    }

    return true;
  }

  validFilterBusiness(article, newState) {
    if (
      newState.filterBusiness.length > 0 &&
      !newState.filterBusiness.includes(article.business)
    ) {
      return false;
    }

    return true;
  }

  validFilterAreaTerritoriale(article, newState) {
    if (
      newState.filterAreaTerritoriale.length > 0 &&
      !newState.filterAreaTerritoriale.includes(article.area_territoriale)
    ) {
      return false;
    }

    return true;
  }

  isEntrateArticleValid(
    article,
    newState,
    validArticleBusiness,
    validArticleAreaTerritoriale
  ) {
    const validFilterBusiness =
      (validArticleBusiness.length > 0 &&
        validArticleBusiness.includes(article.cod_articolo_alt)) ||
      validArticleBusiness.length === 0;

    const validFilterAreaTerritoriale =
      (validArticleAreaTerritoriale.length > 0 &&
        validArticleAreaTerritoriale.includes(article.cod_articolo_alt)) ||
      validArticleAreaTerritoriale.length === 0;

    if (
      this.validFilterAreaMerceologica(article, newState) &&
      this.validFilterArticolo(article, newState) &&
      this.validFilterFornitore(article, newState) &&
      validFilterBusiness &&
      validFilterAreaTerritoriale
    ) {
      return true;
    }

    return false;
  }

  isUsciteArticleValid(article, newState, validArticleFornitore) {
    const validFilterFornitore =
      (validArticleFornitore.length > 0 &&
        validArticleFornitore.includes(article.cod_articolo_alt)) ||
      validArticleFornitore.length === 0;

    if (
      this.validFilterAreaMerceologica(article, newState) &&
      this.validFilterArticolo(article, newState) &&
      this.validFilterBusiness(article, newState) &&
      this.validFilterAreaTerritoriale(article, newState) &&
      validFilterFornitore
    ) {
      return true;
    }

    return false;
  }

  _applyFilter(newState, apply = true) {
    console.count("_applyFilter()");

    const { entrate, uscite } = this.state.immutableData;

    // get entrate article with valid cod for uscite
    let fornitoreValidArticles = [];
    if (newState.filterFornitore && newState.filterFornitore.length > 0) {
      fornitoreValidArticles = entrate
        .filter((o) => this.validFilterFornitore(o, newState))
        .reduce((acc, actual) => {
          const actualCod = actual.cod_articolo_alt;

          if (!acc.includes(actualCod)) {
            acc.push(actualCod);
          }

          return acc;
        }, []);
    }

    // get uscite article with valid cod for uscite
    let businessValidArticles = [];
    if (newState.filterBusiness && newState.filterBusiness.length > 0) {
      businessValidArticles = uscite
        .filter((o) => this.validFilterBusiness(o, newState))
        .reduce((acc, actual) => {
          const actualCod = actual.cod_articolo_alt;

          if (!acc.includes(actualCod)) {
            acc.push(actualCod);
          }

          return acc;
        }, []);
    }

    let areaTerritorialeValidArticles = [];
    if (
      newState.filterAreaTerritoriale &&
      newState.filterAreaTerritoriale.length > 0
    ) {
      areaTerritorialeValidArticles = uscite
        .filter((o) => this.validFilterAreaTerritoriale(o, newState))
        .reduce((acc, actual) => {
          const actualCod = actual.cod_articolo_alt;

          if (!acc.includes(actualCod)) {
            acc.push(actualCod);
          }

          return acc;
        }, []);
    }

    const articlesEntrate = entrate.filter((o) =>
      this.isEntrateArticleValid(
        o,
        newState,
        businessValidArticles,
        areaTerritorialeValidArticles
      )
    );

    const articlesUscite = uscite.filter((o) =>
      this.isUsciteArticleValid(o, newState, fornitoreValidArticles)
    );

    const data = {
      entrate: articlesEntrate,
      uscite: articlesUscite,
    };

    if (apply) {
      this.setState({
        ...newState,
        data,
      });
    }

    return data;
  }

  /** ----- CUSTOM KPI METHODS ------ */
  getTotaleMagazzino() {
    console.time("getTotaleMagazzino");
    const entrateResult = this.state.data.entrate.reduce(
      (a, b) => a + b.value,
      0
    );
    const usciteResult = this.state.data.uscite.reduce(
      (a, b) => a + b.value,
      0
    );
    console.timeEnd("getTotaleMagazzino");

    const entrate = entrateResult > 1000 ? entrateResult.toFixed() : entrateResult.toFixed(2)
    const uscite = usciteResult > 1000 ? usciteResult.toFixed() : usciteResult.toFixed(2)

    return { entrate: entrate, uscite: uscite };
  }

  getFilteredKpi(filterType, filterValue) {
    console.time("getFilteredKpi(" + filterType + filterValue + ")");
    const entrateResult = this.state.data.entrate
      .filter((o) => o[filterType] === filterValue)
      .reduce((a, b) => a + b.value, 0);

    const usciteResult = this.state.data.uscite
      .filter((o) => o[filterType] === filterValue)
      .reduce((a, b) => a + b.value, 0);
    console.timeEnd("getFilteredKpi(" + filterType + filterValue + ")");

    const entrate = entrateResult > 1000 ? entrateResult.toFixed() : entrateResult.toFixed(2)
    const uscite = usciteResult > 1000 ? usciteResult.toFixed() : usciteResult.toFixed(2)

    return { entrate: entrate, uscite: uscite };
  }

  getMapData() {
    const data = this.state.data.uscite.reduce((acc, actual) => {
      acc[actual.provincia] = (acc[actual.provincia] || 0) + actual.value;
      return acc;
    }, {});

    const result = _.keys(data).map((o) => {
      return {
        id: o,
        value: parseInt(data[o]) > 1000 ? parseInt(data[o]) : parseFloat(data[o]).toFixed(2),
      };
    });
    return result;
  }

  getChartData(filter, options) {
    console.time("getChartData(" + filter + ")");

    const { entrate, uscite } = this.state.data;

    let resultEntrate = [],
      resultUscite = [];

    switch (filter) {
      case "area-merceologica":
        resultEntrate = entrate
          .filter((o) => {
            const key = `${o.cod_area_merceologica} - ${o.des_area_merceologica}`;

            if (this.filterAreaMerceologica.length === 0) return true;
            if (this.filterAreaMerceologica.includes(key)) return true;
            return false;
          })
          .reduce((acc, curr) => {
            const key = `${curr.cod_area_merceologica} - ${curr.des_area_merceologica}`;
            acc[key] = (acc[key] || 0) + curr.value;

            return acc;
          }, {});

        resultUscite = uscite
          .filter((o) => {
            const key = `${o.cod_area_merceologica} - ${o.des_area_merceologica}`;

            if (this.filterAreaMerceologica.length === 0) return true;
            if (this.filterAreaMerceologica.includes(key)) return true;
            return false;
          })
          .reduce((acc, curr) => {
            const key = `${curr.cod_area_merceologica} - ${curr.des_area_merceologica}`;
            acc[key] = (acc[key] || 0) + curr.value;

            return acc;
          }, {});
        break;

      case "fornitore":
        resultEntrate = entrate
          .filter((o) => {
            if (this.filterFornitore.length === 0) return true;
            if (
              this.filterFornitore.includes(
                `${o.cod_fornitore_alt} - ${o.ragione_sociale}`
              )
            )
              return true;
            return false;
          })
          .reduce((acc, curr) => {
            const key = `${curr.cod_fornitore_alt} - ${curr.ragione_sociale}`;
            acc[key] = (acc[key] || 0) + curr.value;

            return acc;
          }, {});

        const articlesFromFornitore = entrate
          .filter((o) => {
            if (this.filterFornitore.length === 0) return true;
            if (
              this.filterFornitore.includes(
                `${o.cod_fornitore_alt} - ${o.ragione_sociale}`
              )
            )
              return true;
            return false;
          })
          .reduce((acc, curr) => {
            const key = `${curr.cod_fornitore_alt} - ${curr.ragione_sociale}`;
            const codArticle = curr.cod_articolo_alt;

            if (!acc[key]) {
              acc[key] = [codArticle];

              return acc;
            }

            acc[key].push(codArticle);

            return acc;
          }, {});

        resultUscite = _.keys(articlesFromFornitore).reduce((acc, actual) => {
          const key = actual;

          const validArticles = articlesFromFornitore[key];

          acc[key] = uscite
            .filter((o) => validArticles.includes(o.cod_articolo_alt))
            .reduce((acc, actual) => acc + actual.value, 0);

          return acc;
        }, {});

        break;

      case "articolo":
        resultEntrate = entrate
          .filter((o) => {
            if (this.filterArticolo.length === 0) return true;
            if (
              this.filterArticolo.includes(
                `${o.cod_articolo_alt} - ${o.des_articolo}`
              )
            )
              return true;
            return false;
          })
          .reduce((acc, curr) => {
            const key = `${curr.cod_articolo_alt} - ${curr.des_articolo}`;
            acc[key] = (acc[key] || 0) + curr.value;

            return acc;
          }, {});

        resultUscite = uscite
          .filter((o) => {
            if (this.filterArticolo.length === 0) return true;
            if (
              this.filterArticolo.includes(
                `${o.cod_articolo_alt} - ${o.des_articolo}`
              )
            )
              return true;
            return false;
          })
          .reduce((acc, curr) => {
            const key = `${curr.cod_articolo_alt} - ${curr.des_articolo}`;
            acc[key] = (acc[key] || 0) + curr.value;

            return acc;
          }, {});
        break;

      case "business":
        resultUscite = uscite
          .filter((o) => this.validFilterBusiness(o, this.state))
          .reduce((acc, curr) => {
            const key = curr.business;
            acc[key] = (acc[key] || 0) + curr.value;

            return acc;
          }, {});

        break;

      case "area-territoriale":
        resultUscite = uscite
          .filter((o) => this.validFilterAreaTerritoriale(o, this.state))
          .reduce((acc, curr) => {
            const key = curr.area_territoriale;
            acc[key] = (acc[key] || 0) + curr.value;

            return acc;
          }, {});

        break;

      default:
        break;
    }

    let result;

    switch (filter) {
      case "area-merceologica":
      case "articolo":
      case "fornitore": {
        result = _.keys(resultEntrate)
          .map((o) => {
            return {
              area: o.split(" - ")[0],
              des: o || "",
              entrate: parseInt(resultEntrate[o]) > 1000 ? parseInt(resultEntrate[o]) : parseFloat(resultEntrate[o]).toFixed(2),
              uscite: parseInt(resultUscite[o]) > 1000 ? -parseInt(resultUscite[o]) : -parseFloat(resultUscite[o]).toFixed(2)
            };
          })
          .sort((a, b) => (b.entrate === a.entrate) ? 0 : (b.entrate > a.entrate ? 1 : -1))
          .slice(0, 10);

        break;
      }

      case "business":
      case "area-territoriale":
        result = _.keys(resultUscite)
          .map((o) => {
            return {
              area: o.split(" - ")[0],
              des: o || "",
              uscite: parseInt(resultUscite[o]) > 1000 ? -parseInt(resultUscite[o]) : -parseFloat(resultUscite[o]).toFixed(2)
            };
          })
          .sort((a, b) => a.uscite - b.uscite)
          .slice(0, 10);

        break;

      default:
        break;
    }

    console.timeEnd("getChartData(" + filter + ")");

    return result;
  }
}
