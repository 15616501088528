/*
DemandInventoryService.js
cir_deploy
Author: Jacopo Gasparetto
Date: 06/08/2020
Organization: Energy Way S.r.l
*/
import {Container} from "unstated";
import ApiService from "../ApiService";
import moment from "moment";
import _ from "lodash";

class DemandInventoryService extends Container {
  state = {
    isLoading: true,
    data: [],
    selectedData: [],
    datiAl: new Date().toISOString().split("T")[0],
    runType: 'pm',
    lastAvailableDate: null,
    disabledDays: [],
    notAvailableRuns: null,
    dettaglio: "weekly",

    selectedFilters: {
      areeMerceologiche: [],
      suppliers: [],
      articles: []
    },

    selectedGruppiMerceologici: [],
    selectedAreeMerceologiche: [],
    selectedSuppliers: [],
    selectedArticles: [],

    gruppiMerceologici: [],
    areeMerceologiche: [],
    suppliers: [],
    articles: [],

    productArticles: []
  }

  maximumVisibleSeries = 5;
  serviceType = null;     // Must be "demandForecasting" or "inventoryOptimization"

  gruppiMerceologiciMap = {
    surgelati: "SU",
    freschi: "FR",
    secchi: "SE",
    deperibili: "DP"
  }

  gruppiMerceologiciReverseMap = {
    SU: "SURGELATI",
    FR: "FRESCHI",
    SE: "SECCHI",
    DP: "DEPERIBILI"
  }

  /* -------- Properties ------- */

  // Merge elements like "cod_product - des_product" and fine unique elements
  addNewDataField(data, cod, des, keyField) {
    return data.map(el => {
      el[keyField] = `${el[cod]} - ${el[des]}`
      return el
    })
  }

  getUniqueElementsFromData(data, keyField) {
    return [...new Set(data.map(el => {
      return el[keyField]
    }))
    ]
  }

  fetchLastAvailableData(_callback) {
    const today = moment().format("YYYY-MM-DD")

    const params = `datesAvailability?dati_al=${today}`
    ApiService.getEntries(this.serviceType, params).then(data => {
      const lastAvailableDate = moment(data["last_available_date"]).format("YYYY-MM-DD")
      const notAvailableDates = data["not_available_dates"].map(d => {return new Date(d)})

      const amPm = data["not_available_runs"][lastAvailableDate] === 'pm' ? 'am' : 'pm'

      this.setState({
        datiAl: lastAvailableDate,
        notAvailableRuns: data["not_available_runs"],
        lastAvailableDate: lastAvailableDate,
        disabledDays: notAvailableDates,
        runType: amPm
      })
        .then(_ => {
          if (_callback)
            _callback();
          this.fetchData(this.state.datiAl, amPm, this.state.dettaglio)
        })
    })
      .catch(err => console.log(err))
  }

  fetchProductArticles() {
    ApiService.getEntries('demandForecasting', 'productArticles')
      .then(data => {this.setState({productArticles: data.reduce((obj, i) => _.set(obj, [i.series_name], i.articoli), {})})})
      .catch(error => console.log(error))
  }

  fetchData(datiAl, runType, dettaglio) {
    console.log(`Fetching data for date: ${datiAl}, runType: ${runType}...`)

    const params = `data?dati_al=${datiAl}&dettaglio=${dettaglio}&am_pm=${runType}`
    ApiService.getEntries(this.serviceType, params)
      .then(data => {
        console.log(`Fetched  ${data.length} elements. Prepare and update data`)
        data = this.addNewDataField(data, "cod_area_merceologica", "des_area_merceologica", "area_merceologica")
        data = this.addNewDataField(data, "cod_prodotto_cir", "des_prodotto_cir", "articolo")
        data = this.addNewDataField(data, "cod_fornitore_alt", "ragione_sociale", "fornitore")

        this.filters = this.getFilters(data)

        const gruppiMerceologici = this.getUniqueElementsFromData(data, "cod_gruppo_merceologico")
        const areeMerceologiche = this.getUniqueElementsFromData(data, "area_merceologica")
        const articles = this.getUniqueElementsFromData(data, "articolo")
        const suppliers = this.getUniqueElementsFromData(data, "fornitore")

        this.setState({
          data: data,
          selectedGruppiMerceologici: [],
          selectedAreeMerceologiche: [],
          selectedSuppliers: [],
          selectedArticles: [],
          gruppiMerceologici: gruppiMerceologici,
          areeMerceologiche: areeMerceologiche,
          suppliers: suppliers,
          articles: articles,
          isLoading: false
        })
          .then(() => console.log("All data updated"))

      })
      .catch(error => console.log(error))
  }

  getFilters(data) {
    return data.reduce((acc, el) => {
      const {cod_gruppo_merceologico, area_merceologica, fornitore, articolo} = el

      if (cod_gruppo_merceologico in acc) {
        if (area_merceologica in acc[cod_gruppo_merceologico]) {
          if (fornitore in acc[cod_gruppo_merceologico][area_merceologica]) {
            if (!acc[cod_gruppo_merceologico][area_merceologica][fornitore].includes(articolo)) {
              acc[cod_gruppo_merceologico][area_merceologica][fornitore].push(articolo)
            }
          } else {
            acc[cod_gruppo_merceologico][area_merceologica][fornitore] = [articolo]
          }
        } else {
          acc[cod_gruppo_merceologico][area_merceologica] = {
            [fornitore]: [articolo]
          }
        }
      } else {
        acc[cod_gruppo_merceologico] = {
          [area_merceologica]: {
            [fornitore]: [articolo]
          }
        }
      }
      return acc
    }, {})
  }

  selectFilters(selectedGruppi, selectedAree, selectedSuppliers) {

    const codGruppi = selectedGruppi.length > 0 ?
      selectedGruppi.map(el => this.gruppiMerceologiciMap[el.toLowerCase()]) :
      Object.values(this.state.gruppiMerceologici)

    return codGruppi.reduce((acc, key) => {
      const gruppo = this.filters[key]
      const areeMerceologiche = Object.keys(gruppo)

      const visibleElements = selectedAree.length > 0 ? areeMerceologiche
        .filter(key => selectedAree.includes(key))
        .reduce((acc, key) => {
          acc[key] = gruppo[key]
          return acc
        }, {}) : gruppo


      const objFornitori = Object.values(visibleElements)
      const fornitori = objFornitori.map(el => Object.keys(el)).flat(1)

      let articoli = selectedSuppliers.length > 0 ?
        objFornitori
          .flatMap(el => Object.entries(el))
          .filter(el => selectedSuppliers.includes(el[0]))
          .flatMap(el => el[1]) :
        objFornitori.map(el => Object.values(el)).flat(2)

      acc.areeMerceologiche = [...new Set(acc.areeMerceologiche.concat(areeMerceologiche))]
      acc.suppliers = [...new Set(acc.suppliers.concat(fornitori))]
      acc.articles = [...new Set(acc.articles.concat(articoli))]
      return acc
    }, {areeMerceologiche: [], suppliers: [], articles: []})
  }

  filterData(data) {
    let {
      selectedAreeMerceologiche,
      selectedSuppliers,
      selectedArticles,
      selectedGruppiMerceologici
    } = this.state

    if (selectedGruppiMerceologici.length > 0) {
      selectedGruppiMerceologici = selectedGruppiMerceologici.map(el => this.gruppiMerceologiciMap[el.toLowerCase()])
    }

    const cndGrpMrc = el => selectedGruppiMerceologici.length === 0 || selectedGruppiMerceologici.includes(el["cod_gruppo_merceologico"])
    const cndAreeMrc = el => selectedAreeMerceologiche.length === 0 || selectedAreeMerceologiche.includes(el.area_merceologica)
    const cndSuplr = el => selectedSuppliers.length === 0 || selectedSuppliers.includes(el.fornitore)
    const cndPrd = el => selectedArticles.length === 0 || selectedArticles.includes(el.articolo)

    return data.filter(el => cndGrpMrc(el) && cndAreeMrc(el) && cndSuplr(el) && cndPrd(el))
  }

  onChangeRows(row, isSelected) {
    const filterToUpdate = {
      area_merceologica: "selectedAreeMerceologiche",
      articolo: "selectedArticles",
      fornitore: "selectedSuppliers"
    }

    const key = Object.keys(row)[0]
    let selectedRows = this.state[filterToUpdate[key]]

    if (isSelected) {
      selectedRows.push(row[key])
    } else {
      selectedRows = selectedRows.filter(el => el !== row[key])
    }

    const {selectedGruppiMerceologici} = this.state  // This is immutable when selecting from tables
    let newState = null;
    let activeFilters = null;
    let currentGruppi = null;
    let currentAree = null;
    let currentSuppliers = null;
    let currentSelectedGruppi = null;
    let currentSelectedAree = null;
    let currentSelelectedSuppliers = null;

    switch (key) {
      case "area_merceologica":
        const aree = this.state.data.filter(el => selectedRows.includes(el.area_merceologica))
        currentGruppi = aree.map(el => this.gruppiMerceologiciReverseMap[el.cod_gruppo_merceologico])
        currentSelectedGruppi =  [...new Set(this.state.selectedGruppiMerceologici.concat(currentGruppi))]

        activeFilters = this.selectFilters(selectedGruppiMerceologici, selectedRows, [])

        newState = {
          areeMerceologiche: activeFilters.areeMerceologiche,
          suppliers: activeFilters.suppliers,
          articles: activeFilters.articles,
          selectedSuppliers: [],
          selectedArticles: [],
          selectedGruppiMerceologici: currentSelectedGruppi,
          selectedAreeMerceologiche: selectedRows
        }
        break;
      case "fornitore":
        const suppliers = this.state.data.filter(el => selectedRows.includes(el.fornitore))
        currentGruppi = suppliers.map(el => this.gruppiMerceologiciReverseMap[el.cod_gruppo_merceologico])
        currentAree = suppliers.map(el => el.area_merceologica)

        currentSelectedGruppi = [...new Set(this.state.selectedGruppiMerceologici.concat(currentGruppi))]
        currentSelectedAree = [...new Set(this.state.selectedAreeMerceologiche.concat(currentAree))]

        activeFilters = this.selectFilters(currentSelectedGruppi, currentSelectedAree, selectedRows)

        newState = {
          articles: activeFilters.articles,
          suppliers: activeFilters.suppliers,
          selectedGruppiMerceologici: currentSelectedGruppi,
          selectedAreeMerceologiche: currentSelectedAree,
          selectedSuppliers: selectedRows,
          selectedArticles: []
        }
        break;
      case "articolo":
        const articles = this.state.data.filter(el => selectedRows.includes(el.articolo))

        currentGruppi = articles.map(el => this.gruppiMerceologiciReverseMap[el.cod_gruppo_merceologico])
        currentAree = articles.map(el => el.area_merceologica)
        currentSuppliers = articles.map(el => el.fornitore)
        currentSelectedGruppi =  [...new Set(this.state.selectedGruppiMerceologici.concat(currentGruppi))]
        currentSelectedAree = [...new Set(this.state.selectedAreeMerceologiche.concat(currentAree))]
        currentSelelectedSuppliers = [...new Set(this.state.selectedSuppliers.concat(currentSuppliers))]

        newState = {
          selectedArticles: selectedRows,
          selectedGruppiMerceologici: currentSelectedGruppi,
          selectedAreeMerceologiche: currentSelectedAree,
          selectedSuppliers: currentSelelectedSuppliers
        }
        break;
      default:
        break;
    }
    this.setState(newState)
  }

  onChangeGruppoMerceologico(selectedGruppiMerceologici) {
    const activeFilters = this.selectFilters(selectedGruppiMerceologici, [], [])

    const newState = {
      selectedGruppiMerceologici: selectedGruppiMerceologici,
      areeMerceologiche: activeFilters.areeMerceologiche,
      suppliers: activeFilters.suppliers,
      articles: activeFilters.articles,
      selectedSuppliers: [],
      selectedArticles: [],
      selectedAreeMerceologiche: []
    }

    this.setState(newState)
  }

  onChangeDettaglio(dettaglio) {
    this.setState({
      dettaglio: dettaglio,
      isLoading: true
    }).then(_ =>
      this.fetchData(this.state.datiAl, this.state.runType, dettaglio)
    )
  }

  onChangeDatiAl(date) {
    const datiAl = moment(date).format("YYYY-MM-DD")
    const amPm = this.state.notAvailableRuns[datiAl] === 'pm' ? 'am' : 'pm'

    this.setState({
      datiAl: datiAl,
      runType: amPm,
      isLoading: true
    }).then(_ =>
      this.fetchData(datiAl, amPm, this.state.dettaglio)
    )
  }

  onChangeRunType(runType) {
    console.log("Change runType:", runType)
    this.setState({
      runType: runType,
      isLoading: true
    }).then(_ =>
      this.fetchData(this.datiAl, this.runType, this.dettaglio)
    )
  }

  clearFilters() {
    console.log("Clear filters")
    const activeFilters = this.selectFilters([], [], [])


    this.setState({
      selectedGruppiMerceologici: [],
      areeMerceologiche: activeFilters.areeMerceologiche,
      suppliers: activeFilters.suppliers,
      articles: activeFilters.articles,
      selectedSuppliers: [],
      selectedArticles: [],
      selectedAreeMerceologiche: []
    })
  }

  /* -------- Getters ------- */

  get filteredData() {
    return this.filterData(this.state.data)
  }

  get gruppiMerceologici() {
    return this.state.gruppiMerceologici.map(el => this.gruppiMerceologiciReverseMap[el])
  }

  get areeMerceologiche() {
    return this.state.areeMerceologiche.map(el => {
      return {"area_merceologica": el}
    })
  }

  get suppliers() {
    return this.state.suppliers.map(el => {
      return {"fornitore": el}
    })
  }

  get articles() {
    return this.state.articles.map(el => {
      return {"articolo": el}
    })
  }

  get selectedGruppiMerceologici() {
    return this.state.selectedGruppiMerceologici
  }

  get selectedAreeMerceologiche() {
    return this.state.selectedAreeMerceologiche;
  }

  get selectedSuppliers() {
    return this.state.selectedSuppliers;
  }

  get selectedArticles() {
    return this.state.selectedArticles;
  }

  get isLoading() {
    return this.state.isLoading
  }

  get isClearFilterDisabled() {
    const {
      selectedGruppiMerceologici,
      selectedAreeMerceologiche,
      selectedSuppliers,
      selectedArticles
    } = this.state

    return selectedGruppiMerceologici.length === 0 && selectedSuppliers.length === 0
      && selectedArticles.length === 0 && selectedAreeMerceologiche.length === 0
  }

  get disabledChartTypes() {
    let disabledCharts = [];

    if (
      !(this.state.areeMerceologiche.length <= this.maximumVisibleSeries || (
        this.state.selectedAreeMerceologiche.length > 0 &&
        this.state.selectedAreeMerceologiche.length <= this.maximumVisibleSeries))
    )
      disabledCharts.push("area_merceologica");

    if (
      !(this.state.suppliers.length <= this.maximumVisibleSeries || (
        this.state.selectedSuppliers.length > 0 &&
        this.state.selectedSuppliers.length <= this.maximumVisibleSeries))
    )
      disabledCharts.push("fornitore");

    if (
      !(this.state.articles.length <= this.maximumVisibleSeries || (
        this.state.selectedArticles.length > 0 &&
        this.state.selectedArticles.length <= this.maximumVisibleSeries))
    )
      disabledCharts.push("articolo");

    return disabledCharts
  }

  get datiAl() {
    return this.state.datiAl
  }

  get dettaglio() {
    return this.state.dettaglio
  }

  get lastAvailableDate() {
    return this.state.lastAvailableDate
  }

  get disabledDays() {
    return this.state.disabledDays
  }

  get runType() {
    return this.state.runType
  }

  get isAMDisabled() {
    if (this.state.notAvailableRuns) {
      return this.state.notAvailableRuns[this.state.datiAl] === "am"
    }
    return true
  }

  get isPMDisabled() {
    if (this.state.notAvailableRuns) {
      return this.state.notAvailableRuns[this.state.datiAl] === "pm"
    }
    return true
  }
}

export const demandService = new DemandInventoryService()
export const inventoryService = new DemandInventoryService()
