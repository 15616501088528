import ApiService from "../ApiService";
import _ from "lodash";

import ModelService from "../ModelService";

/**
 * SUPPLIER KEY: "COD_FORNITORE_ALT - RAGIONE_SOCIALE"
 */

export default class PalletSupplierService extends ModelService {
  constructor() {
    super();

    // Bindings
    this.updateData = this.updateData.bind(this);
    this.addNewUnderSupplier = this.addNewUnderSupplier.bind(this);
  }

  async retrieveData() {
    try {
      const palletsSuppliers = await ApiService.getEntries("pallets/suppliers");

      const actualSuppliers = {};

      palletsSuppliers.forEach((supplier) => {
        if (_.isNull(supplier.sotto_fornitore)) return;
        if (_.isNull(supplier.ragione_sociale)) return;

        const key = `${supplier.cod_fornitore_alt} - ${supplier.ragione_sociale}`;

        actualSuppliers[key] = {
          ...actualSuppliers[key],
          [supplier.sotto_fornitore]: supplier,
        };
      });

      this.setData(actualSuppliers);
      this.setImmutableData(_.cloneDeep(actualSuppliers));

      return palletsSuppliers;
    } catch (err) {
      throw err;
    }
  }

  async updateData() {
    if (this.isDataChanged()) {
      const changedData = this.getChangedData();

      _.keys(changedData).forEach(async (supplier) => {
        const supplierChangedData = changedData[supplier];

        _.keys(supplierChangedData).forEach(async (underSupplier) => {
          const underSupplierChangedData = supplierChangedData[underSupplier];
          underSupplierChangedData["sotto_fornitore"] = underSupplier;

          if (this.getImmutableData()[supplier][underSupplier]) {
            // The entry exist, update required
            await ApiService.updateEntry(
              "pallets/suppliers",
              supplier.split(" - ")[0],
              underSupplierChangedData
            );
          } else {
            // the entry not exist, new insert required
            const cod_fornitore_alt = supplier.split(" - ")[0];
            const ragione_sociale = supplier.split(" - ")[1];

            underSupplierChangedData["cod_fornitore_alt"] = cod_fornitore_alt;
            underSupplierChangedData["ragione_sociale"] = ragione_sociale;

            await ApiService.createEntry(
              "pallets/suppliers",
              underSupplierChangedData
            );
          }

          this.setImmutableData(
            _.set(
              this.getImmutableData(),
              [supplier, underSupplier],
              _.cloneDeep(this.getField([supplier, underSupplier]))
            )
          );
        });
      });
    }
  }

  addNewUnderSupplier(supplierKey, newUnderSupplierName) {
    this.setField([supplierKey, newUnderSupplierName], {});
  }

  /* --------- Custom methods --------- */
  getSupplierCodeNames() {
    return _.keys(this.getData()).sort((a, b) => {
      const ragione_sociale_a = a.split(" - ")[1];
      const ragione_sociale_b = b.split(" - ")[1];

      if (ragione_sociale_a > ragione_sociale_b) return 1;
      if (ragione_sociale_a < ragione_sociale_b) return -1;
      return 0;
    });
  }

  getUnderSupplierNames(supplierName) {
    try {
      const keys = _.keys(this.getData()[supplierName]);
      return keys.filter((key) => key !== "null").sort();
    } catch (err) {
      throw err;
    }
  }

  getUnderSupplierOptions(supplierName) {
    try {
      const keys = _.keys(this.getData()[supplierName]);

      return keys
        .filter((key) => key !== "null")
        .map((key) => {
          return {
            value: key,
            label: key,
          };
        });
    } catch (err) {
      throw err;
    }
  }

  getUncompletedUnderSuppliers(supplier) {
    /*
    {
      fornitore: {
        sottoFornitore: {
        val_lead_time: null
        }
    }
     */

    let data = this.getData()

    let counter = 0
    for (let j in data) {
      if (supplier !== "" && j !== supplier)
        continue;
      for (let k in data[j]) {
        const underSupplier = data[j][k]
        const isValidDate = underSupplier["flg_lunedi"]
          || underSupplier["flg_lunedi"]
          || underSupplier["flg_martedi"]
          || underSupplier["flg_mercoledi"]
          || underSupplier["flg_giovedi"]
          || underSupplier["flg_venerdi"]

        const isValidLeadTime = underSupplier["val_lead_time"] != null && underSupplier["val_lead_time"] > 0

        if ( !(isValidDate && isValidLeadTime ))
          counter += 1
      }
    }
    return counter
  }
}
