import React from "react";
import BaseComponent from "../BaseComponent";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUserEdit} from "@fortawesome/free-solid-svg-icons";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory, {textFilter, Comparator, selectFilter} from "react-bootstrap-table2-filter";
import cellEditFactory from "react-bootstrap-table2-editor";
import "./tables.module.css";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import {Form} from "react-bootstrap";
import * as Validator from "../../Utils/validator.utils";
import ButtonExportCSV from "../Buttons/ExportCSVButton";
import AuthenticationService from "../../services/AuthenticationService";
import {csvFloatFormatter} from "../../Utils";

class ArticoliTable extends BaseComponent {
  constructor(props) {
    super(props);

    this.state = {
      filteredData: [],
      showOutOfStock: false
    }

    // Control if user can see pricing column
    const {user_group} = AuthenticationService.getData();
    this.hidePricing = user_group > 2;

    const measureUnitsOption = [
      {
        value: "PZ",
        label: "PZ",
      },
      {
        value: "KG",
        label: "KG",
      },
      {
        value: "kg",
        label: "kg",
      }
    ];
    const statusOptions = [
      {label: "IN ESAURIMENTO", value: "E"},
      {label: "IN ASSORTIMENTO", value: "I"},
      {label: "NUOVO", value: "N"},
      {label: "FUORI ASSORTIMENTO", value: "F"},
    ];

    const groupOptions = [
      {label: "DEPERIBILI", value: "DP"},
      {label: "FRESCHI", value: "FR"},
      {label: "SECCHI", value: "SE"},
      {label: "SURGELATI", value: "SU"},
    ];
    this.columns = [
      {
        dataField: "cod_prodotto_cir",
        text: "Prodotto",
        type: "number",
        filter: textFilter({
          placeholder: "...",
          comparator: Comparator.EQ,
        }),
        validator: Validator.handlePositiveNumberValidation,
        formatter: this.editableCellFormatter,
        headerStyle: {width: "5%"},
        sort: true
      },
      {
        dataField: "des_prodotto_cir",
        text: "Descrizione Prodotto",
        filter: textFilter({
          placeholder: "...",
        }),
        formatter: this.editableCellFormatterSmall,
        headerStyle: {width: "18%"},
        sort: true
      },
      {
        dataField: "cod_articolo_alt",
        text: "Articolo",
        type: "number",
        filter: textFilter({
          placeholder: "...",
        }),
        editable: false,
        headerStyle: {width: "6%"},
        sort: true
      },
      {
        dataField: "des_articolo",
        text: "Descrizione Articolo",
        filter: textFilter({
          placeholder: "...",
        }),
        formatter: cell => {
          return (<span><small>{cell}</small></span>)
        },
        editable: false,
        headerStyle: {width: "16%"},
        sort: true
      },
      {
        dataField: "fornitori",
        text: "Fornitori",
        filter: textFilter({
          placeholder: "...",
        }),
        formatter: cell => {
          return (<span><small>{cell}</small></span>)
        },
        editable: false,
        headerStyle: {width: "17%"},
        sort: true
      },
      {
        dataField: "des_area_merceologica",
        text: "Area Merceologica",
        filter: textFilter({
          placeholder: "...",
        }),
        editable: false,
        formatter: this.areaMerceologicoFormatter,
        headerStyle: {width: "12%"},
        sort: true
      },
      {
        dataField: "cod_gruppo_merceologico",
        text: "Gruppo",
        filter: selectFilter({
          options: groupOptions,
          placeholder: "...",
        }),
        editable: false,
        headerStyle: {width: "5%"},
        sort: true
      },
      {
        dataField: "cod_stato_articolo",
        text: "Stato",
        filter: selectFilter({
          options: statusOptions,
          placeholder: "...",
        }),
        editable: false,
        headerStyle: {width: "5%"},
        sort: true
      },
      {
        dataField: "um_cliente",
        text: "Um",
        filter: selectFilter({
          options: measureUnitsOption,
          placeholder: "...",
        }),
        editable: false,
        headerStyle: {width: "5%"},
        sort: true
      },
      {
        dataField: "val_kg_per_pezzi",
        text: "Kg/Pz",
        filter: selectFilter({
            placeholder: "...",
            options: {"empty": "N.D."},
            onFilter: (filterVal, data) => {
              if (filterVal) {
                return data.filter(el => el["val_kg_per_pezzi"] == null)
              }
              return data
            }
          }
        ),
        validator: Validator.handlePositiveNumberValidation,
        formatter: user_group < 4 && this.editableCellFormatter,
        csvFormatter: csvFloatFormatter,
        type: "number",
        headerStyle: {width: "5%"},
        sort: true
      },
      {
        dataField: "prezzo",
        text: "Listino",
        comparator: Comparator.LT,
        filter: selectFilter({
            placeholder: "...",
            options: {"empty": "N.D."},
            onFilter: (filterVal, data) => {
              if (filterVal) {
                return data.filter(el => el["prezzo"] == null)
              }
              return data
            }
          }
        ),
        type: "number",
        validator: Validator.handlePositiveNumberValidation,
        formatter: !this.hidePricing && this.editableCellFormatter,
        csvFormatter: csvFloatFormatter,
        headerStyle: {width: "5%"},
        sort: true,
        editable: !this.hidePricing,
      },
    ];

    this.defaultSorted = [
      {
        dataField: "cod_prodotto_cir",
        order: "asc",
      },
    ];
  }

  /* -------- Life cycle ------- */

  componentWillUnmount() {
    this.props.service.setState({filteredData: []})
  }

  /* -------- Handlers ------- */

  handleAfterSaveCell(oldValue, newValue, row) {
    console.log(oldValue, newValue, row);
    if (String(newValue) !== String(oldValue)) {
      this.props.service.addModifiedArticle(row);
    }
  }

  getData() {
    if (!this.state.showOutOfStock)
      return this.props.service.state.lkp_articoli.data.filter(el => el["cod_stato_articolo"] !== "F")

    return this.props.service.state.lkp_articoli.data
  }

  /* -------- Formatters ------- */

  editableCellFormatter(cell) {
    return (
      <span>
        <span className="text-primary">
          <FontAwesomeIcon icon={faUserEdit} size="xs"/>
        </span>{" "}
        {cell}
      </span>
    );
  }

  editableCellFormatterSmall(cell) {
    return (
      <span>
        <span className="text-primary">
          <FontAwesomeIcon icon={faUserEdit} size="xs"/>
        </span>{" "}
        <small>
          {cell}
        </small>
      </span>
    );
  }

  areaMerceologicoFormatter(cell, row) {
    return (
      <span>
        <small>
          <strong>{row["cod_area_merceologica"]}</strong>
          {" - "}
          {row["des_area_merceologica"]}
        </small>
      </span>
    );
  }

  numberOfNullPkPz() {
    const filteredData = this.props.service.state.filteredData.length > 0 ?
      this.props.service.state.filteredData : this.props.service.state.lkp_articoli.data
    return filteredData.filter(el => el["val_kg_per_pezzi"] == null).length

  }

  numberOfNullPrices() {
    const filteredData = this.props.service.state.filteredData.length > 0 ?
      this.props.service.state.filteredData : this.props.service.state.lkp_articoli.data
    return filteredData.filter(el => el["prezzo"] == null).length
  }

  afterFilter(newResult, _) {
    if (this.props.service.state.filteredData.length !== newResult.length) {
      this.props.service.setState({
        filteredData: newResult
      })
    }
  }

  nullsCounter() {
    if (this.hidePricing) {
      return <></>
    }
    return (
      <>
        <b>Record mancanti</b>:
        Kg/Pz <b>{this.numberOfNullPkPz()}</b>;
        Listino <b>{this.numberOfNullPrices()}</b>
      </>
    )
  }

  /* -------- Rendering ------- */

  render() {
    return (
      <ToolkitProvider
        keyField="cod_articolo_alt"
        data={this.getData()}
        columns={this.columns}
        exportCSV={{
          fileName: "articoli.csv",
          separator: ";",
          onlyExportFiltered: true
        }}
      >
        {(props) => (
          <div>
            <div style={{width: "300px", float: "left" }}>
              <Form.Group controlId={"hide-show-fuori-assortimento"}>
                <Form.Check
                  size={"sm"}
                  label={"Mostra prodotti fuori assortimento"}
                  onChange={event => this.setState({showOutOfStock: event.target.checked})}
                />
              </Form.Group>
            </div>
            <div className={"text-right"} style={{margin: "auto"}}>
              {this.nullsCounter()}
            </div>
            <BootstrapTable
              {...props.baseProps}
              bootstrap4
              pagination={paginationFactory()}
              filter={filterFactory({afterFilter: (newResult, newFilters) => this.afterFilter(newResult, newFilters)})}
              defaultSorted={this.defaultSorted}
              rowStyle={{fontSize: "small"}}
              headerClasses={"small"}
              cellEdit={cellEditFactory({
                mode: "click",
                blurToSave: true,
                afterSaveCell: this.handleAfterSaveCell.bind(this),
              })}
            />
            <ButtonExportCSV
              {...props.csvProps}
              disabled={this.props.service.state.lkp_articoli.data.length === 0}
            >
              Esporta CSV</ButtonExportCSV>
          </div>
        )}
      </ToolkitProvider>
    );
  }
}

export default ArticoliTable;
