/*
InventoryRiordinoService.jsx
cir_deploy
Author: Jacopo Gasparetto
Date: 10/08/2020
Organization: Energy Way S.r.l
*/

import ApiService from "../ApiService";

export default class InventoryRiordinoService {

  constructor() {
    this.data = undefined;
    this.serviceType = "inventoryOptimization"
  }

  fetchData(datiAl, runType) {
    const params = `magazzino?dati_al=${datiAl}&am_pm=${runType}`
    ApiService.getEntries(this.serviceType, params)
      .then(data => {
        this.data = data
        console.log("Riordino:", data.length)
      })
  }

  getFilteredDataByArticles(articles=[]) {
    articles = articles.map(el => el.split(" ")[0]);
    return this.data.filter(el => articles.includes(el["cod_prodotto_cir"]))
  }

  filterData(groups, areas, suppliers, articles) {
    let data = [...this.data];

    if (groups.length > 0) {
      groups = groups.map(el => el.split(" ")[0])
      data = data.filter(el => groups.includes(el["des_gruppo_merceologico"]))
    }

    if (areas.length > 0) {
      areas = areas.map(el => el.split(" ")[0])
      console.log(areas)
      data = data.filter(el => areas.includes(el["cod_area_merceologica"]))
    }

    if (suppliers.length > 0) {
      suppliers = suppliers.map(el => el.split(" ")[0])
      data = data.filter(el => suppliers.includes(el["cod_fornitore_alt"]))
    }

    if (articles.length > 0) {
      articles = articles.map(el => el.split(" ")[0])
      data = data.filter(el => articles.includes(el["cod_prodotto_cir"]))
    }

    return data
  }
}