/*
LineChartSkeleton.jsx
cir_deploy
Author: Jacopo Gasparetto
Date: 07/08/2020
Organization: Energy Way S.r.l
*/

import * as am4core from "@amcharts/amcharts4/core";
import {CircleBullet, DateAxis, Legend, LineSeries, XYChart} from "@amcharts/amcharts4/charts";
import am4lang_it_IT from "@amcharts/amcharts4/lang/it_IT";
import {XYCursor} from "@amcharts/amcharts4/.internal/charts/cursors/XYCursor";
import moment from "moment";

export function makeLineChart(chartID, showToday = false) {
  let chart = am4core.create(chartID, XYChart);
  chart.language.locale = am4lang_it_IT;
  chart.paddingRight = 20;

  let dateAxis = chart.xAxes.push(new DateAxis());
  let range = dateAxis.axisRanges.create();
  if (showToday) {
    range.date = new Date(moment().format("YYYY-MM-DDT12:00"))
    range.grid.stroke = am4core.color("#ba1e1e");
    range.grid.strokeWidth = 1;
    range.grid.strokeOpacity = 1;
  }

  chart.legend = new Legend();
  chart.cursor = new XYCursor();
  chart.cursor.xAxis = dateAxis;

  return chart
}

export function createSeries(chart, yAxis, field, name, color = undefined, hiddenInLegend = false, dashed = undefined) {
  let series = chart.series.push(new LineSeries());
  series.dataFields.valueY = field;
  series.dataFields.dateX = "x";
  series.name = name;
  series.yAxis = yAxis

  series.tooltipText = "{dateX}: [bold]{valueY}[/]";

  // chart.cursor.snapToSeries = series;
  if (color) {
    series.stroke = am4core.color(color);
    series.tooltip.getFillFromObject = false;
    series.tooltip.background.fill = am4core.color(color);
    series.strokeWidth = 1;
  }
  if (hiddenInLegend) {
    series.hiddenInLegend = true;
  }

  let bullet = series.bullets.push(new CircleBullet());
  if (color)
    bullet.circle.fill = am4core.color(color);
  bullet.circle.stroke = am4core.color("#fff");
  bullet.circle.strokeWidth = 1;

  if (dashed) {
    series.strokeDasharray = dashed;
  }

  return series;
}