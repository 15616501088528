/*
commons.js
cir_deploy
Author: Jacopo Gasparetto
Date: 10/09/2020
Organization: Energy Way S.r.l
*/

export function getUniqueProducts(data) {
  /*
  Get unique list of products, from a list where multiple suppliers (cod_fornitore_alt) can
  share the same product (cod_prodotto_cir)
   */
  console.log("Removing duplicate articles from different suppliers")
  let prodotti = {}
  return data.filter(el => {
    const listOfProducts = Object.keys(prodotti)
    if (listOfProducts.includes(el["cod_prodotto_cir"])) {
      const fornitori = prodotti[el["cod_prodotto_cir"]] // Ho già visto questo fornitore? Se sì, tieni il prodotto
      return fornitori.includes(el["cod_fornitore_alt"])
    } else {                                             // Se non l'ho mai visto tienilo e aggiorna prodotti
      prodotti[el["cod_prodotto_cir"]] = [el["cod_fornitore_alt"]]
      return true
    }
  })
}