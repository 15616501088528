/*
ModalChangePassword.jsx
cir_deploy
Author: Jacopo Gasparetto
Date: 28/09/2020
Organization: Energy Way S.r.l
*/
import React, {useState} from "react";
import {Button, Form, Modal} from "react-bootstrap";
import ApiService from "../../services/ApiService";
import {routes} from "../../Utils/constants";

export const ModalChangePassword = (props) => {
  const errorWrongPassword = "Password errata"
  const errorPasswordsMismatch = "Le due password non corrispondono"

  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [validated, setValidated] = useState(false);
  const [success, setSuccess] = useState(false);
  const [isPasswordWrong, setIsPasswordWrong] = useState(false);

  const handleOnHide = () => {
    setCurrentPassword("");
    setNewPassword("");
    setConfirmPassword("");
    setValidated(false);
    setSuccess(false);
    props.onHide();
  }

  const handleSubmit = (event) => {
    console.log("submit")
    event.preventDefault();

    // if (validated) setValidated(false);
    if (newPassword !== confirmPassword) {
      setValidated(true)
      return
    }

    const data = {
      email: props.email,
      username: props.username,
      currentPassword: currentPassword,
      newPassword: newPassword
    }
    ApiService.postData(routes.changePassword, data)
      .then(_ => {
        setIsPasswordWrong(false);
        setSuccess(true)
      })
      .catch(err => {
        if (err.response.status === 401) {
          setIsPasswordWrong(true);
        } else {
          console.log(err)
        }
      })

    setValidated(true);
  }

  return (
    <Modal
      onHide={_ => handleOnHide()}
      size="lg"
      show={props.show}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="modal-change-password">
          Modifica Password
        </Modal.Title>
      </Modal.Header>

      <Form noValidate onSubmit={event => handleSubmit(event)}>
        <Modal.Body>
          <Form.Group controlId={"formBasicPassword"}>
            <Form.Label>Password</Form.Label>
            <Form.Control
              type={"password"}
              placeholder={"Password corrente"}
              autoComplete={"current-password"}
              required
              onChange={event => setCurrentPassword(event.target.value)}
              isValid={success}
              isInvalid={validated && isPasswordWrong}
            />
            <Form.Control.Feedback type="invalid">{errorWrongPassword}</Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId={"new-password"}>
            <Form.Label>Nuova password</Form.Label>
            <Form.Control
              type={"password"}
              placeholder={"Nuova password"}
              autoComplete={"new-password"}
              required
              onChange={event => setNewPassword(event.target.value)}
              isInvalid={validated && newPassword !== confirmPassword}
              isValid={success}
            />
            <Form.Control.Feedback type="invalid">{errorPasswordsMismatch}</Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId={"confirm-password"}>
            <Form.Label>Conferma password</Form.Label>
            <Form.Control
              type={"password"}
              placeholder={"Conferma password"}
              autoComplete={"new-password"}
              onChange={event => setConfirmPassword(event.target.value)}
              isInvalid={validated && newPassword !== confirmPassword}
              required
              isValid={success}
            />
            <Form.Control.Feedback type="invalid">{errorPasswordsMismatch}</Form.Control.Feedback>
          </Form.Group>
        </Modal.Body>

        <Modal.Footer>
          <Button
            className={"btn-secondary"}
            onClick={handleOnHide}>
            Chiudi
          </Button>
          <Button type={"submit"} disabled={success}>
            Modifica password
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}
