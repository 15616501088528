import { Container } from "unstated";
import ApiService from "../ApiService";

import _ from "lodash";

export default class DataEntryService extends Container {
  constructor() {
    super();
    this.updateModifiedArticles = this.updateModifiedArticles.bind(this);
    this.updateModifiedDestinatari = this.updateModifiedDestinatari.bind(this);

    this.state = {
      lkp_articoli: {
        data: [],
        changedValues: {},
        lastUpdate: null,
      },
      lkp_bancalizzazione_fornitore: {
        data: null,
        lastUpdate: null,
      },
      lkp_destinatari: {
        data: [],
        changedValues: {},
        lastUpdate: null
      },
      filteredData: []
    };
  }

  /* ----------- lkp_articoli ---------- */

  async getArticles() {
    try {
      const articles = await ApiService.getEntries("articles");

      this.setState({
        lkp_articoli: {
          ...this.state.lkp_articoli,
          data: articles,
          lastUpdate: new Date(),
        },
      });

      return articles;
    } catch (err) {
      throw err;
    }
  }

  updateModifiedArticles() {
    const actualModifiedArticle = this.state.lkp_articoli.changedValues;
    _.keys(actualModifiedArticle).forEach((id) => {
      const article = this.state.lkp_articoli.changedValues[id];

      try {
        ApiService.updateEntry("articles", id, article);
        delete actualModifiedArticle[id];
      } catch (err) {
        throw err;
      }
    });

    this.setState({
      lkp_articoli: {
        ...this.state.lkp_articoli,
        changedValues: actualModifiedArticle,
        lastUpdate: new Date(),
      },
    });
  }

  isArticlesUpdated() {
    const objectsNumber = _.keys(this.state.lkp_articoli.changedValues);

    return objectsNumber.length === 0;
  }

  addModifiedArticle(article) {
    const actualModifiedArticle = this.state.lkp_articoli.changedValues;
    actualModifiedArticle[article.cod_articolo_alt] = article;

    this.setState({
      lkp_articoli: {
        ...this.state.lkp_articoli,
        changedValues: actualModifiedArticle,
      },
    });

    return actualModifiedArticle;
  }

  /* ----------- lkp_bancalizzazione_fornitore ---------- */

  async getSuppliers() {
    try {
      const palletsSuppliers = await ApiService.getEntries("pallets/suppliers");

      const actualSuppliers = {};

      palletsSuppliers.forEach((supplier) => {
        actualSuppliers[supplier.cod_fornitore_alt] = {};
      });

      palletsSuppliers.forEach((supplier) => {
        actualSuppliers[supplier.cod_fornitore_alt][
          supplier.sotto_fornitore
        ] = [supplier];
      });

      this.setState({
        lkp_bancalizzazione_fornitore: {
          ...this.state.lkp_bancalizzazione_fornitore,
          data: actualSuppliers,
          lastUpdate: new Date(),
        },
      });

      return palletsSuppliers;
    } catch (err) {
      throw err;
    }
  }

  getField(field) {
    const value = _.get(
      this.state.lkp_bancalizzazione_fornitore.data,
      field,
      undefined
    );
    return value;
  }

  setField(field, value) {
    this.setState({
      lkp_bancalizzazione_fornitore: {
        ...this.state.lkp_bancalizzazione_fornitore,
        data: _.set(
          this.state.lkp_bancalizzazione_fornitore.data,
          field,
          value
        ),
        lastUpdate: new Date(),
      },
    });
  }

  getSuppliersNames() {
    return _.keys(this.state.lkp_bancalizzazione_fornitore.data);
  }

  getUnderSupplierNames(supplierName) {
    try {
      return _.keys(
        this.state.lkp_bancalizzazione_fornitore.data[supplierName]
      );
    } catch (err) {
      return [];
    }
  }

  /* ----------- lkp_destinatari ---------- */

  async getDestinatari() {
    console.log("get destinatari")
    try {
      const destinatari = await ApiService.getEntries("destinatari")

      await this.setState({

        lkp_destinatari: {
          ...this.state.lkp_destinatari,
          data: destinatari,
          lastUpdate: new Date()
        }
      });
      return destinatari;
    } catch (err) {
      throw err;
    }
  }

  isDestinatariUpdated() {
    const objectsNumber = _.keys(this.state.lkp_destinatari.changedValues);
    return objectsNumber.length === 0;
  }

  addModifiedDestinatario(destinatario) {
    const actualModifiedArticle = this.state.lkp_destinatari.changedValues;
    actualModifiedArticle[destinatario["cod_destinatario_alt"]] = destinatario

    this.setState({
      lkp_destinatari: {
        ...this.state.lkp_destinatari,
        changedValues: actualModifiedArticle
      }
    });
    console.log(this.state)
    return actualModifiedArticle
  }

  updateModifiedDestinatari() {
    console.log("ciao")
    const actualModifiedDestinatari = this.state.lkp_destinatari.changedValues;
    _.keys(actualModifiedDestinatari).forEach(id => {
      const destinatari = this.state.lkp_destinatari.changedValues[id]

      try {
        ApiService.updateEntry("destinatari", id, destinatari);
        delete actualModifiedDestinatari[id];
      } catch (err) {
        throw err;
      }
    });

    this.setState({
      lkp_destinatari: {
        ...this.state.lkp_destinatari,
        changedValues: actualModifiedDestinatari,
        lastUpdate: new Date(),
      }
    })
  }

}
