import React from "react";
import BaseComponent from "../../components/BaseComponent";

import KPIService from "../../services/KPIService";

import MapItalyKPI from "../../components/Maps/MapItalyKPI";
import { Container, Row, Col, Button } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";

import KpiDiff from "../../components/Kpi";

import {
  faLeaf,
  faWarehouse,
  faAppleAlt,
  faSnowflake,
  faArchive,
} from "@fortawesome/free-solid-svg-icons";
import ChartKpiBar from "../../components/Charts/ChartKpiBar";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEraser } from "@fortawesome/free-solid-svg-icons";

import _ from "lodash";
import { LoadingSpinner } from "../../components/Misc/LoadingSpinner";

import * as am4core from "@amcharts/amcharts4/core";
import RangeDayPicker from "../../components/LocalizedDayInputPicker/RangeDayPicker";

import { dateTostring } from "../../Utils";
import SingleChartKpiBar from "../../components/Charts/SingleChartKpiBar";

class Home extends BaseComponent {
  constructor(props) {
    super(props);

    [this.kpiService] = this.props.states;

    this.state = {
      from: undefined,
      to: undefined,

      updatingEntries: false,
    };

    this.gruppiMerceoligici = [
      {
        name: "DEPERIBILI 0 - 2°",
        title: "Deperibili",
        icon: faLeaf,
      },
      {
        name: "FRESCHI",
        title: "Freschi",
        icon: faAppleAlt,
      },
      {
        name: "SECCHI",
        title: "Secchi",
        icon: faArchive,
      },
      {
        name: "SURGELATI",
        title: "Surgelati",
        icon: faSnowflake,
      },
    ];
  }

  componentDidMount() {
    // Setting chart global var
    am4core.options.onlyShowOnViewport = true;

    if (
      !_.isEmpty(this.kpiService.state.data.entrate) &&
      !_.isEmpty(this.kpiService.state.data.uscite)
    ) {
      // Exit if data exist
      return;
    }

    this.kpiService.retrieveDefaultPeriod();

  }

  handleOnChangeFilterAreaMerceologica(selected) {
    // optimization
    const newState = {
      ...this.kpiService.state,
      filterAreaMerceologica: selected,
    };

    this.kpiService._applyFilter(newState);
  }

  handleOnChangeFilterForntitore(selected) {
    // optimization
    const newState = {
      ...this.kpiService.state,
      filterFornitore: selected,
    };

    this.kpiService._applyFilter(newState);
  }

  handleOnChangeFilterArticolo(selected) {
    // optimization
    const newState = {
      ...this.kpiService.state,
      filterArticolo: selected,
    };

    this.kpiService._applyFilter(newState);
  }

  handleOnChangeFilterBusiness(selected) {
    // optimization
    const newState = {
      ...this.kpiService.state,
      filterBusiness: selected,
    };

    this.kpiService._applyFilter(newState);
  }

  handleOnChangeFilterAreaterritoriale(selected) {
    // optimization
    const newState = {
      ...this.kpiService.state,
      filterAreaTerritoriale: selected,
    };

    this.kpiService._applyFilter(newState);
  }

  handleResetFIlters() {
    const newState = {
      ...this.kpiService.state,
      filterAreaMerceologica: [],
      filterFornitore: [],
      filterArticolo: [],
      filterBusiness: [],
      filterAreaTerritoriale: [],
    };

    this.kpiService._applyFilter(newState);
  }

  handleOnChangeFrom(from) {
    this.setState({ from });
  }

  async handleOnChangeTo(to) {
    await this.setState({ to });
  }

  async handleChangeDate() {
    const from = dateTostring(this.state.from);
    const to = dateTostring(this.state.to);

    this.setState({ updatingEntries: true }, () => {
      this.kpiService.retrieveSpecificData(from, to).then(() => {
        this.setState({ updatingEntries: false });
      });
    });
  }

  render() {
    const { state } = this;

    console.count("render-home");

    const options = {
      areaMeceologica: [],
      fornitore: [],
      articolo: [],
      business: [],
      areaTerritoriale: [],
    };

    if (this.kpiService.state.ready) {
      options.areaMeceologica = this.kpiService.getOptionsAreaMerceologica();
      options.fornitore = this.kpiService.getOptionsFornitore();
      options.articolo = this.kpiService.getOptionsArticolo();
      options.business = this.kpiService.getOptionsBusiness();
      options.areaTerritoriale = this.kpiService.getOptionsAreaTerritoriale();
    }

    return (
      <>
        <Container fluid className="mt-3">

          {!this.kpiService.state.ready ? (
            <LoadingSpinner />
          ) : (
            <>
              <Row>
                <Col lg={4} className="text-center">
                    <h5 className="mt-3">Seleziona il periodo</h5>
                    <RangeDayPicker
                      defaultFrom={this.kpiService.state.from}
                      defaultTo={this.kpiService.state.to}
                      from={this.state.from}
                      onChangeFrom={this.handleOnChangeFrom.bind(this)}
                      to={this.state.to}
                      onChangeTo={this.handleOnChangeTo.bind(this)}
                    />

                    <br />

                    <Button
                      variant="success"
                      onClick={this.handleChangeDate.bind(this)}
                      disabled={!this.state.from || !this.state.to}
                      className="mb-3"
                    >
                      {state.updatingEntries
                        ? "Aggiorno ..."
                        : "Applica"}
                    </Button>
                  </Col>
                <Col className="text-center" style={{marginLeft: "-20px", marginRight: "-10px"}}>
                    <KpiDiff
                      title="Magazzino"
                      icon={faWarehouse}
                      values={this.kpiService.getTotaleMagazzino()}
                    />
                </Col>
                {this.gruppiMerceoligici.map((gruppo, i) => {
                  return (
                    <Col key={i} style={{marginLeft: "-10px", marginRight: "-10px"}}>
                      <KpiDiff
                        title={gruppo.title}
                        icon={gruppo.icon}
                        values={this.kpiService.getFilteredKpi(
                          "des_gruppo_merceologico",
                          gruppo.name
                        )}
                      />
                    </Col>
                  );
                })}
              </Row>
              <Row>
                <Col lg={4}>
                  <Row className="mt-2 mb-5">
                    <MapItalyKPI data={this.kpiService.getMapData()} />
                  </Row>
                  <Row className="mt-3">
                    <Col className="text-center">
                      <Button
                        variant="secondary"
                        onClick={this.handleResetFIlters.bind(this)}
                        disabled={
                          this.kpiService.filterAreaMerceologica.length === 0 &&
                          this.kpiService.filterFornitore.length === 0 &&
                          this.kpiService.filterArticolo.length === 0 &&
                          this.kpiService.filterBusiness.length === 0 &&
                          this.kpiService.filterAreaTerritoriale.length === 0
                        }
                      >
                        <FontAwesomeIcon icon={faEraser} /> Cancella tutti i filtri
                      </Button>
                    </Col>
                  </Row>
                </Col>
                <Col lg={8}>
                  <Row className="mt-2">
                <Col>
                  <Row>
                    <Col className="text-center">
                      <h5>Area merceologica</h5>
                      <Typeahead
                        clearButton
                        id="filter-area-meceologica"
                        options={options.areaMeceologica}
                        onChange={this.handleOnChangeFilterAreaMerceologica.bind(
                          this
                        )}
                        selected={this.kpiService.filterAreaMerceologica}
                        placeholder="Seleziona area merceologica"
                        multiple
                      />
                      <ChartKpiBar
                        id="chart-ref-area-meceologica"
                        data={this.kpiService.getChartData("area-merceologica")}
                      />
                    </Col>
                    <Col className="mt-1 text-center">
                      <h5>Fornitore</h5>
                      <Typeahead
                        clearButton
                        id="filter-fornitore"
                        options={options.fornitore}
                        onChange={this.handleOnChangeFilterForntitore.bind(
                          this
                        )}
                        selected={this.kpiService.filterFornitore}
                        placeholder="Seleziona fornitore"
                        multiple
                      />
                      <ChartKpiBar
                        id="chart-ref-fornitore"
                        data={this.kpiService.getChartData("fornitore")}
                      />
                    </Col>
                    <Col className="mt-1 text-center">
                      <h5>Articolo</h5>
                      <Typeahead
                        clearButton
                        id="filter-articolo"
                        options={options.articolo}
                        onChange={this.handleOnChangeFilterArticolo.bind(this)}
                        selected={this.kpiService.filterArticolo}
                        placeholder="Seleziona articolo"
                        multiple
                      />
                      <ChartKpiBar
                        id="chart-ref-articolo"
                        data={this.kpiService.getChartData("articolo")}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
                  <Row>
                    <Col className="mt-1 text-center">
                      <h5>Business</h5>
                      <Typeahead
                        clearButton
                        id="filter-business"
                        options={options.business}
                        onChange={this.handleOnChangeFilterBusiness.bind(this)}
                        selected={this.kpiService.filterBusiness}
                        placeholder="Seleziona business"
                        multiple
                      />
                      <SingleChartKpiBar
                        id="chart-ref-business"
                        data={this.kpiService.getChartData("business")}
                      />
                    </Col>
                    <Col className="mt-1 text-center">
                      <h5>Area territoriale</h5>
                      <Typeahead
                        clearButton
                        id="filter-area-territoriale"
                        options={options.areaTerritoriale}
                        onChange={this.handleOnChangeFilterAreaterritoriale.bind(
                          this
                        )}
                        selected={this.kpiService.filterAreaTerritoriale}
                        placeholder="Seleziona area territoriale"
                        multiple
                      />
                      <SingleChartKpiBar
                        id="chart-ref-area-territoriale"
                        data={this.kpiService.getChartData("area-territoriale")}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </>
          )}
        </Container>
      </>
    );
  }
}
export default Home.withStates([KPIService]);
