/*
MagazzinoTable.jsx
cir_deploy
Author: Jacopo Gasparetto
Date: 10/08/2020
Organization: Energy Way S.r.l
*/
import React, {useState} from "react";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ButtonExportCSV from "../Buttons/ExportCSVButton";
import moment from "moment";
import {csvFloatFormatter, numberFormatter} from "../../Utils";
import ApiService from "../../services/ApiService";
import {Modal} from "react-bootstrap";

function ArticlePopup(props) {

  const columns = [
    {
      dataField: "cod_articolo_alt",
      text: "Articolo",
      type: "number",
      headerStyle: {width: "6%"},
      sort: true
    },
    {
      dataField: "des_articolo",
      text: "Descrizione Articolo",
      formatter: cell => {return (<span><small>{cell}</small></span>)},
      headerStyle: {width: "20%"},
      sort: true
    },
    {
      dataField: "fornitori",
      text: "Fornitori",
      formatter: cell => {return (<span><small>{cell}</small></span>)},
      headerStyle: {width: "20%"},
      sort: true
    },
    {
      dataField: "des_area_merceologica",
      text: "Area Merceologica",
      formatter: cell => {return (<span><small>{cell}</small></span>)},
      headerStyle: {width: "16%"},
      sort: true
    },
    {
      dataField: "cod_gruppo_merceologico",
      text: "Gruppo",
      headerStyle: {width: "6%"},
      sort: true
    },
    {
      dataField: "cod_stato_articolo",
      text: "Stato",
      headerStyle: {width: "6%"},
      sort: true
    },
    {
      dataField: "um_cliente",
      text: "Um",
      headerStyle: {width: "6%"},
      sort: true
    },
    {
      dataField: "val_kg_per_pezzi",
      text: "Kg/Pz",
      type: "number",
      headerStyle: {width: "6%"},
      sort: true
    }
  ];

  return (
    <div style={{width: "100%"}}>
      <Modal
        show={props.show}
        onHide={props.handleClose}
        dialogClassName="modal-xl"
        aria-labelledby="example-custom-modal-styling-title"
      >
        {props.articles.length > 0 &&
          <>
        <Modal.Header closeButton>
          <Modal.Title>
            {`${props.articles[0]["cod_prodotto_cir"]} - ${props.articles[0]["des_prodotto_cir"]}`}
          </Modal.Title>
        </Modal.Header>
          <Modal.Body>
          <BootstrapTable
          bootstrap4
          columns={columns}
          data={props.articles}
          rowStyle={{overflowWrap: 'break-word', fontSize: "small"}}
          headerClasses={"small"}
          keyField={"cod_articolo_alt"}
          />
          </Modal.Body>
        </>
        }
      </Modal>
    </div>
  )
}

export function AlertUnderStockTable(props) {

  const [selectedArticles, setSelectedArticles] = useState([])

  const handleClose = () => {
    setSelectedArticles([])
  }
  const handleShow = (articles) => {
    setSelectedArticles(articles)
  }

  const columns = [
    {
      dataField: "cod_prodotto_cir",
      text: "Prod",
      type: "string",
      sort: true
    },
    {
      dataField: "des_prodotto_cir",
      text: "Descrizione",
      formatter: cell => {
        return (<span><small>{cell}</small></span>)
      },
      type: "string",
      sort: true
    },
    {
      dataField: "dat_prevista_consegna",
      text: "Data Consegna",
      type: "date",
      sort: true
    },
    {
      dataField: "val_riordino_kg",
      text: "Riordino (Kg)",
      type: "number",
      sort: true,
      csvFormatter: csvFloatFormatter,
      formatter: cell => {
        return numberFormatter(cell.toFixed(2), ".")
      }
    },
    {
      dataField: "val_safety_kg",
      text: "Safety (Kg)",
      type: "number",
      sort: true,
      csvFormatter: csvFloatFormatter,
      formatter: cell => {
        return numberFormatter(cell.toFixed(2), ".")
      }
    },
    {
      dataField: "val_svuotamento_al_giorno",
      text: "Senza Riordino (Kg)",
      type: "number",
      sort: true,
      csvFormatter: csvFloatFormatter,
      formatter: cell => {
        return numberFormatter(cell.toFixed(2), ".")
      }
    },
    {
      dataField: "val_under_stock",
      text: "Delta (Kg)",
      type: "number",
      sort: true,
      csvFormatter: csvFloatFormatter,
      formatter: cell => {
        return numberFormatter(cell.toFixed(2), ".")
      }
    }

  ]

  const rowEvents = {
    onClick: (_, el) => {
      ApiService.getEntries('articles', `?cod_prodotto_cir=${el["cod_prodotto_cir"]}`)
        .then(data => {
          handleShow(data)
        })
    }
  }

  const parseDates = (data) => {
    return data.map(el => {
      el["dat_prevista_consegna"] = moment(el["dat_prevista_consegna"]).format("YYYY-MM-DD")
      return el
    })
  }

  const getData = () => {
    if (props.data.length > 0) {
      return parseDates(props.data).map(el => {
        el["key"] = el["cod_prodotto_cir"] + "-" + el["dat_prevista_consegna"]
        return el
      })
    }
    return []
  }

  return (
    <ToolkitProvider
      keyField="key"
      data={getData()}
      columns={columns}
      exportCSV={{
        fileName: "alert_riordini_urgenti.csv",
        separator: ";"
      }}
    >
      {innerProps => (
        <div style={{width: "99%", margin: "auto"}}>
          <BootstrapTable
            {...innerProps.baseProps}
            bootstrap4
            pagination={paginationFactory({sizePerPage: 25, paginationSize: 2})}
            rowStyle={{overflowWrap: 'break-word', cursor: "pointer", fontSize: "small"}}
            rowEvents={rowEvents}
            hover
            condensed
            headerClasses={"small"}
            noDataIndication={() => <h3>Nessun dato disponibile.</h3>}
          />
          <ButtonExportCSV
            {...innerProps.csvProps}
            disabled={props.data.length === 0}
          >
            Esporta CSV
          </ButtonExportCSV>
          <ArticlePopup
            show={selectedArticles.length > 0}
            articles={selectedArticles}
            handleClose={handleClose}
          />
        </div>
      )}
    </ToolkitProvider>
  )
}

export function AlertOverStockTable(props) {
  const [selectedArticles, setSelectedArticles] = useState([])

  const handleClose = () => {
    setSelectedArticles([])
  }
  const handleShow = (articles) => {
    setSelectedArticles(articles)
  }

  const columns = [
    {
      dataField: "cod_prodotto_cir",
      text: "Prod",
      type: "string",
      sort: true
    },
    {
      dataField: "des_prodotto_cir",
      text: "Descrizione",
      formatter: cell => {
        return (<span><small>{cell}</small></span>)
      },
      type: "string",
      sort: true
    },
    {
      dataField: "dat_magazzino",
      text: "Data Esubero",
      type: "date",
      sort: true
    },
    {
      dataField: "val_waste_food_kg",
      text: "Scarto (Kg)",
      type: "number",
      sort: true,
      csvFormatter: csvFloatFormatter
    }
  ]

  const getData = () => {
    if (props.data.length > 0) {
      return parseDates(props.data).map(el => {
        el["key"] = el["cod_prodotto_cir"] + "-" + el["dat_magazzino"]
        return el
      })
    }
    return []
  }

  const rowEvents = {
    onClick: (_, el) => {
      ApiService.getEntries('articles', `?cod_prodotto_cir=${el["cod_prodotto_cir"]}`)
        .then(data => {
          handleShow(data)
        })
    }
  }

  const parseDates = (data) => {
    return data.map(el => {
      el["dat_magazzino"] = moment(el["dat_magazzino"]).format("YYYY-MM-DD")
      return el
    })
  }

  return (
    <ToolkitProvider
      keyField="key"
      data={getData()}
      columns={columns}
      exportCSV={{
        fileName: "alert_prodotti_esubero.csv",
        separator: ";"
      }}
    >
      {innerProps => (
        <div style={{width: "99%", margin: "auto"}}>
          <BootstrapTable
            {...innerProps.baseProps}
            bootstrap4
            hover
            condensed
            pagination={paginationFactory({sizePerPage: 25, paginationSize: 2})}
            rowStyle={{overflowWrap: 'break-word', cursor: "pointer", fontSize: "small"}}
            rowEvents={rowEvents}
            headerClasses={"small"}
            noDataIndication={() => <h3>Nessun dato disponibile.</h3>}
          />
          <ButtonExportCSV
            {...innerProps.csvProps}
            disabled={props.data.length === 0}
          >
            Esporta CSV
          </ButtonExportCSV>
          <ArticlePopup
            show={selectedArticles.length > 0}
            articles={selectedArticles}
            handleClose={handleClose}
          />
        </div>
      )}
    </ToolkitProvider>
  )
}