/*
MagazzinoTable.jsx
cir_deploy
Author: Jacopo Gasparetto
Date: 10/08/2020
Organization: Energy Way S.r.l
*/
import React from "react";
import {csvFloatFormatter, numberFormatter} from "../../Utils";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ButtonExportCSV from "../Buttons/ExportCSVButton";
import moment from "moment";
import "./InventoryRiordinoTable.css"
import  filterFactory, {textFilter} from "react-bootstrap-table2-filter";

//TODO: add columns

export default function InventoryRiordinoTable(props) {
  const columns = [
    {
      dataField: "cod_prodotto_cir",
      text: "Prod",
      type: "number",
      filter: textFilter({
        placeholder: "..."
      }),
      sort: true,
      style: { fontSize: 'small' }
    },
    {
      dataField: "cod_articolo_alt",
      text: "Articolo",
      type: "string",
      // style: { 'white-space' : 'nowrap' }
      filter: textFilter({
        placeholder: "..."
      }),
      sort: true,
      style: { fontSize: 'small' }
    },
    {
      dataField: "des_articolo",
      text: "Descrizione",
      type: "string",
      filter: textFilter({
        placeholder: "..."
      }),
      sort: true,
      style: {fontSize: 'small'},
      headerStyle: {width: "13%"},
    },
    {
      dataField: "cod_gruppo_merceologico",
      text: "GM",
      type: "string",
      filter: textFilter({
        placeholder: "..."
      }),
      sort: true,
      style: { fontSize: 'small' }
    },
    {
      dataField: "des_area_merceologica",
      text: "Area",
      type: "string",
      filter: textFilter({
        placeholder: "..."
      }),
      sort: true,
      style: { fontSize: 'small' },
      headerStyle: {width: "13%"}
    },
    {
      dataField: "ragione_sociale",
      text: "Fornitore",
      type: "string",
      filter: textFilter({
        placeholder: "..."
      }),
      sort: true,
      style: { fontSize: 'small' },
      headerStyle: {width: "13%"},
    },
    {
      dataField: "sotto_fornitore",
      text: "Sotto-fornitore",
      type: "string",
      filter: textFilter({
        placeholder: "..."
      }),
      sort: true,
      style: { fontSize: 'small' },
      headerStyle: {width: "12%"},
    },
    {
      dataField: "dat_riordino",
      text: "Data Riordino",
      type: "date",
      filter: textFilter({
        placeholder: "..."
      }),
      sort: true,
      style: { whiteSpace : 'nowrap', fontSize: 'small'}
    },
    {
      dataField: "dat_prevista_consegna",
      text: "Consegna Prevista",
      type: "date",
      filter: textFilter({
        placeholder: "..."
      }),
      sort: true,
      style: { whiteSpace : 'nowrap', fontSize: 'small'}
    },
    {
      dataField: "val_riordino_int",
      text: "Quantità (Pezzi)",
      type: "number",
      filter: textFilter({
        placeholder: "..."
      }),
      formatter: cell => {
        if (cell) {
          return numberFormatter(cell.toFixed(0), ".")
        }
      },
      csvFormatter: csvFloatFormatter,
      sort: true,
      style: { fontSize: 'small'}
    },
    {
      dataField: "val_riordino_cli",
      text: "Quantità Cliente",
      type: "number",
      formatter: cell => {
        if (cell) {
          return numberFormatter(cell.toFixed(2), ".")
        }
      },
      csvFormatter: csvFloatFormatter,
      filter: textFilter({
        placeholder: "..."
      }),
      sort: true,
      style: { fontSize: 'small'}
    },
    {
      dataField: "um_cliente",
      text: "UM",
      type: "string",
      filter: textFilter({
        placeholder: "..."
      }),
      sort: true,
      style: { fontSize: 'small'}
    },
    {
      dataField: "val_riordino_pallet",
      text: "Pallet",
      type: "number",
      formatter: cell => {
        if (cell) {
          return numberFormatter(cell.toFixed(2), ".")
        }
      },
      csvFormatter: csvFloatFormatter,
      filter: textFilter({
        placeholder: "..."
      }),
      sort: true,
      style: { fontSize: 'small'}
    }
  ]

  const paginatorOptions = {
    sizePerPage: 8,
    hideSizePerPage: true,
  };

  const rowStyle = (_, __) => {
    return { overflowWrap: 'break-word' };
  };

  const parseDates = (data) => {
    return data.map(el => {
      el["id"] = el["dat_dati_al"] + el["cod_articolo_alt"] + el["periodo"] + el["cod_fornitore_alt"] + el["sotto_fornitore"]
      el["dat_riordino"] = moment(el["dat_riordino"]).format("YYYY-MM-DD");
      el["dat_prevista_consegna"] = moment(el["dat_prevista_consegna"]).format("YYYY-MM-DD")
      return el
    })
  }

  return (
    <ToolkitProvider
      keyField="id"
      data={props.data.length > 0 ? parseDates(props.data) : []}
      columns={columns}
      exportCSV={{
          fileName: 'riordino.csv',
          separator: ';',
          onlyExportFiltered: true
        }}
    >
      {props => (
        <div style={{width: "100%"}}>
          <BootstrapTable
            {...props.baseProps}
            bootstrap4
            pagination={paginationFactory(paginatorOptions)}
            filter={filterFactory()}
            rowStyle={rowStyle}
            headerClasses={"small"}
            noDataIndication={ () => <h3>Nessun dato disponibile.</h3>  }
          />
          <ButtonExportCSV
            className={"export-csv-button"}
            {...props.csvProps}
          >
            Esporta CSV
          </ButtonExportCSV>
        </div>
      )}
    </ToolkitProvider>
  )
};