import React from "react";
import Form from "react-bootstrap/Form";

export default function TextField(props) {
  const value = props.service.getField(props.fieldName) || "";

  const handleOnChange = (value) => {
    if (value === "") value = null;
    props.service.setField(props.fieldPath, value);
  }


  return (
    <Form.Group>
      <Form.Label>{props.label}</Form.Label>
      <Form.Control
        size={"sm"}
        type="text"
        value={value}
        onChange={event => handleOnChange(event.target.value)}
        disabled={props.disabled}
      />
    </Form.Group>
  );
}
