/*
index.jsx
cir_deploy
Author: Jacopo Gasparetto
Date: 16/07/2020
Organization: Energy Way S.r.l
*/
import React from "react";
import DayInputPicker from "react-day-picker/DayPickerInput";
import { DateUtils } from "react-day-picker";
import dateFnsFormat from "date-fns/format";
import dateFnsParse from "date-fns/parse";
import { subDays } from "date-fns";
import MomentLocaleUtils from 'react-day-picker/moment';
import 'moment/locale/it';
import "react-day-picker/lib/style.css";
import "./inputPicker.css";

const LocalizedDayInputPicker = (props) => {
  const lastAvailableDate = props.lastAvailableDate;
  const before = subDays(lastAvailableDate, 30);

  const disabledDays = props.disabledDays
    props.disabledDays.push({ before: before, after: lastAvailableDate })

  const selectedDay = "value" in props ? props.value : new Date()

  const FORMAT = "dd/MM/yyyy";

  function parseDate(str, format, locale) {
    const parsed = dateFnsParse(str, format, lastAvailableDate, { locale });
    if (DateUtils.isDate(parsed)) {
      return parsed;
    }
    return undefined;
  }

  function formatDate(date, format, locale) {
    return dateFnsFormat(date, format, { locale });
  }

  return (
    <div style={{ lineHeight: 1.2 }}>
      <DayInputPicker
        {...props}
        formatDate={formatDate}
        format={FORMAT}
        parseDate={parseDate}
        placeholder={`${dateFnsFormat(lastAvailableDate, FORMAT)}`}
        dayPickerProps={{
          selectedDays: selectedDay,
          localeUtils: MomentLocaleUtils,
          disabledDays: disabledDays,
        }}
      />
    </div>
  );
};

export default LocalizedDayInputPicker;
