import React from "react";
import Form from "react-bootstrap/Form";

export default function BooleanField(props) {
  const handleOnChange = (checked) => {
    props.service.setField(props.fieldPath, checked);
  }

  let checked = false;
  try {
    checked = props.service.getField(props.fieldPath) || false;
  } catch (err) {
    console.error("[ERROR] Handler not found");
  }

  return (
    <Form.Group style={{marginLeft: "20px", maxWidth: "200px"}} controlId={props.fieldName.replace("_", "-")}>
      <Form.Check
        size={"sm"}
        label={props.label}
        checked={checked}
        onChange={_ => handleOnChange(!checked)}
        disabled={props.disabled}
      />
    </Form.Group>
  );

}
