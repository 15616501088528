import React from "react";

import { numberFormatter } from "../../Utils";

import {
  faSignInAlt,
  faSignOutAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Badge from "react-bootstrap/Badge";

export default function KpiDiff(props) {
  if (!props.values) return <p>Loading ...</p>;

    if (props.title === 'Magazzino') {
          return (
            <div className="text-center">
              <h2 style={{color: '#505050'}}>
                <FontAwesomeIcon icon={props.icon} />
              </h2>
              <h4><strong>{props.title}</strong></h4>
              <h5>
                <Badge variant="success" style={{fontSize: 'medium'}}>  IN {" "}
                  <FontAwesomeIcon icon={faSignInAlt} />{" "}
                  {numberFormatter(props.values.entrate, ".")} KG
                </Badge>
                <br />
                <Badge variant="primary" style={{fontSize: 'medium', marginTop: "2px"}}> OUT {" "}
                  <FontAwesomeIcon icon={faSignOutAlt} />{" "}
                  {numberFormatter(props.values.uscite, ".")} KG
                </Badge>
              </h5>
            </div>
          );
    }
  return (
    <div className="text-center">
      <h2 style={{color: '#505050'}}>
        <FontAwesomeIcon icon={props.icon} />
      </h2>
      <strong>{props.title}</strong>
      <h5>
        <Badge variant="success" style={{fontSize: 'small'}}> IN {" "}
          <FontAwesomeIcon icon={faSignInAlt} />{" "}
          {numberFormatter(props.values.entrate, ".")} KG
        </Badge>
        <br />
        <Badge variant="primary" style={{marginTop: "2px", fontSize: 'small'}}>OUT {" "}
          <FontAwesomeIcon icon={faSignOutAlt} />{" "}
          {numberFormatter(props.values.uscite, ".")} KG
        </Badge>
      </h5>
    </div>
  );
}
