import React from "react";
import BaseComponent from "../../components/BaseComponent";
import {Button, Container, Row} from "react-bootstrap";
import AdminUsersTable from "../../components/Tables/AdminUsersTable";
import AdminService from "../../services/AdminService";
import JumbotronDataEntry from "../../components/Jumbotron/DataEntry";
import ModalNewUser from "../../components/Modals/ModalNewUser";

class AdminPanel extends BaseComponent {

  /* -------- Init ------- */
  constructor(props) {
    super(props);
    this.adminService = this.props.states[0];
    this.state = {
      showNewUser: false
    }
  }

  /* -------- Life Cycle ------- */
  componentDidMount() {
    this.adminService.fetchUsers();
  }

  /* -------- Helpers ------- */
  handleSelect(selectedUsers) {
    this.adminService.selectedUsers = selectedUsers
  }

  toggleNewUserModal() {
    this.setState({showNewUser: !this.state.showNewUser})
  }

  /* -------- Render ------- */
  render() {

    return (
      <Container fluid className={"mt-4"}>
        <JumbotronDataEntry
          title="Pannello di Amministrazione"
          isSaved={!this.adminService.isChanged}
          lastUpdate={undefined}
          handleOnSave={_ => this.adminService.save()}
          handleOnCancel={_ => this.adminService.cancel()}
        />
        <hr/>

        <div className={"w-80 m-auto"}>
          <h3>Utenti</h3>
          <AdminUsersTable
            service={this.adminService}
          />
          <Row className={"justify-content-between ml-0 mr-0"}>
            <Button
              className={"btn-danger"}
              disabled={this.adminService.selectedUsers.length === 0}
              onClick={_ => this.adminService.deleteUsers()}
            >
              Cancella utente/i
            </Button>
            <Button
              onClick={_ => this.toggleNewUserModal()}>Aggiungi utente
            </Button>
          </Row>
        </div>
        <ModalNewUser
          show={this.state.showNewUser}
          onHide={() => this.toggleNewUserModal()}
          addUser={(user) => this.adminService.addUser(user)}
          />
      </Container>
    )
  }
}

export default AdminPanel.withStates([new AdminService()]);
