import React from "react";
import BaseComponent from "../../components/BaseComponent";
import {inventoryService} from "../../services/DemandInventoryService/DemandInventoryService";
import {Col, Container, Jumbotron, Row} from "react-bootstrap";
import DemandInventoryFilterColumns from "../../components/DemandInventoryFiltersColumns/DemandInventoryFilterColumns";
import {LoadingSpinner} from "../../components/Misc/LoadingSpinner";
import DetailSelector from "../InventoryOptimization/DetailSelector";
import LocalizedDayInputPicker from "../../components/LocalizedDayInputPicker";
import ButtonClearFilters from "../../components/Buttons/ButtonClearFilters";
import ButtonList from "../../components/ButtonList";
import InventoryRiordinoService from "../../services/DemandInventoryService/InventoryRiordinoService";
import InventoryOptimizationChart from "../../components/Charts/InventoryOptimizationChart";
import InventoryRiordinoTable from "../../components/Tables/InventoryRiordinoTable";
import {DEBUG} from "../../Utils/constants";
import {AmPmSelector} from "../../components/Misc/AmPmSelector";

class InventoryOptimization extends BaseComponent {
  inventoryOptimization;

  constructor(props) {
    super(props);
    this.inventoryOptimization = this.props.states[0];
    this.inventoryOptimization.serviceType = "inventoryOptimization";
    this.inventoryOptimization.state.dettaglio = "daily";
    this.riordinoService = new InventoryRiordinoService();
  }

  onChangeDate(day) {
    this.inventoryOptimization.onChangeDatiAl(day)
    this.inventoryOptimization.riordino.fetchData(day, this.inventoryOptimization.runType)
  }

  onChangeRunType(runType) {
    this.inventoryOptimization.onChangeRunType(runType)
    this.inventoryOptimization.riordino.fetchData(this.inventoryOptimization.datiAl, runType)
  }

  /* -------- Life Cycle ------- */

  componentDidMount() {
    DEBUG && console.log("InventoryOptimization component DidMount")
    if (this.inventoryOptimization.state.data.length > 0)
      return

    this.inventoryOptimization.riordino = this.riordinoService;
    this.inventoryOptimization.fetchLastAvailableData(_ => {
      this.inventoryOptimization.riordino.fetchData(this.inventoryOptimization.datiAl, this.inventoryOptimization.runType)
    });
  }

  render() {

    const data = this.inventoryOptimization.filteredData
    console.log("Render: Data Length:", data.length)

    const {
      isClearFilterDisabled,
      isLoading,
      datiAl,
      selectedGruppiMerceologici,
      gruppiMerceologici,
      articles,
      selectedArticles,
      selectedSuppliers,
      lastAvailableDate,
      riordino
    } = this.inventoryOptimization

    // DataTable
    let riordinoData = []

    if (articles.length > 0) {
      riordinoData = riordino.filterData(selectedGruppiMerceologici, this.inventoryOptimization.selectedAreeMerceologiche, selectedSuppliers, selectedArticles)
    }

    console.log("InventoryOptimization datiAl", datiAl)
    return (
      <Container fluid className={"mt-4"}>
        <Jumbotron className={"py-3"}>
          <h1>Inventory Optimization</h1>
          <span>Proposte di riordino per ottimizzazione del magazzino</span>
        </Jumbotron>

        <Container fluid className={isLoading ? "" : "d-none"}>
          <LoadingSpinner/>
        </Container>

        <Container fluid className={data.length > 0 ? "" : "d-none"}>
          {/* ---- FILTRO DETTAGLIO ---- */}
          <Row>
            {/* ---- FILTRO CATEGORIA ---- */}
            <Col lg={4} className={"text-center"}>
              <h5>Gruppo Merceologico</h5>
              <ButtonList
                className="mt-1"
                selected={selectedGruppiMerceologici}
                options={gruppiMerceologici}
                onChange={value => this.inventoryOptimization.onChangeGruppoMerceologico(value)}
              />
            </Col>

            {/* ---- FILTRO DETTAGLIO ---- */}
            <Col lg={3} className={"text-center"}>
              <h5>Periodo</h5>
              <DetailSelector onChange={e => this.inventoryOptimization.onChangeDettaglio(e.target.id)}/>
            </Col>

            {/* ---- FILTRO DATI_AL ---- */}
            <Col lg={3} className={"text-center"}>
              <h5>Dati Al</h5>
              <LocalizedDayInputPicker
                value={new Date(datiAl)}
                onDayChange={day => this.onChangeDate(day)}
                lastAvailableDate={lastAvailableDate ? new Date(lastAvailableDate) :  new Date()}
                disabledDays={this.inventoryOptimization.disabledDays}
              />
              <AmPmSelector
                onChange={e => this.onChangeRunType(e.target.id)}
                runType={this.inventoryOptimization.runType}
                isAMDisable={this.inventoryOptimization.isAMDisabled}
                isPMDisabled={this.inventoryOptimization.isPMDisabled}
              />
            </Col>

            {/* ---- CLEAR FILTERS ---- */}
            <Col lg={2} className={"text-center"} style={{margin: "auto"}}>
              <ButtonClearFilters
                onClick={() => this.inventoryOptimization.clearFilters()}
                disabled={isClearFilterDisabled}
              />
            </Col>
          </Row>

          {/* ---- FILTRI COLONNE ----*/}
          <Row className={"mt-4 bg-light"} style={{borderRadius: "25px"}}>
            <DemandInventoryFilterColumns service={this.inventoryOptimization}/>
          </Row>

          {/* ---- CHART ----*/}
          <Row className={"mt-5 p-2 bg-light justify-content-center"} style={{borderRadius: "25px"}}>
            {(data && data.length > 0) &&
            <InventoryOptimizationChart
              data={data}
              dettaglio={this.inventoryOptimization.dettaglio}
              datiAl={this.inventoryOptimization.datiAl}
              runType={this.inventoryOptimization.runType}
            />
            }
          </Row>

          <Row className={"mt-5"}>
            <h3>Proposta di Riordino</h3>
            <br/>
            <InventoryRiordinoTable
              data={riordinoData}
            />
          </Row>
        </Container>
      </Container>
    )
  }
}

export default InventoryOptimization.withStates([inventoryService]);
