import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";

import { Provider } from "unstated";
import { BrowserRouter } from "react-router-dom";

import { hotjar } from "react-hotjar";

import "bootstrap/dist/css/bootstrap.min.css";
import "@trendmicro/react-sidenav/dist/react-sidenav.css";
import "react-bootstrap-typeahead/css/Typeahead.css";

hotjar.initialize(2404918, 6);

ReactDOM.render(
  <Provider>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);
