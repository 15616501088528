import React from "react";

import Badge from "react-bootstrap/Badge";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faExclamationTriangle,
} from "@fortawesome/free-solid-svg-icons";

function SavedBadge(props) {
  if (props.isSaved) {
    return (
      <Badge pill variant="success" style={{marginTop: "auto"}}>
        <FontAwesomeIcon icon={faCheck} /> Salvato
      </Badge>
    );
  } else {
    return (
      <Badge pill variant="danger" style={{marginTop: "auto"}}>
        <FontAwesomeIcon icon={faExclamationTriangle} /> Non Salvato
      </Badge>
    );
  }
}

export default SavedBadge;
