/*
ButtonGroupDF.jsx
cir_deploy
Author: Jacopo Gasparetto
Date: 29/07/2020
Organization: Energy Way S.r.l
*/
import React from "react";
import { ButtonGroup, ToggleButton } from "react-bootstrap";

export default function ButtonGroupDF(props) {

  const radios = [
    { name: 'Cumulato', value: 'dettaglio' },
    { name: 'Gruppo Merceologico', value: 'des_gruppo_merceologico' },
    { name: 'Area Merceologica', value: 'area_merceologica' },
    { name: 'Fornitore', value: 'fornitore' },
    { name: 'Prodotto', value: 'articolo' },
  ];

  return (
    <>
      <ButtonGroup toggle>
        {radios.map((radio, idx) => (
          <ToggleButton
            key={idx}
            type="radio"
            variant="light"
            name="radio"
            value={radio.value}
            checked={props.radioValue === radio.value}
            onChange={e => props.onChange(e)}
          >
            {radio.name}
          </ToggleButton>
        ))}
      </ButtonGroup>
    </>
  );
}