/*
DemandInventoryFilterColumns.jsx
cir_deploy
Author: Jacopo Gasparetto
Date: 07/08/2020
Organization: Energy Way S.r.l
*/
import React from "react";
import {Col} from "react-bootstrap";
import SingleColumnTable from "../Tables/SingleColumnTable";


export default function DemandInventoryFilterColumns(props) {

  const {
    areeMerceologiche,
    selectedAreeMerceologiche,
    suppliers,
    selectedSuppliers,
    articles,
    selectedArticles
  } = props.service

  return (
    <>
      <Col className={"bg-white m-4"} style={{borderRadius: "25px"}}>
        <SingleColumnTable
          dataField={"area_merceologica"}
          text={"Area Merceologica"}
          onSelect={(row, isSelected) => props.service.onChangeRows(row, isSelected)}
          data={areeMerceologiche}
          selected={selectedAreeMerceologiche}
        />
      </Col>
      <Col className={"bg-white mt-4 mb-4 mr-4"} style={{borderRadius: "25px"}}>
        <SingleColumnTable
          dataField={"fornitore"}
          text={"Fornitore"}
          onSelect={(row, isSelected) => props.service.onChangeRows(row, isSelected)}
          data={suppliers}
          selected={selectedSuppliers}
        />
      </Col>
      <Col className={"bg-white mt-4 mb-4 mr-4 pb-2"} style={{borderRadius: "25px"}}>
        <SingleColumnTable
          dataField={"articolo"}
          text={"Prodotto"}
          onSelect={(row, isSelected) => props.service.onChangeRows(row, isSelected)}
          data={articles}
          selected={selectedArticles}
        />
      </Col>
    </>
  )
}