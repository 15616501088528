/*
ChartTypeSelectorButton.jsx
cir_deploy
Author: Jacopo Gasparetto
Date: 07/08/2020
Organization: Energy Way S.r.l
*//*
ButtonGroupDF.jsx
cir_deploy
Author: Jacopo Gasparetto
Date: 29/07/2020
Organization: Energy Way S.r.l
*/
import React from "react";
import { ButtonGroup, ToggleButton } from "react-bootstrap";

export default function ChartTypeSelectorButton(props) {

  const radios = [
    {name: "Peso", value: "peso"},
    {name: "Euro", value: "euro"}
  ];

  return (
    <>
      <ButtonGroup toggle>
        {radios.map((radio, idx) => (
          <ToggleButton
            key={idx}
            type="radio"
            variant="light"
            name="radio"
            disabled={props.disabledButtons.includes(radio.value)}
            value={radio.value}
            checked={props.radioValue === radio.value}
            onChange={e => props.onChange(e)}
          >
            {radio.name}
          </ToggleButton>
        ))}
      </ButtonGroup>
    </>
  );
}