import React, { Component } from "react";

/**
 * React Bootstrap components
 */
import Alert from "react-bootstrap/Alert";
import {Container, Row, Col, Form, Button} from "react-bootstrap";
import ModalRecoveryPassword from "../../components/Modals/ModalRecoveryPassword";

import style from "./login.module.css";

/**
 * Services
 */
import AuthenticationService from "./../../services/AuthenticationService";

class Login extends Component {
  state = {
    username: "",
    password: "",
    stay_on: false,
    error: false,
    message: "",
    showRecoveryPassword: false
  };

  componentDidMount = () => {
    document.body.style = "background: #f5f5f5;";

    console.log("CHECK ENV", process.env.NODE_ENV);
  };

  handleLogin = async (e) => {
    e.preventDefault();
    await AuthenticationService.login(this.state.username, this.state.password);

    if (AuthenticationService.isUserAuthenticated()) window.location = "/home";
    else {
      this.setState({
        error: true,
        message: "Username o Password non corretti!",
      });
    }
  };

  handleControlChange = (e) => {
    const fieldName = e.target.name;
    const fieldValue = e.target.value;

    this.setState({
      [fieldName]: fieldValue,
    });
  };

  toggleRecoveryPassword() {
    this.setState({showRecoveryPassword: !this.state.showRecoveryPassword})
    console.log(`Toggle recovery password modal: ${this.state.showRecoveryPassword}`)
  }

  render() {
    return (
      <React.Fragment>
        <ModalRecoveryPassword
          show={this.state.showRecoveryPassword}
          onHide={_ => this.toggleRecoveryPassword()}
        />
        <Container fluid style={{ height: "100vh", width: "100vw" }}>
          <Row
            style={{ marginTop: "-10vh" }}
            className="h-100 align-items-center"
          >
            <Col>
              <Form
                onSubmit={this.handleLogin.bind(this)}
                className={style.formSignin}
              >
                <div className="mb-4 text-center">
                  <img
                    className="mb-3"
                    width="250px"
                    src={process.env.PUBLIC_URL + "/logo-cirfood.svg"}
                    alt="LOGO CIRFOOD"
                  />
                  <h1 className="h3 mb-3 font-weight-normal">
                    Inventory Optimization
                  </h1>
                </div>
                <Form.Group>
                  <Form.Label>Username</Form.Label>
                  <Form.Control
                    type="text"
                    size="lg"
                    name="username"
                    placeholder="Inserisci username"
                    onChange={this.handleControlChange.bind(this)}
                    autoComplete="username"
                  />
                  {/* <Form.Text className="text-muted">
                    We'll never share your email with anyone else.
                  </Form.Text> */}
                </Form.Group>

                <Form.Group>
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    type="password"
                    size="lg"
                    name="password"
                    placeholder="Password"
                    onChange={this.handleControlChange.bind(this)}
                    autoComplete="current-password"
                  />
                </Form.Group>
                {/* <Form.Group controlId="formBasicCheckbox">
                  <Form.Check
                    onChange={this.handleControlChange.bind(this)}
                    type="checkbox"
                    label="Resta collegato"
                    name="stay_on"
                    value={this.state.stay_on}
                  />
                </Form.Group> */}
                <Button
                  className="btn-block"
                  variant="primary"
                  size="lg"
                  type="submit"
                  style={{ background: "#006939", borderColor: "#006939" }}
                >
                  Entra
                </Button>

                <Button
                  className="btn-block"
                  variant="link"
                  size="small"
                  onClick={_ => this.toggleRecoveryPassword()}
                >
                  Password dimenticata
                </Button>
                {this.state.error && (
                  <Alert className="mt-3" variant="danger">
                    {this.state.message}
                  </Alert>
                )}
              </Form>
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}

export default Login;
