import { Container } from "unstated";
import _ from "lodash";

export default class ModelService extends Container {
  state = {
    data: {},
    immutableData: {},
    lastUpdate: null,
  };

  /* --------- Getter & setter --------- */
  getData() {
    return this.state.data;
  }

  setData(data) {
    this.setState({
      data,
      lastUpdate: Date.now(),
    });
  }

  getImmutableData() {
    return this.state.immutableData;
  }

  setImmutableData(immutableData) {
    this.setState({
      immutableData,
      lastUpdate: Date.now(),
    });
  }

  getLastUpdate() {
    return this.state.lastUpdate;
  }

  getField(fieldName) {
    return _.get(this.getData(), fieldName, undefined);
  }

  setField(fieldName, fieldValue) {
    this.setData(_.set(this.getData(), fieldName, fieldValue));
  }

  /* --------- Utilities --------- */
  isDataChanged() {
    return !_.isEqual(this.getData(), this.getImmutableData());
  }

  getChangedData() {
    function changes(object, base) {
      return _.transform(object, function (result, value, key) {
        if (!_.isEqual(value, base[key])) {
          result[key] =
            _.isObject(value) && _.isObject(base[key])
              ? changes(value, base[key])
              : value;
        }
      });
    }
    return changes(this.getData(), this.getImmutableData());
  }

  /* --------- Main methods --------- */
  async retrieveData() {
    // OVERRIDE
  }

  async updateData() {
    // OVERRIDE
  }
}
