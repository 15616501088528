/*
ModalRecoveryPassword.jsx
cir_deploy
Author: Jacopo Gasparetto
Date: 13/10/2020
Organization: Ammagamma S.r.l
*/
import React, {useState} from "react";
import ApiService from "../../services/ApiService";
import {routes} from "../../Utils/constants";
import {Modal, Form, Button} from "react-bootstrap";

export default function ModalRecoveryPassword(props) {
  const errors = {
    notFound: "Email o nome utente non trovati",
    emailNotValid: "Email non valida. Inserire un indirizzo email valido",
  }

  const [email, setEmail] = useState("");
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [notFound, setNotFound] = useState(false);
  const [validated, setValidated] = useState(false);
  const [success, setSuccess] = useState(false);

  const validateEmail = (email) => {
    return /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email)
  }

  const handleSubmit = (event) => {
    event.preventDefault();

    const emailValid = validateEmail(email);

    setIsEmailValid(emailValid);
    setValidated(true);

    if (notFound) setNotFound(false);
    if (!emailValid)
      return

    const route = routes.recoverPassword;
    const data = {email: email}

    ApiService.postData(route, data)
      .then(_ => {
        setIsEmailValid(true);
        setSuccess(true)
      })
      .catch(err => {
        if (err.response.status === 401) {
          setNotFound(true);
        } else {
          console.log(err)
        }
      })
  }

  const handleOnHide = () => {
    setEmail("");
    setNotFound(false);
    setIsEmailValid(true);
    setSuccess(false);
    setValidated(false);
    props.onHide();
  }

  const emailErrorMessage = notFound ? errors.notFound : errors.emailNotValid

  return (
    <Modal
      show={props.show}
      size={"lg"}
      aria-labelledby={"modal-recovery-password"}
      centered
      backdrop="static"
      keyboard={false}
      onHide={handleOnHide}
    >
      <Form noValidate onSubmit={event => handleSubmit(event)}>
        <Modal.Header closeButton>
          <Modal.Title id="modal-recovery-password-title">
            Recupera password
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>Inserisci la mail associata all'utenza per ricevere una nuova password</p>
          <Form.Group controlId={"modal-password-recovery-email"}>
            <Form.Label>Email</Form.Label>
            <Form.Control
              type={"email"}
              placeholder={"Email"}
              required
              isInvalid={(notFound || !isEmailValid) && validated}
              isValid={success}
              onChange={event => setEmail(event.target.value)}
            />
            <Form.Control.Feedback type={"invalid"}>{emailErrorMessage}</Form.Control.Feedback>
            <Form.Control.Feedback type={"valid"}>Email inviata con successo.</Form.Control.Feedback>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button
            id={"modal-recovery-password-cancel"}
            className={"btn-secondary"}
            onClick={handleOnHide}>
              Chiudi
          </Button>
          <Button
            id={"modal-recovery-password-send"}
            type={"submit"}
            disabled={success}>
              Invia
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>

  )
}