import React  from "react";

import Form from "react-bootstrap/Form";
import {Col} from "react-bootstrap";

export default function SelectField(props) {
  const defaultValue = "...";
  const value = props.service.getField(props.fieldPath);

  const handleOnChange = (selected) => {
    props.service.setField(props.fieldPath, selected === defaultValue ? null : selected)
  }

  const options = props.options.map(el => {
    if (el === defaultValue)
      return <option value="" key={el}>{el}</option>
    return <option value={el} key={el}>{el}</option>
  })

  return (
    <Col>
      <Form.Group controlId={props.fieldName.replace("_", "-")}>
        <Form.Label>u.m.</Form.Label>
        <Form.Control
          className="mr-sm-2"
          size={"sm"}
          custom
          as={"select"}
          value={value ? value : undefined}
          onChange={event => handleOnChange(event.target.value)}
          disabled={props.disabled}
          required={props.required ? props.required : undefined}
        >
          {options}
        </Form.Control>
        <Form.Control.Feedback type="invalid">
          Inserire l'unità di misura
        </Form.Control.Feedback>
      </Form.Group>
    </Col>
  )
}
