import React, { Component } from "react";
import moment from "moment";

import "moment/locale/it";

import DayPickerInput from "react-day-picker/DayPickerInput";
import "react-day-picker/lib/style.css";
import "./range.css";

import {
  MomentLocaleUtils,
  formatDate,
  parseDate,
} from "react-day-picker/moment";

import { subDays } from "date-fns";

const MONTHS = [
  "Gennaio",
  "Febbraio",
  "Marzo",
  "Aprile",
  "Maggio",
  "Giugno",
  "Luglio",
  "Agosto",
  "Settembre",
  "Ottobre",
  "Novembre",
  "Dicembre",
];

const WEEKDAYS_LONG = [
  "Domenica",
  "Lunedì",
  "Martedì",
  "Mercoledì",
  "Giovedì",
  "Venerdì",
  "Sabato",
];
const WEEKDAYS_SHORT = ["Do", "Lu", "Ma", "Me", "Gi", "Ve", "Sa"];

export default class RangeDayPicker extends Component {
  constructor(props) {
    super(props);

    this.handleToChange = this.handleToChange.bind(this);
  }

  showFromMonth() {
    const { from, to } = this.props;
    if (!from) {
      return;
    }
    if (moment(to).diff(moment(from), "months") < 2) {
      this.to.getDayPicker().showMonth(from);
    }
  }

  async handleToChange(to) {
    await this.props.onChangeTo(to);
    this.showFromMonth();
  }

  render() {
    const { from, to } = this.props;
    const modifiers = { start: from, end: to };
    return (
      <div className="InputFromTo">
        <DayPickerInput
          value={from}
          placeholder={`${formatDate(this.props.defaultFrom, "LL", "it")}`}
          format="LL"
          formatDate={formatDate}
          parseDate={parseDate}
          dayPickerProps={{
            locale: "it",
            localeUtils: MomentLocaleUtils,
            months: MONTHS,
            weekdaysLong: WEEKDAYS_LONG,
            weekdaysShort: WEEKDAYS_SHORT,
            selectedDays: [from, { from, to }],
            disabledDays: {
              before: subDays(new Date(this.props.defaultTo), 730),
              after: new Date(this.props.defaultTo),
            },
            toMonth: to,
            modifiers,
            numberOfMonths: 2,
            onDayClick: () => this.to.getInput().focus(),
          }}
          onDayChange={this.props.onChangeFrom}
        />{" "}
        —{" "}
        <span className="InputFromTo-to">
          <DayPickerInput
            ref={(el) => (this.to = el)}
            value={to}
            placeholder={`${formatDate(this.props.defaultTo, "LL", "it")}`}
            format="LL"
            formatDate={formatDate}
            parseDate={parseDate}
            dayPickerProps={{
              locale: "it",
              localeUtils: MomentLocaleUtils,
              months: MONTHS,
              weekdaysLong: WEEKDAYS_LONG,
              weekdaysShort: WEEKDAYS_SHORT,
              selectedDays: [from, { from, to }],
                disabledDays: {
                  before: subDays(new Date(this.props.defaultTo), 730),
                  after: new Date(this.props.defaultTo),
                },
              modifiers,
              month: from,
              fromMonth: from,
              numberOfMonths: 2,
            }}
            onDayChange={this.handleToChange}
          />
        </span>
      </div>
    );
  }
}
