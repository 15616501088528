/*
ModalNewUser.jsx
cir_deploy
Author: Jacopo Gasparetto
Date: 22/09/2020
Organization: Energy Way S.r.l
*/
import React, {useState} from "react";
import {Modal, Button, Form} from "react-bootstrap";

const userGroups = {
  "Audit User": 4,
  "Operative User": 3,
  "Super User": 2,
  "Amministratore": 1,
}

export default function ModalNewUser(props) {
  const errors = {
    userNotValid: "Nome utente non valido. \n" +
      "Sono ammessi solo numeri, lettere e i seguenti simboli: ., _, -. ",
    emailNotValid: "Email non valida. Inserire un indirizzo email valido",
    alreadyExists: "Username o email già esistenti."
  }

  const [email, setEmail] = useState("");
  const [group, setGroup] = useState(4);
  const [username, setUsername] = useState("");
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [alreadyExists, setAlreadyExists] = useState(false);
  const [isUsernameValid, setIsUsernameValid] = useState(false);
  const [validated, setValidated] = useState(false);
  const [saved, setSaved] = useState(false);
  const {addUser, ...rest} = props;

  const options = Object.keys(userGroups).map(key => {
    return <option value={userGroups[key]} key={key}>{key}</option>
  })

  const validateEmail = (email) => {
    return /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email)
  }

  const validateUsername = (username) => {
    return /^[a-zA-Z0-9._-]{2,}$/.test(username)
  }


  const handleSubmit = (event) => {
    event.preventDefault();

    const emailValid = validateEmail(email);
    const userValid = validateUsername(username);

    setIsEmailValid(emailValid);
    setIsUsernameValid(userValid);
    setValidated(true);
    if (alreadyExists) setAlreadyExists(false);

    if (!emailValid || !userValid)
      return

    addUser({email: email, user_group: group, username: username})
      .then(_ => {
        setIsEmailValid(true);
        setIsUsernameValid(true);
        setSaved(true);
      })
      .catch(err => {
        if (err.response.status === 409) {
          setAlreadyExists(true);
        } else {
          console.log(err)
        }
      })
  }

  const handleOnHide = () => {
    setEmail("");
    setUsername("");
    setAlreadyExists(false);
    setIsEmailValid(true);
    setIsUsernameValid(true);
    setSaved(false)
    setValidated(false);
    props.onHide()
  }

  const emailErrorMessage = alreadyExists ? errors.alreadyExists : errors.emailNotValid;
  const userErrorMessage = alreadyExists ? errors.alreadyExists : errors.userNotValid;

  return (
    <Modal
      {...rest}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      keyboard={false}
      onHide={handleOnHide}
    >
      <Form noValidate onSubmit={(event) => handleSubmit(event)}>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Nuovo Utente
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId={"new-user-email"}>
            <Form.Label>Email</Form.Label>
            <Form.Control
              type={"email"}
              placeholder={"Email"}
              required
              isInvalid={(alreadyExists || !isEmailValid) && validated}
              isValid={saved}
              onChange={event => setEmail(event.target.value)}
            />
            <Form.Control.Feedback type="invalid">{emailErrorMessage}</Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId={"new-user-name"}>
            <Form.Label>Username</Form.Label>
            <Form.Control
              type={"text"}
              placeholder={"Username"}
              required
              isInvalid={(alreadyExists || !isUsernameValid) && validated}
              isValid={saved}
              onChange={event => setUsername(event.target.value)}
            />
            <Form.Control.Feedback type="invalid">{userErrorMessage}</Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId={"new-user-group"}>
            <Form.Label>Gruppo</Form.Label>
            <Form.Control
              as={"select"}
              onChange={event => setGroup(parseInt(event.target.value))}>
              required
              {options}
            </Form.Control>
          </Form.Group>

        </Modal.Body>
        <Modal.Footer>
          <Button
            className={"btn-secondary"}
            onClick={handleOnHide}>Chiudi</Button>
          <Button type={"submit"} disabled={saved}>Crea Utente</Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}