/**
 * For AMCHART references and examples:
 * https://www.amcharts.com/demos/location-sensitive-map/
 *
 */

import React, { Component } from "react";

/** amcharts DEPs */
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import _ from "lodash";
import abbreviate from "number-abbreviate";

import * as Utils from "../../Utils";

export default class ChartKpiBar extends Component {
  /* -------- Life Cycle ------- */
  componentDidMount() {
    // Create chart instance
    let chart = am4core.create(this.props.id, am4charts.XYChart);
    chart.language.locale["_decimalSeparator"] = ",";
    chart.language.locale["_thousandSeparator"] = ".";

    chart.data = this.props.data;
    this.setupAxis(chart);

    this.chart = chart;
  }

  componentDidUpdate(prevProps) {
    if (_.isEqual(prevProps, this.props)) return;

    this.chart.data = this.props.data;
    this.setupAxis(this.chart);
  }

  componentWillUnmount() {
    if (this.chart) {
      this.chart.dispose();
    }
  }

  setupAxis(chart) {
    chart.yAxes.clear();
    chart.xAxes.clear();
    chart.series.clear();

    let categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "area";
    categoryAxis.fontSize = 10;
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.inversed = true;
    categoryAxis.renderer.minGridDistance = 20;

    this.yAxes = categoryAxis;

    const maxUscite = this.getMaxData(this.props.data, "uscite");
    const maxEntrate = this.getMaxData(this.props.data, "entrate");

    let result = maxUscite > maxEntrate ? maxUscite : maxEntrate;

    let valueAxis = chart.xAxes.push(new am4charts.ValueAxis());
    valueAxis.min = -result;
    valueAxis.max = result;
    valueAxis.fontSize = 9;
    valueAxis.extraMin = 0.2;
    valueAxis.extraMax = 0.2;
    valueAxis.renderer.minGridDistance = 40;
    valueAxis.renderer.ticks.template.length = 6;
    valueAxis.renderer.ticks.template.disabled = false;
    valueAxis.renderer.ticks.template.strokeOpacity = 0.4;
    valueAxis.renderer.labels.template.adapter.add("text", (text) => {
      if (!text) return undefined;

      text = parseInt(text.split(",").join(""));
      return abbreviate(text);
    });

    // Create series
    let entrate = chart.series.push(new am4charts.ColumnSeries());
    entrate.dataFields.valueX = "entrate";
    entrate.dataFields.categoryY = "area";
    entrate.clustered = false;
    entrate.fill = am4core.color("#28a745");
    entrate.stroke = am4core.color("white");

    let entrateLabel = entrate.bullets.push(new am4charts.LabelBullet());
    entrateLabel.label.text = "{valueX}";
    entrateLabel.label.fontSize = 7;
    entrateLabel.label.hideOversized = false;
    entrateLabel.label.truncate = false;
    entrateLabel.label.horizontalCenter = "left";
    entrateLabel.label.dx = 5;

    let uscite = chart.series.push(new am4charts.ColumnSeries());
    uscite.dataFields.valueX = "uscite";
    uscite.dataFields.categoryY = "area";
    uscite.clustered = false;
    uscite.fill = am4core.color("#007bff");
    uscite.stroke = am4core.color("white");

    let usciteLabel = uscite.bullets.push(new am4charts.LabelBullet());
    usciteLabel.label.text = "{valueX}";
    usciteLabel.label.fontSize = 7;
    usciteLabel.label.hideOversized = false;
    usciteLabel.label.truncate = false;
    usciteLabel.label.horizontalCenter = "right";
    usciteLabel.label.dx = -5;

    chart.cursor = new am4charts.XYCursor();
    categoryAxis.tooltip.label.adapter.add("text", (text, target) => {
      if (target.dataItem) {
        return (
          "[bold]" +
          target.dataItem.dataContext.des +
          "[/]" +
          "\nEntrate: " +
          Utils.numberFormatter(target.dataItem.dataContext.entrate) +
          "\nUscite: " +
          Utils.numberFormatter(-target.dataItem.dataContext.uscite)
        );
      }
      return text;
    });

    categoryAxis.tooltip.background.fill = "#ffffff";
    categoryAxis.tooltip.label.fill = "#000000";
    categoryAxis.tooltip.background.cornerRadius = 2;
    categoryAxis.tooltip.background.strokeOpacity = 0.2;

    valueAxis.tooltip.label.adapter.add("text", (text, target) => "");
  }

  getMaxData(data, filter) {
    return data.reduce((acc, actual) => {
      let actualField = actual[filter];
      actualField = actualField < 0 ? -actualField : actualField;

      if (actualField > acc) return actualField;
      return acc;
    }, 0);
  }

  /* -------- Render ------- */
  render() {
    return (
      <div id={this.props.id} style={{width: "100%", height: "350px"}}/>
    );
  }
}
