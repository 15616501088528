/**
 * For AMCHART references and examples:
 * https://www.amcharts.com/demos/location-sensitive-map/
 *
 */

import React, { Component } from "react";

/** amcharts DEPs */
import * as am4core from "@amcharts/amcharts4/core";
import {
  MapPolygonSeries,
  MapChart,
  projections,
  HeatLegend,
  ZoomControl,
} from "@amcharts/amcharts4/maps";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

import * as Utils from "../../Utils";

import _ from "lodash";

export default class MapItalyKPI extends Component {
  /* -------- Life Cycle ------- */
  componentDidMount() {
    am4core.useTheme(am4themes_animated);

    let chart = am4core.create("map-ref", MapChart);
    chart.language.locale["_decimalSeparator"] = ",";
    chart.language.locale["_thousandSeparator"] = ".";

    chart.geodataSource.url = process.env.PUBLIC_URL + "itx.geojson";
    chart.projection = new projections.Miller();

    this.setUpPolygonSeries(chart);
    // this.setUpHeatupLegend(chart, this.polygonSeries);

    this.chart = chart;
  }

  componentDidUpdate(prevProps) {
    if (_.isEqual(prevProps, this.props)) return;

    this.setUpPolygonSeries(this.chart);
    //this.setUpHeatupLegend(this.chart, this.polygonSeries);
  }

  componentWillUnmount() {
    if (this.chart) {
      this.chart.dispose();
    }
  }

  setUpPolygonSeries(chart) {
    let polygonSeries = chart.series.push(new MapPolygonSeries());

    polygonSeries.heatRules.push({
      property: "fill",
      target: polygonSeries.mapPolygons.template,
      min: chart.colors.getIndex(1).brighten(0),
      max: chart.colors.getIndex(1).brighten(-0.5),
    });

    polygonSeries.useGeodata = true;
    polygonSeries.calculateVisualCenter = true;

    // Tooltip on hover
    let polygonTemplate = polygonSeries.mapPolygons.template;
    polygonTemplate.tooltipText = "{id}: {value}";
    polygonTemplate.nonScalingStroke = true;
    polygonTemplate.strokeWidth = 0.5;

    // // // Hover color
    let hs = polygonTemplate.states.create("hover");
    hs.properties.fill = am4core.color("#076939");

    polygonSeries.data = this.props.data;

    chart.zoomControl = new ZoomControl();

    let button = chart.chartContainer.createChild(am4core.Button);
    button.padding(5, 5, 5, 5);
    button.align = "left";
    button.marginRight = 15;
    button.events.on("hit", function () {
      chart.goHome();
    });
    button.icon = new am4core.Sprite();
    button.icon.path =
      "M16,8 L14,8 L14,16 L10,16 L10,10 L6,10 L6,16 L2,16 L2,8 L0,8 L8,0 L16,8 Z M16,8";

    this.polygonSeries = polygonSeries;
  }

  setUpHeatupLegend(chart, polygonSeries) {
    if (this.heatLegend) {
      this.heatLegend.removeChildren();
    }
    const maxValue = this.props.data.reduce(
      (acc, actual) => (actual.value > acc ? actual.value : acc),
      0
    );

    const minValue = this.props.data.reduce(
      (acc, actual) => (actual.value < acc ? actual.value : acc),
      0
    );

    let heatLegend = chart.createChild(HeatLegend);
    heatLegend.series = polygonSeries;
    heatLegend.align = "left";
    heatLegend.valign = "bottom";
    heatLegend.width = am4core.percent(30);
    heatLegend.marginLeft = am4core.percent(4);
    heatLegend.marginBottom = am4core.percent(5);
    heatLegend.minValue = minValue;
    heatLegend.maxValue = maxValue;
    heatLegend.background.fill = am4core.color("#000");
    heatLegend.background.fillOpacity = 0.05;
    heatLegend.padding(5, 5, 5, 5);

    let minRange = heatLegend.valueAxis.axisRanges.create();
    minRange.value = heatLegend.minValue;
    minRange.label.text = minValue;
    minRange.label.horizontalCenter = "left";

    let maxRange = heatLegend.valueAxis.axisRanges.create();
    maxRange.value = heatLegend.maxValue;
    maxRange.label.text = Utils.numberFormatter(maxValue);
    maxRange.label.horizontalCenter = "right";

    // Blank out internal heat legend value axis labels
    heatLegend.valueAxis.renderer.labels.template.adapter.add("text", function (
      labelText
    ) {
      return "";
    });

    this.heatLegend = heatLegend;
  }

  /* -------- Render ------- */
  render() {
    return <div id="map-ref" style={{ width: "100%", height: "500px" }}></div>;
  }
}
