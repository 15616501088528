import React from "react";
import {Form, Col} from "react-bootstrap";

export const NumberField = (props) => {
  const value = props.service.getField(props.fieldPath) || "";

  const onChange = (value) => {
    props.service.setField(props.fieldPath, value === "" ? null : parseFloat(value));
  }

  return (
    <Col>
      <Form.Group>
        <Form.Label>{props.label}</Form.Label>
        <Form.Control
          size={"sm"}
          type="number"
          value={value}
          onChange={event => onChange(event.target.value)}
          disabled={props.disabled}
        />
      </Form.Group>
    </Col>
  );
}

export default NumberField;