/*
ExportCSVButton.jsx
cir_deploy
Author: Jacopo Gasparetto
Date: 05/08/2020
Organization: Energy Way S.r.l
*/
import React from "react";

const ButtonExportCSV = (props) => {
  const handleClick = () => {
    props.onExport();
  };
  return (
    <div>
      <button
        disabled={props.disabled}
        className={"btn btn-success " + props.className}
        onClick={ handleClick }>
        Esporta CSV
      </button>
    </div>
  );
};

export default ButtonExportCSV;