/*
InventoryOptimizationChart.jsx
cir_deploy
Author: Jacopo Gasparetto
Date: 07/08/2020
Organization: Energy Way S.r.l
*/
import React, {Component} from 'react';
import * as am4core from "@amcharts/amcharts4/core";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import "./demandForecasting.css"
import {makeLineChart, createSeries} from "./LineChartSkeleton";
import {lineChartColors} from "../../Utils/constants";
import {ValueAxis} from "@amcharts/amcharts4/charts";
import ChartTypeSelectorButton from "../../containers/InventoryOptimization/ChartTypeSelectorButton";
import {getUniqueProducts} from "../../Utils/commons";
import AuthenticationService from "../../services/AuthenticationService";

am4core.useTheme(am4themes_animated);

class InventoryOptimizationChart extends Component {

  chartID = "chart-inventory-id";
  yKeys = [
    "val_giacenza_kg",
    "val_svuotamento_kg",
    "val_safety_kg",
    "val_giacenza_euro",
    "val_svuotamento_euro",
    "val_safety_euro",
  ]

  constructor(props) {
    super(props);

    // Control if user can see pricing column
    const {user_group} = AuthenticationService.getData();
    this.hidePricing = user_group > 2;

    this.state = {
      index: 0,
      selectedChartType: "peso"
    }
  }

  sumBy(data, xKey, yKeys, groupKey) {
    return data.reduce((acc, el) => {
      const groupLabel = el[groupKey]
      const x = el[xKey]

      const yValues = Object.keys(el)
        .filter(key => yKeys.includes(key))
        .reduce((acc, key) => {
          acc[key] = el[key]
          return acc
        }, {})


      if (!(groupLabel in acc)) {
        acc[groupLabel] = {
          [x]: yValues
        }

      } else if (!(x in acc[groupLabel])) {
        acc[groupLabel][x] = yValues

      } else {

        for (let key of Object.keys(acc[groupLabel][x])) {
          acc[groupLabel][x][key] += yValues[key]
        }
      }

      return acc

    }, {})

  }

  showAxis(axis) {
    axis.series.each(s => {
      s.show();
      s.hiddenInLegend = false;
      s.tooltip.disabled = false;
    })
    axis.disabled = false
    axis.show()
  }

  hideAxis(axis) {
    axis.series.each(s => {
      s.hide();
      s.hidden = true
      s.hiddenInLegend = true;
      s.tooltip.disabled = true;
    })
    axis.hide()
    axis.disabled = true
  }

  drawData(data) {

    data = Object.keys(data).reduce((acc, key) => {
      acc.push({
        x: new Date(key),
        ...data[key]
      })
      return acc
    }, [])

    console.log("Draw data", data.length)
    this.chart.data = data
  }

  draw() {
    let data = getUniqueProducts(this.props.data)
    data = this.sumBy(data, "dat_magazzino", this.yKeys, "dettaglio");
    this.drawData(data[this.props.dettaglio])
  }

  handleSelect = (selectedIndex, e) => {
    this.setState({index: selectedIndex});
  };

  handleChangeChartType = (type) => {
    switch (type) {
      case "peso":
        this.hideAxis(this.euroAxis)
        this.showAxis(this.massAxis)
        break;
      case "euro":
        this.hideAxis(this.massAxis)
        this.showAxis(this.euroAxis)
        break;
      default:
        break;
    }
    this.setState({
        selectedChartType: type
      }
    )
  }

  /* -------- Life Cycle ------- */

  componentDidMount() {
    console.log("chart did mount")
    this.chart = makeLineChart(this.chartID)
    this.chart.maskBullets = false

    this.massAxis = this.chart.yAxes.push(new ValueAxis());
    this.massAxis.title.text = "Quantità (KG)"

    this.euroAxis = this.chart.yAxes.push(new ValueAxis());
    this.euroAxis.title.text = "Valore (€)"

    createSeries(this.chart, this.massAxis, "val_giacenza_kg", "Giacenza", lineChartColors[0])
    createSeries(this.chart, this.massAxis, "val_svuotamento_kg", "Svuotamento", lineChartColors[1])
    createSeries(this.chart, this.massAxis, "val_safety_kg", "Safety", lineChartColors[2])

    createSeries(this.chart, this.euroAxis, "val_giacenza_euro", "Giacenza", lineChartColors[0])
    createSeries(this.chart, this.euroAxis, "val_svuotamento_euro", "Svuotamento", lineChartColors[1])
    createSeries(this.chart, this.euroAxis, "val_safety_euro", "Safety", lineChartColors[2])

    this.hideAxis(this.euroAxis)

    if (this.props.data.length > 0) {
      this.draw()
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.data.length !== this.props.data.length ||
      prevProps.datiAl !== this.props.datiAl ||
      prevProps.runType !== this.props.runType
    ) {
      this.draw()
    }
  }

  componentWillUnmount() {
    console.log("component will unmount")
    if (this.chart) {
      this.chart.dispose();
    }
  }


  render() {
    return (
      <div className={"text-center w-100"}>
        {!this.hidePricing &&
          <ChartTypeSelectorButton
            disabledButtons={[]}
            radioValue={this.state.selectedChartType}
            onChange={e => this.handleChangeChartType(e.currentTarget.value)}
          />
        }
        <div id={this.chartID} style={{width: "100%", height: "600px", marginTop: "15"}}/>
      </div>
    )
  }
}

export default InventoryOptimizationChart;