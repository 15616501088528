import React from "react";
import ArticoliTable from "../../components/Tables/ArticoliTable";
import BaseComponent from "../../components/BaseComponent";
import JumbotronDataEntry from "../../components/Jumbotron/DataEntry";
import DataEntryService from "../../services/DataEntryService";
import Container from "react-bootstrap/Container";

class DataEntryArticoli extends BaseComponent {

  constructor(props) {
    super(props);
    this.dataEntryService = this.props.states[0];
  }

  async componentDidMount() {
    try {
      await this.dataEntryService.getArticles();
    } catch (err) {
      console.log("err:", err);
    }
  }

  render() {
    return (
      <>
        <Container fluid className="mt-3">
          <JumbotronDataEntry
            title="Anagrafica Articoli"
            isSaved={this.dataEntryService.isArticlesUpdated()}
            lastUpdate={this.dataEntryService.state.lkp_articoli.lastUpdate}
            handleOnSave={this.dataEntryService.updateModifiedArticles.bind(
              this
            )}
          />

          <ArticoliTable service={this.dataEntryService}/>
        </Container>
      </>
    );
  }
}

export default DataEntryArticoli.withStates([DataEntryService]);
