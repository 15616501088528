import React, { Component } from "react";

import { Alert, Modal, FormControl, Button } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExclamationTriangle,
  faUserPlus,
} from "@fortawesome/free-solid-svg-icons";

const errorUnderSupplierAlreadyExist =
  "Il sotto-fornitore indicato <strong>è già esistente</strong>!";

const errorUnderSupplierNotValid =
  "Il sotto-fornitore indicato <strong>non è valido</strong>!";

export default class ModalNewUnderSupplier extends Component {
  state = {
    underSupplierName: "",
    message: "",
  };

  handleOnChangeName(e) {
    const value = e.target.value;
    this.setState({ underSupplierName: value });
  }

  handleOnSave() {
    let { underSupplierName } = this.state;

    underSupplierName = underSupplierName.trim();

    if (!underSupplierName || underSupplierName.length === 0) {
      this.setState({
        message: errorUnderSupplierNotValid,
      });
      return;
    } else if (this.props.underSupplierOptions.includes(underSupplierName)) {
      this.setState({
        message: errorUnderSupplierAlreadyExist,
      });
      return;
    } else {
      this.setState({
        message: "",
      });
    }

    this.props.handleOnSave(this.props.selectedSupplier, underSupplierName);

    this.props.handleAfterSave(underSupplierName);

    this.props.toggle();
  }

  render() {
    return (
      <Modal show={this.props.show} onHide={this.props.toggle}>
        <Modal.Header>
          <Modal.Title>Aggiungi sotto-fornitore</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h6>Sotto-fornitore:</h6>
          <FormControl
            placeholder={"Inserisci nuovo sotto-fornitore"}
            value={this.state.underSupplierName}
            onChange={this.handleOnChangeName.bind(this)}
          />

          <br />

          {this.state.message && (
            <Alert variant="warning">
              <FontAwesomeIcon icon={faExclamationTriangle} />{" "}
              <span
                dangerouslySetInnerHTML={{ __html: this.state.message }}
              ></span>
            </Alert>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={this.props.toggle}>
            Annulla
          </Button>
          <Button variant="primary" onClick={this.handleOnSave.bind(this)}>
            <FontAwesomeIcon icon={faUserPlus} /> Aggiungi
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
