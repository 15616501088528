/*
FormBancalizzazione.jsx
cir_deploy
Author: Jacopo Gasparetto
Date: 18/09/2020
Organization: Energy Way S.r.l
*/
import React from "react";
import {Form, Row, Col} from "react-bootstrap";
import "./FormBancalizzazione.css"
import RenderField from "../Fields";

// noinspection JSCheckFunctionSignatures
export class FromBancalizzazione extends React.Component {

  um_options = ["...", "BANCALI", "IMBALLI", "STRATI"]

  constructor(props) {
    super(props);
    this.state = {
      validated: false
    }
    this.formRef = React.createRef();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if ((this.props.selectedSupplier === undefined || this.props.selectedSupplier === "") ||
      (this.props.selectedUnderSupplier === undefined || this.props.selectedUnderSupplier === "")) {
      console.log("Reset form");
      this.formRef.reset()
    }
  }


  getFieldPath = (fieldName) => {
    if (this.props.selectedUnderSupplier !== null)
      return [this.props.selectedSupplier, this.props.selectedUnderSupplier, fieldName];
  };

  getField = (fieldName) => {
    return this.props.service.getField(this.getFieldPath(fieldName));
  }

  getFieldProps = (fieldName, label, type, options) => {
    return {
      fieldName: fieldName,
      fieldPath: this.getFieldPath(fieldName),
      label: label,
      type: type,
      service: this.props.service,
      options: options
    }
  }

  isEntryInvalid(fieldName) {
    const minValue = this.getField(`val_${fieldName}_min`);
    const maxValue = this.getField(`val_${fieldName}_max`);
    const umValue = this.getField(`um_${fieldName}`);

    return (typeof (minValue === "number") || typeof (maxValue === "number")) && umValue === null;
  }

  submit() {
    const event = new Event('submit', {cancelable: true})
    this.formRef.dispatchEvent(event);
    console.log("submit!")
  }

  handleSubmit(event) {
    console.log("submitted!")
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;
    console.log("Check Validity", form.checkValidity())
    this.setState({validated: true})
  }

  render() {

    const formFields = {
      val_peso: this.getFieldProps("val_peso", null, "range"),
      val_ordini_al_mese_max: this.getFieldProps("val_ordini_al_mese_max", "Max ordini", "number"),
      val_lead_time: this.getFieldProps("val_lead_time", "Giorni di consegna", "number"),
      flg_bancali_completi: this.getFieldProps("flg_bancali_completi", "Bancali completi", "boolean"),
      flg_2_referenze_per_bancale: this.getFieldProps("flg_2_referenze_per_bancale", "Max 2 referenze per bancale", "boolean"),

      val_ordine: this.getFieldProps("val_ordine", null, "range"),
      um_ordine: this.getFieldProps("um_ordine", "u.m.", "select", this.um_options),

      flg_fornitore_estero: this.getFieldProps("flg_fornitore_estero", "Fornitore Estero", "boolean"),
      val_estero_peso: this.getFieldProps("val_estero_peso", "peso", "range"),
      val_estero_ordine: this.getFieldProps("val_estero_ordine", null, "range"),
      um_estero_ordine: this.getFieldProps("um_estero_ordine", "u.m.", "select", this.um_options),

      flg_arrotonda_difetto: this.getFieldProps("flg_arrotonda_difetto", "Arrotonda difetto", "boolean"),
      val_arrotonda_difetto: this.getFieldProps("val_arrotonda_difetto", null, "range"),
      um_arrotonda_difetto: this.getFieldProps("um_arrotonda_difetto", "u.m.", "select", this.um_options),

      flg_fornitore_regola_alternativa: this.getFieldProps("flg_fornitore_regola_alternativa", "Regola alternativa", "boolean"),
      val_regola_alternativa_peso: this.getFieldProps("val_regola_alternativa_peso", "peso", "range"),
      val_regola_alternativa: this.getFieldProps("val_regola_alternativa", null, "range"),
      um_regola_alternativa: this.getFieldProps("um_regola_alternativa", "u.m.", "select", this.um_options),

      flg_regola_alternativa_in_arrotonda_difetto: this.getFieldProps("flg_regola_alternativa_in_arrotonda_difetto", "Regola alternativa in arrotonda difetto", "boolean"),
      val_regola_alternativa_in_arrotonda_difetto: this.getFieldProps("val_regola_alternativa_in_arrotonda_difetto", null, "range"),
      um_regola_alternativa_in_arrotonda_difetto: this.getFieldProps("um_regola_alternativa_in_arrotonda_difetto", "u.m.", "select", this.um_options),

      flg_lunedi: this.getFieldProps("flg_lunedi", "lunedì", "boolean"),
      flg_martedi: this.getFieldProps("flg_martedi", "martedì", "boolean"),
      flg_mercoledi: this.getFieldProps("flg_mercoledi", "mercoledì", "boolean"),
      flg_giovedi: this.getFieldProps("flg_giovedi", "giovedì", "boolean"),
      flg_venerdi: this.getFieldProps("flg_venerdi", "venerdì", "boolean"),
    };

    const {formDisabled} = this.props;
    const fornitoreEsteroDisabled = !this.getField("flg_fornitore_estero") || formDisabled;
    const arrotondaDifettoDisabled = !this.getField("flg_arrotonda_difetto") || formDisabled;
    const fornitoreRegolaAlternativaDisabled = !this.getField("flg_fornitore_regola_alternativa") || formDisabled;
    const regolaAlternativaInArrotondaDifettoDisabled = !this.getField("flg_regola_alternativa_in_arrotonda_difetto") || formDisabled;

    const isUmRangeOrdineRequired = (this.getField("val_ordine_min") ||
      this.getField("val_ordine_max"))

    const isFornitoreEsteroRequired =
      !(this.getField("val_estero_peso_min") ||
        this.getField("val_estero_peso_max") ||
        this.getField("val_estero_ordine_min") ||
        this.getField("val_estero_ordine_max"));

    const isArrotondaDifettoRequired =
      !(this.getField("val_arrotonda_difetto_min") || this.getField("val_arrotonda_difetto_max"));

    const isRegolaAlternativaRequired =
      !(this.getField("val_regola_alternativa_peso_min") ||
        this.getField("val_regola_alternativa_peso_max") ||
        this.getField("val_regola_alternativa_min") ||
        this.getField("val_regola_alternativa_max"));

    const isRegolaAlternativaInArrotondaDifettoRequired =
      !(this.getField("val_regola_alternativa_in_arrotonda_difetto_min") ||
        this.getField("val_regola_alternativa_in_arrotonda_difetto_max"));

    return (
      <Form
        ref={e => this.formRef = e}
        onSubmit={this.handleSubmit.bind(this)}
        noValidate validated={this.state.validated}
        onChange={_ => this.setState({validated: false})}
      >
        <Row className={"mt-4"}>
          {/* ---- RANGE PESO --- */}
          <Col lg={4}>
            <Row><h4>Range Peso</h4></Row>
            <Row><RenderField {...formFields["val_peso"]} disabled={formDisabled}/></Row>
          </Col>

          <Col className={"ml-4"}>
            <Row>
              {/* ---- ORDINI AL MESE --- */}
              <Col>
                <Row><h4>Ordini al Mese</h4></Row>
                <Row><RenderField {...formFields["val_ordini_al_mese_max"]} disabled={formDisabled}/></Row>
              </Col>

              {/* ---- TEMPO DI CONSEGNA ---- */}
              <Col>
                <Row><h4>Tempo di Consegna</h4></Row>
                <Row><RenderField {...formFields["val_lead_time"]} disabled={formDisabled}/></Row>
              </Col>
            </Row>
          </Col>

          {/* ---- VINCOLI BANCALI ---- */}
          <Col>
            <Row><h4>Vincoli Bancali</h4></Row>
            <Row style={{marginTop: "30px"}}>
              <RenderField {...formFields["flg_bancali_completi"]} disabled={formDisabled}/>
              <RenderField {...formFields["flg_2_referenze_per_bancale"]} disabled={formDisabled}/>
            </Row>
          </Col>
        </Row>

        <Row className={"mt-4"}>
          {/* ---- RANGE ORDINE --- */}
          <Col lg={4}>
            <Row><h4>Range Ordine</h4></Row>
            <Row>
              <RenderField {...formFields["val_ordine"]} disabled={formDisabled}/>
              <RenderField
                {...formFields["um_ordine"]}
                disabled={formDisabled}
                required={isUmRangeOrdineRequired}/>
            </Row>
          </Col>

          {/* ---- FORNITORE ESTERO --- */}
          <Col className={"ml-4"}>
            <Row>
              <h4>Estero</h4>
              <RenderField {...formFields["flg_fornitore_estero"]} disabled={formDisabled}/>
            </Row>
            <Row>
              <RenderField
                {...formFields["val_estero_peso"]}
                disabled={fornitoreEsteroDisabled}
                required={isFornitoreEsteroRequired}
              />
              <RenderField
                {...formFields["val_estero_ordine"]}
                disabled={fornitoreEsteroDisabled}
                required={isFornitoreEsteroRequired}
              />
              <RenderField
                {...formFields["um_estero_ordine"]}
                disabled={fornitoreEsteroDisabled}
                required={true}
              />
            </Row>
          </Col>
        </Row>

        <Row className={"mt-4"}>
          {/* ---- ARROTONDA DIFETTO --- */}
          <Col lg={4}>
            <Row>
              <h4>Arrotonda Difetto</h4>
              <RenderField {...formFields["flg_arrotonda_difetto"]} disabled={formDisabled}/>
            </Row>
            <Row>
              <RenderField
                {...formFields["val_arrotonda_difetto"]}
                disabled={arrotondaDifettoDisabled}
                required={isArrotondaDifettoRequired}
              />
              <RenderField
                {...formFields["um_arrotonda_difetto"]}
                disabled={arrotondaDifettoDisabled}
                required={true}
              />
            </Row>
          </Col>

          {/* ---- REGOLA ALTERNATIVA --- */}
          <Col className={"ml-4"}>
            <Row>
              <h4>Regola Alternativa</h4>
              <RenderField {...formFields["flg_fornitore_regola_alternativa"]} disabled={formDisabled}/>
            </Row>
            <Row>
              <RenderField
                {...formFields["val_regola_alternativa_peso"]}
                disabled={fornitoreRegolaAlternativaDisabled}
                required={isRegolaAlternativaRequired}
              />
              <RenderField
                {...formFields["val_regola_alternativa"]}
                disabled={fornitoreRegolaAlternativaDisabled}
                required={isRegolaAlternativaRequired}
              />
              <RenderField
                {...formFields["um_regola_alternativa"]}
                disabled={fornitoreRegolaAlternativaDisabled}
                required={true}
              />
            </Row>
          </Col>

        </Row>

        <Row className={"mt-4"}>
          {/* ---- REGOLA ALTERNATIVA IN ARROTONDA DIFETTO --- */}
          <Col lg={4}>
            <Row>
              <h4>Regola Alternativa Difetto</h4>
              <RenderField
                {...formFields["flg_regola_alternativa_in_arrotonda_difetto"]}
                disabled={formDisabled} style={{width: "10px"}}/>
            </Row>
            <Row>
              <RenderField
                {...formFields["val_regola_alternativa_in_arrotonda_difetto"]}
                disabled={regolaAlternativaInArrotondaDifettoDisabled}
                required={isRegolaAlternativaInArrotondaDifettoRequired}
              />
              <RenderField
                {...formFields["um_regola_alternativa_in_arrotonda_difetto"]}
                disabled={regolaAlternativaInArrotondaDifettoDisabled}
                required={true}
              />
            </Row>
          </Col>

          {/* ---- GIORNI CONSEGNA ---- */}
          <Col className={"ml-4 mt-3"}>
            <Row><h4>Giorni di Consegna</h4></Row>
            <Row>
              <RenderField {...formFields["flg_lunedi"]} disabled={formDisabled}/>
              <RenderField {...formFields["flg_martedi"]} disabled={formDisabled}/>
              <RenderField {...formFields["flg_mercoledi"]} disabled={formDisabled}/>
              <RenderField {...formFields["flg_giovedi"]} disabled={formDisabled}/>
              <RenderField {...formFields["flg_venerdi"]} disabled={formDisabled}/>
            </Row>
          </Col>
        </Row>
      </Form>
    )
  }
}


