const NOT_A_NUMBER_ERROR = "Il numero inserito non è valido";
const NEGATIVE_NUMBER_ERROR = "Il numero non può essere negativo";

const handleNumberValidation = (newValue, _, __) => {
  if (isNaN(newValue)) {
    return {
      valid: false,
      message: NOT_A_NUMBER_ERROR,
    };
  } else {
    return true;
  }
};

const handlePositiveNumberValidation = (newValue, _, __) => {
  if (isNaN(newValue)) {
    return {
      valid: false,
      message: NOT_A_NUMBER_ERROR,
    };
  } else if (newValue < 0) {
    return {
      valid: false,
      message: NEGATIVE_NUMBER_ERROR,
    };
  } else {
    return true;
  }
};

export { handleNumberValidation, handlePositiveNumberValidation };
