/*
index.jsx
cir_deploy
Author: Jacopo Gasparetto
Date: 16/07/2020
Organization: Energy Way S.r.l
*/
// import React, { useState } from "react";
import * as React from "react"
import {ToggleButton, ToggleButtonGroup} from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.css';


const ButtonList = (props) => {

  const value = props.selected

  return (

    <ToggleButtonGroup
      className={props.className}
      type={"checkbox"}
      value={value}
      onChange={props.onChange}
    >
      {props.options.map(option => {
        return (
          <ToggleButton variant="outline-primary" key={option} value={option}>
            {option}
          </ToggleButton>)
      })}
    </ToggleButtonGroup>
  )
}

export default ButtonList;