import React, { Component } from "react";
import { Subscribe } from "unstated";

export default class BaseComponent extends Component {
  static withStates(states) {
    return React.forwardRef((props, ref) => (
      <Subscribe to={states}>
        {(...states) => <this {...props} ref={ref} states={states} />}
      </Subscribe>
    ));
  }
}
