import React from "react";
import BaseComponent from "../../components/BaseComponent";
import {Col, Container, Jumbotron, Row} from "react-bootstrap";
import LocalizedDayInputPicker from "../../components/LocalizedDayInputPicker";
import AlertService from "../../services/AlertService";
import {faCubes, faBell} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import _ from "lodash";
import {AlertUnderStockTable, AlertOverStockTable} from "../../components/Tables/AlertTables";
import {AmPmSelector} from "../../components/Misc/AmPmSelector";

class InventoryAlert extends BaseComponent {

  constructor(props) {
    super(props);

    this.alertService = this.props.states[0];
  }

  componentDidMount() {

    if (
      !_.isEmpty(this.alertService.state.data.riordiniUrgenti) &&
      !_.isEmpty(this.alertService.state.data.prodottiEsubero)
    ) {
      return;
    }
    this.alertService.fetchLastAvailableData();
  }

  render() {

    const {riordiniUrgenti, prodottiEsubero} = this.alertService.state.data
    const lastAvailableDate = this.alertService.lastAvailableDate

    console.log('UNDER', riordiniUrgenti)
    console.log('OVER', prodottiEsubero)

    return (
      <Container fluid className={"mt-4"}>
        <Jumbotron className={"py-3"}>
          <h1>Inventory Optimization</h1>
          <span>Riordini urgenti e prodotti in esubero</span>
        </Jumbotron>

        <Row className="text-center">
          <Col className="text-center">
            <h5>Dati Al</h5>
            <LocalizedDayInputPicker
              value={new Date(this.alertService.datiAl)}
              onDayChange={day => this.alertService.onChangeDatiAl(day)}
              lastAvailableDate={lastAvailableDate ? new Date(lastAvailableDate) : new Date()}
              disabledDays={this.alertService.disabledDays}
            />
            <AmPmSelector
              onChange={e => this.alertService.onChangeRunType(e.target.id)}
              runType={this.alertService.runType}
              isAMDisable={this.alertService.isAMDisabled}
              isPMDisabled={this.alertService.isPMDisabled}
            />
          </Col>
        </Row>
        <Row>
          <Col lg={7} className="text-center">
            <h3 style={{color: '#505050'}}>
              <FontAwesomeIcon icon={faBell}/>
            </h3>
            <h3>Riordini Urgenti</h3>
            <AlertUnderStockTable
              data={riordiniUrgenti}
            />
          </Col>
          <Col lg={5} className="text-center">
            <h3 style={{color: '#505050'}}>
              <FontAwesomeIcon icon={faCubes}/>
            </h3>
            <h3>Prodotti in Esubero</h3>
            <AlertOverStockTable
              data={prodottiEsubero}
            />
          </Col>
        </Row>
      </Container>
    )
  }
}

export default InventoryAlert.withStates([AlertService]);