/*
Author: Jacopo Gasparetto
Date: 13/07/2020
Filename: DestinatariTable.jsx
Description:
*/
import React from "react";
import BaseComponent from "../BaseComponent";
import DataEntryService from "../../services/DataEntryService";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUserEdit} from "@fortawesome/free-solid-svg-icons";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, {textFilter, selectFilter} from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import cellEditFactory, {Type} from "react-bootstrap-table2-editor";
import "./tables.module.css";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import ButtonExportCSV from "../Buttons/ExportCSVButton";


const businessOptions = [
  "BUONI PASTO",
  "CENTRI PASTI",
  "FORNITURE ALIMENTARI",
  "IMPRESE",
  "LOCALI COMMERCIALI",
  "N.D.",
  "R.T.I.",
  "SCUOLE",
  "SERVIZI DI PRODUZIONE",
  "SOCIO SANITARIA",
  "UFFICI",
  "UFFICI AREE",
  "UFFICI SEDE"
]

const areaTerritorialeOptions = [
  "CENTRO",
  "CENTRO SUD",
  "DIV. RIST. COM.LE",
  "EMILIA EST",
  "EMILIA OVEST",
  "LOMBARDIA",
  "NORD EST",
  "NORD OVEST",
  "SEDE CENTRALE",
  "N.D."
]

class DestinatariTable extends BaseComponent {

  constructor(props) {
    super(props);
    [this.dataentryService] = this.props.states;

    this.columns = [
      {
        dataField: "cod_destinatario_alt",
        text: "ID Destinatario",
        filter: textFilter({
          placeholder: 'Cerca Destinatario'
        }),
        editable: false,
        headerStyle: {width: '15%'},
        sort: true
      },
      {
        dataField: "ragione_sociale",
        text: "Ragione Sociale",
        filter: textFilter({
          placeholder: 'Cerca Ragione Sociale'
        }),
        editable: false,
        headerStyle: {width: '21%'},
        sort: true
      },
      {
        dataField: "localita",
        text: "Località",
        filter: textFilter({
          placeholder: 'Cerca Località'
        }),
        editable: false,
        headerStyle: {width: '18%'},
        sort: true
      },
      {
        dataField: "provincia",
        text: "Provincia",
        filter: textFilter({
          placeholder: 'Cerca Provincia'
        }),
        editable: false,
        headerStyle: {width: '15%'},
        sort: true
      },
      {
        dataField: "area_territoriale",
        text: "Area Territoriale",
        filter: selectFilter({
          options: areaTerritorialeOptions.reduce((r, el) => {
            r[el] = el;
            return r
          }, {}),
          placeholder: 'Seleziona Area'
        }),
        editor: {
          type: Type.SELECT,
          options: areaTerritorialeOptions.map(el => {
            return {value: el, label: el}
          })
        },
        formatter: this.editableCellFormatter,
        headerStyle: {width: '15%'},
        sort: true
      },
      {
        dataField: "business",
        text: "Business",
        filter: selectFilter({
          options: businessOptions.reduce((r, el) => {
            r[el] = el;
            return r
          }, {}),
          placeholder: 'Seleziona Business'
        }),
        editor: {
          type: Type.SELECT,
          options: businessOptions.map(el => {
            return {value: el, label: el}
          })
        },
        formatter: this.editableCellFormatter,
        headerStyle: {width: '16%'},
        sort: true
      }
    ]
  }


  /* -------- Life cycle ------- */

  async componentDidMount() {
    try {
      await this.dataentryService.getDestinatari();
    } catch (err) {
      console.log("err:", err);
    }
  }

  /* -------- Handlers ------- */

  editableCellFormatter(cell) {
    return (
      <span>
                <span className="text-primary">
                  <FontAwesomeIcon icon={faUserEdit} size="xs"/>
                </span>
        {" "}{cell}
            </span>
    );
  }

  handleAfterSaveCell(oldValue, newValue, row) {
    if (String(newValue) !== String(oldValue)) {
      this.dataentryService.addModifiedDestinatario(row);

    }
  }

  /* -------- Rendering ------- */

  render() {
    return (
      <ToolkitProvider
        keyField={"cod_destinatario_alt"}
        data={this.dataentryService.state.lkp_destinatari.data}
        columns={this.columns}
        pagination={paginationFactory()}
        exportCSV={{
          fileName: 'destinatari.csv',
          separator: ';',
          onlyExportFiltered: true
        }}
      >
        {props => (
          <div>
            <BootstrapTable
              { ... props.baseProps }
              bootstrap4
              filter={filterFactory()}
              pagination={paginationFactory()}
              cellEdit={
                cellEditFactory({
                  mode: "click",
                  blurToSave: true,
                  afterSaveCell: this.handleAfterSaveCell.bind(this)
                })
              }
            />
            <ButtonExportCSV
                {...props.csvProps}
                disabled={this.dataentryService.state.lkp_destinatari.data.length === 0}
                className={"mb-4"}
              >
                  Esporta CSV
              </ButtonExportCSV>
          </div>
        )}
      </ToolkitProvider>
    );
  }
}

export default DestinatariTable.withStates([DataEntryService]);
