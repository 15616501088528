export const numberFormatter = (number, separator = ".") =>
  number
    .toString()
    .replace(".", ",")
    .replace(/\B(?=(\d{3})+(?!\d))/g, separator);

export const dateTostring = (date) => {
  const mm = date.getMonth() + 1;
  const dd = date.getDate();
  const yyyy = date.getFullYear();

  return `${yyyy}-${(mm < 9 && "0") + mm}-${(dd < 9 && "0") + dd}`;
};


export const csvFloatFormatter = (cell) => {
  if (cell) {
    return numberFormatter(cell.toFixed(2), ".")
  } else {
    return ''
  }
};