/*
AdminService.js
cir_deploy
Author: Jacopo Gasparetto
Date: 22/09/2020
Organization: Energy Way S.r.l
*/
import {Container} from "unstated";
import ApiService from "./ApiService";
import {routes} from "../Utils/constants";

export default class AdminService extends Container {

  defaultUsersState = {
    selectedUsers: [],
    usersToUpdate: [],
    usersToDelete: [],
    usersToAdd: [],
  }

  state = {
    users: [],
    ...this.defaultUsersState
  }

  fetchUsers() {
    ApiService.getEntries("auth", "users")
      .then(users => this.setState({
        users: users,
        ...this.defaultUsersState
      }))
  }


  addUser(user) {
    return ApiService.postData(routes["addUser"], user)
      .then(_ => {
        this.users = [...this.users, user]
      })
      .catch(err => {throw err})
  }

  deleteUsers() {
    const emails = this.state.selectedUsers.map(user => user.email)
    this.setState({
      users: this.state.users.filter(user => !emails.includes(user.email)),
      selectedUsers: [],
      usersToDelete: this.state.selectedUsers
    })
  }

  updateUser(oldValue, newValue, row) {
    let users = this.state.users;
    let userToUpdate = users.filter(el => el.email === row.email)[0]
    userToUpdate.user_group = newValue

    this.setState({
      users: users,
      usersToUpdate: [...this.state.usersToUpdate, userToUpdate]
    })
  }

  save() {
    const data = {
      usersToUpdate: this.state.usersToUpdate,
      usersToDelete: this.state.usersToDelete
    }

    ApiService.postData(routes.modifyUsers, data)
      .then( _ => this.fetchUsers())
  }

  cancel() {
    this.fetchUsers()
  }

  get users() {
    return this.state.users
  }

  set users(users) {
    this.setState({users: users})
  }

  get selectedUsers() {
    return this.state.selectedUsers
  }

  set selectedUsers(selectedUsers) {
    this.setState({selectedUsers: selectedUsers, isChanged: true})
  }

  get isChanged() {
    return this.state.usersToUpdate.length > 0 || this.state.usersToAdd.length > 0 || this.state.usersToDelete.length > 0
  }
}