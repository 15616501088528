/*
AmPmSelector.jsx
cir_deploy
Author: Jacopo Gasparetto
Date: 08/09/2020
Organization: Energy Way S.r.l
*/


import {Form} from "react-bootstrap";
import React from "react";

export const AmPmSelector = (props) => {
  return (
    <Form.Group className={"my-group mt-2"}>
      <Form.Check
        inline type={"radio"}
        label={"Mattina"}
        id={"am"}
        name={"selectionRun"}
        onChange={props.onChange}
        checked={props.runType === "am"}
        disabled={props.isAMDisabled}
      />
      <Form.Check
        inline type={"radio"}
        label={"Pomeriggio"}
        id={"pm"}
        name={"selectionRun"}
        onChange={props.onChange}
        checked={props.runType === "pm"}
        disabled={props.isPMDisabled}
      />
    </Form.Group>
  )
}