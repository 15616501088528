import BootstrapTable from "react-bootstrap-table-next";
import React, {Component} from "react";
import "./singleColumnTable.css"
import filterFactory, {textFilter} from "react-bootstrap-table2-filter";

// noinspection JSCheckFunctionSignatures
class SingleColumnTable extends Component {

  columns = [
    {
      dataField: this.props.dataField,
      sort: true,
      filter: textFilter({
        placeholder: `Cerca ${this.props.text}`
      }),
      headerStyle: () => {
        return {border: 'none'}
      }
    }
  ];

  selectRow = {
    mode: 'checkbox',
    clickToSelect: true,
    hideSelectColumn: true,
    classes: "bg-primary text-white",
    onSelect: this.props.onSelect,
  }

  defaultSorted = [{
    dataField: this.props.dataField,
    order: 'asc'
  }];

  render() {
    let selectedRow = this.selectRow;
    selectedRow.selected = this.props.selected;
    const numSelectedRow = this.props.selected.length > 0 ? this.props.selected.length : this.props.data.length

    this.columns[0].text = this.props.text + ` (${numSelectedRow ? numSelectedRow : 0})`

    return (
      <div>
        <div className={"table-container"}>
          <BootstrapTable
            bootstrap4
            keyField={this.props.dataField}
            columns={this.columns}
            data={this.props.data}
            bordered={false}
            filter={filterFactory()}
            hover
            selectRow={selectedRow}
            defaultSorted={this.defaultSorted}
          />
        </div>
      </div>
    )
  }
}

export default SingleColumnTable;