/*
constants.js
cir_deploy
Author: Jacopo Gasparetto
Date: 07/08/2020
Organization: Energy Way S.r.l
*/

export const DEBUG = true

export const lineChartColors = ["#039be5", "#d81b60", "#5e35b1", "#00897b", "#ffeb3b"];
export const lineChartColorsLight = ["#4fc3f7", "#f06292", "#9575cd", "#4db6ac", "#fff176"];
export const lineChartColorsSuperLight = ["#94cfea", "#f39fbb", "#bba9d9", "#81b8b3", "#fff176"];


// Api URL
export const API_URL =
    !process.env.NODE_ENV || process.env.NODE_ENV === "development"
      ? "/api"
      : "https://inventory-backend.cirfood.cloud.ammagamma.com";


// Routes
export const routes = {
  addUser: "/auth/addUser",
  modifyUsers: "/auth/modifyUsers",
  changePassword: "/auth/changePassword",
  recoverPassword: "/auth/recoverPassword"
}