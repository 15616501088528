import React from "react";

import BaseComponent from "../BaseComponent";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faUserEdit,
  faExclamationTriangle,
} from "@fortawesome/free-solid-svg-icons";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import cellEditFactory, {Type} from "react-bootstrap-table2-editor";
import "./tables.module.css";
import {csvFloatFormatter} from "../../Utils";
import * as Validator from "../../Utils/validator.utils";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import ButtonExportCSV from "../Buttons/ExportCSVButton";

class BancalizzazioneTable extends BaseComponent {
  constructor(props) {
    super(props);

    this.columns = [
      {
        dataField: "sotto_fornitore",
        text: "Sottofornitore",
        formatter: this.editableCell,
        editor: {
          type: Type.SELECT,
          options: this.props.underSupplierList || [],
        },
        headerStyle: {width: "12%"},
      },
      {
        dataField: "cod_articolo_alt",
        text: "Articolo",
        type: "number",
        editable: false,
        headerStyle: {width: "6%"},
      },
      {
        dataField: "des_articolo",
        text: "Descrizione",
        formatter: cell => {return (<span><small>{cell}</small></span>)},
        editable: false,
        headerStyle: {width: "14%"},
      },
      {
        dataField: "val_pezzi_per_imballo",
        text: "Pezzi Imballo",
        type: "number",
        editable: false,
        headerStyle: {width: "4%", paddingLeft: 4, paddingRight: 4, fontSize: 10},
        csvFormatter: csvFloatFormatter
      },
      {
        dataField: "val_imballi_per_strato",
        text: "Imballi Strato",
        type: "number",
        editable: false,
        headerStyle: {width: "4%", padding: 4, fontSize: 10},
        csvFormatter: csvFloatFormatter
      },
      {
        dataField: "val_imballi_per_pallet",
        text: "Imballi Pallet",
        type: "number",
        editable: false,
        headerStyle: {width: "4%", padding: 4, fontSize: 10},
        csvFormatter: csvFloatFormatter
      },
      {
        dataField: "val_kg_per_pezzi",
        text: "Kg Pezzi",
        type: "number",
        editable: false,
        headerStyle: {width: "4%", padding: 4, fontSize: 10},
        csvFormatter: csvFloatFormatter
      },
      {
        dataField: "val_kg_per_imballo",
        text: "Kg Imballo",
        type: "number",
        editable: false,
        headerStyle: {width: "4%", padding: 4, fontSize: 10},
        csvFormatter: csvFloatFormatter
      },
      {
        dataField: "val_kg_per_strato",
        text: "Kg Strato",
        type: "number",
        editable: false,
        headerStyle: {width: "4%", padding: 4, fontSize: 10},
        csvFormatter: csvFloatFormatter
      },
      {
        dataField: "val_kg_per_strato_fornitore",
        text: "Kg Strato Custom*",
        type: "number",
        formatter: this.editableCell,
        validator: Validator.handleNumberValidation,
        headerStyle: {width: "4%", padding: 4, fontSize: 10},
        csvFormatter: csvFloatFormatter
      },
      {
        dataField: "val_kg_per_pallet",
        text: "Kg Pallet",
        type: "number",
        editable: false,
        headerStyle: {width: "4%", padding: 4, fontSize: 10},
        csvFormatter: csvFloatFormatter
      },
      {
        dataField: "val_kg_per_pallet_fornitore",
        text: "Kg Pallet Custom*",
        type: "number",
        formatter: this.editableCell,
        validator: Validator.handleNumberValidation,
        headerStyle: {width: "4%", padding: 4, fontSize: 10},
        csvFormatter: csvFloatFormatter
      },
      {
        dataField: "val_kg_minimi_articolo",
        text: "Min Kg",
        type: "number",
        formatter: this.editableCell,
        validator: Validator.handleNumberValidation,
        headerStyle: {width: "4%", padding: 4, fontSize: 10},
        csvFormatter: csvFloatFormatter
      },
      {
        dataField: "val_kg_multiplo_riordino",
        text: "Kg Riordino",
        type: "number",
        formatter: this.editableCell,
        validator: Validator.handleNumberValidation,
        headerStyle: {width: "4%", padding: 4, fontSize: 10},
        csvFormatter: csvFloatFormatter
      },
      {
        dataField: "val_giorni_scadenza",
        text: "Giorni Scad.",
        type: "number",
        editable: false,
        headerStyle: {width: "4%", padding: 4, fontSize: 10},
        csvFormatter: csvFloatFormatter
      },
      {
        dataField: "val_shelf_life_entrata",
        text: "SL Entrata",
        type: "number",
        editable: false,
        headerStyle: {width: "4%", padding: 4, fontSize: 10},
        csvFormatter: csvFloatFormatter
      },
      {
        dataField: "val_shelf_life_uscita",
        text: "SL Uscita",
        type: "number",
        editable: false,
        headerStyle: {width: "4%", padding: 4, fontSize: 10},
        csvFormatter: csvFloatFormatter
      },
      {
        dataField: "val_giorni_safety",
        text: "Gg.Lav. Safety",
        type: "number",
        formatter: this.editableCell,
        validator: Validator.handleNumberValidation,
        headerStyle: {width: "4%", padding: 4, fontSize: 10},
        csvFormatter: csvFloatFormatter
      },
      {
        dataField: "val_kg_safety_aggiuntiva",
        text: "Kg Safety Aggiuntiva",
        type: "number",
        formatter: this.editableCell,
        validator: Validator.handleNumberValidation,
        headerStyle: {width: "4%", padding: 4, fontSize: 10},
        csvFormatter: csvFloatFormatter
      }
    ];

    this.defaultSorted = [
      {
        dataField: "cod_articolo_alt",
        order: "asc",
      },
    ];
  }

  /* -------- Handlers ------- */

  handleAfterSaveCell(oldValue, newValue, row, column) {
    if (String(newValue) !== String(oldValue)) {
      const Service = this.props.handler;

      const {
        cod_fornitore_alt,
        cod_articolo_alt,
        ragione_sociale,
        sotto_fornitore,
      } = row;

      const supplierKey = `${cod_fornitore_alt} - ${ragione_sociale}`;
      const productKey = `${sotto_fornitore} - ${cod_articolo_alt}`;
      const fullKey = [supplierKey, productKey];

      if (!Service.getField(fullKey)) {
        const oldUnderSupplierName = oldValue;
        delete Service.state.data[supplierKey][
          `${oldUnderSupplierName} - ${cod_articolo_alt}`
          ];
      }

      Service.setField(fullKey, row);
    }
  }

  handleNoDataIndication() {
    return (
      <p>
        <FontAwesomeIcon icon={faExclamationTriangle}/> Nessun prodotto
        trovato!
      </p>
    );
  }

  /* -------- Formatters ------- */

  editableCell(cell) {
    return (
      <span>
        <span className="text-primary">
          <FontAwesomeIcon icon={faUserEdit} size="xs"/>
        </span>{" "}
        {cell}
      </span>
    );
  }

  /* -------- Rendering ------- */

  render() {
    return (
      <ToolkitProvider
        keyField="cod_articolo_alt"
        data={this.props.data}
        columns={this.columns}
        exportCSV={{
          fileName: 'bancalizzazione.csv',
          separator: ';'
        }}
      >
        {props => (
          <div>
            <BootstrapTable
              bootstrap4
              { ... props.baseProps }
              pagination={paginationFactory()}
              defaultSorted={this.defaultSorted}
              cellEdit={cellEditFactory({
                mode: "click",
                blurToSave: true,
                afterSaveCell: this.handleAfterSaveCell.bind(this),
              })}
              rowStyle={{fontSize: "small"}}
              headerClasses={"small"}
              noDataIndication={this.handleNoDataIndication()}
            />
            <ButtonExportCSV
              {...props.csvProps}
              disabled={this.props.data.length === 0}
            >
              Esporta CSV
            </ButtonExportCSV>
          </div>
        )
        }

      </ToolkitProvider>
    );
  }
}

export default BancalizzazioneTable;
