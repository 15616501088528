/*
bancalizzazione.jsx
cir_deploy
Author: Jacopo Gasparetto
Date: 18/09/2020
Organization: Energy Way S.r.l
*/
import React from "react";
import BaseComponent from "../../components/BaseComponent";
import JumbotronDataEntry from "../../components/Jumbotron/DataEntry";
import PalletArticleService from "../../services/DataEntryService/PalletArticleService";
import PalletSupplierService from "../../services/DataEntryService/PalletSupplierService";
import {Typeahead} from "react-bootstrap-typeahead";
import {Col, Row, Container, InputGroup} from "react-bootstrap";
import ModalNewUnderSupplier from "../../components/Modals/ModalNewUnderSupplier";
import ButtonCancelEntry from "../../components/Buttons/ButtonCancelEntry";
import {LoadingSpinner} from "../../components/Misc/LoadingSpinner";
import {FromBancalizzazione} from "../../components/Forms/FormBancalizzazione";
import BancalizzazioneTable from "../../components/Tables/BancalizzazioneTable";

const newUnderSupplierLabel = "Aggiungi nuovo sottofornitore...";

class DataEntryBancalizzazione extends BaseComponent {
  constructor(props) {
    super(props);

    this.palletArticleService = this.props.states[0];
    this.palletSupplierService = this.props.states[1];

    this.state = {
      selectedSupplier: [""],
      selectedUnderSupplier: [""],
      articleList: [],
      ready: false,
      showNewUnderSupplierPopup: false,
    };

    this.innerFormRef = React.createRef();
  }

  /* -------- Component methods ------- */

  async componentDidMount() {
    try {
      await Promise.all([
        this.palletSupplierService.retrieveData(),
        this.palletArticleService.retrieveData(),
      ]);

      this.setState({
        ready: true,
      });
    } catch (err) {
      console.log(err);
    }
  }

  /* -------- Getters ------- */
  getOptionUnderSuppliers() {
    const selectedSupplier = this.state.selectedSupplier;
    if (selectedSupplier) {
      let underSuppliersList = this.palletSupplierService.getUnderSupplierNames(
        selectedSupplier
      );
      underSuppliersList.push(newUnderSupplierLabel);
      return underSuppliersList;
    } else {
      return [];
    }
  }

  /* -------- Handlers ------- */

  toggleNewUnderSupplierPopup() {
    this.setState({
      showNewUnderSupplierPopup: !this.state.showNewUnderSupplierPopup,
    });
  }

  handleOnNewSupplierSelected(underSupplierName) {
    this.setState({
      selectedUnderSupplier: [underSupplierName],
    });
  }

  handleOnChangeSupplier(selected) {
    this.setState({selectedSupplier: selected, selectedUnderSupplier: [""]});
  }

  async handleOnChangeUnderSupplier(selected) {
    if (selected[0] === newUnderSupplierLabel) {
      this.toggleNewUnderSupplierPopup();
      return;
    }

    if (selected[0]) {
      await this.setState({
        selectedUnderSupplier: selected,
        articleList: this.palletArticleService.retrieveSpecificData(this.state.selectedSupplier[0], selected[0])
      });
    } else {
      this.setState({
        selectedUnderSupplier: selected,
      });
    }
  }

  handleOnSave() {
    console.log("submitting")
    this.innerFormRef.current.submit();
    const form = this.innerFormRef.current.formRef;
    const isFormValid = form.checkValidity()
    if (!isFormValid) {
      console.log("Form not valid")
    } else {
      this.palletSupplierService.updateData()
      this.palletArticleService.updateData()
    }
  }

  /* -------- Helpers ------- */

  isSupplierSelected() {
    try {
      return this.state.selectedSupplier[0].length > 0;
    } catch (err) {
      return false;
    }
  }

  isUnderSupplierSelected() {
    try {
      return (
        this.state.selectedSupplier[0].length > 0 &&
        this.state.selectedUnderSupplier[0].length > 0
      );
    } catch (err) {
      return false;
    }
  }

  getUncompletedArticles(articles) {
    let counter = 0
    articles.forEach(article => {
      if (!(article["sotto_fornitore"] && article["val_kg_minimi_articolo"] && article["val_kg_multiplo_riordino"]))
        counter += 1
    })
    return counter
  }

  getFullFieldName = (fieldName) => {
    if (this.isUnderSupplierSelected()) {
      return [
        `${this.state.selectedSupplier[0]}`,
        `${this.state.selectedUnderSupplier[0]}`,
        `${fieldName}`, // THIS IS DANGEROUS. If modify this, you must change also RangeMinMaxField.
      ];
    }
  };


  render() {
    if (!this.state.ready) return (
      <Container className={"vh-100"}>
        <LoadingSpinner/>
      </Container>
    );

    const isFormDisabled = !this.isUnderSupplierSelected();

    return (
      <>
        {this.state.selectedSupplier[0] && (
          <ModalNewUnderSupplier
            show={this.state.showNewUnderSupplierPopup}
            toggle={this.toggleNewUnderSupplierPopup.bind(this)}
            selectedSupplier={this.state.selectedSupplier[0]}
            underSupplierOptions={this.palletSupplierService.getUnderSupplierNames(this.state.selectedSupplier[0])}
            handleOnSave={this.palletSupplierService.addNewUnderSupplier.bind(this)}
            handleAfterSave={this.handleOnNewSupplierSelected.bind(this)}
          />
        )}

        <Container fluid className="mt-3">
          <JumbotronDataEntry
            title="Anagrafica Bancali"
            isSaved={!this.palletSupplierService.isDataChanged() && !this.palletArticleService.isDataChanged()}
            lastUpdate={this.palletSupplierService.getLastUpdate()}
            handleOnSave={_ => this.handleOnSave()}
          />

          <Row className="px-3 pb-2 justify-content-center">
            {/* ---- COUNTER ---- */}
            <Col lg={2} className={"px-5 py-2 text-left"}>
              N. Sottofornitori incompleti:
              <b> {this.palletSupplierService.getUncompletedUnderSuppliers(this.state.selectedSupplier[0])}</b>
            </Col>

            {/* ---- SUPPLIER/UNDER-SUPPLIER SELECTORS ---- */}
            <Col lg={5} className="px-5 py-2">
              <h5><strong>Seleziona il Fornitore:</strong></h5>
              <InputGroup>
                <InputGroup.Prepend>
                  <ButtonCancelEntry
                    disabled={!this.isSupplierSelected()}
                    onClick={_ => this.setState({selectedSupplier: [""], selectedUnderSupplier: [""]})}
                  />
                </InputGroup.Prepend>
                <Typeahead
                  id="select-supplier"
                  onChange={this.handleOnChangeSupplier.bind(this)}
                  options={this.palletSupplierService.getSupplierCodeNames()}
                  selected={this.state.selectedSupplier}
                  placeholder="Ricerca per codice o ragione sociale"
                  disabled={this.isSupplierSelected()}
                />
              </InputGroup>
            </Col>
            <Col lg={5} className="px-5 py-2">
              <h5>
                <strong>Seleziona il Sottofornitore:</strong>
              </h5>
              <InputGroup>
                <InputGroup.Prepend>
                  <ButtonCancelEntry
                    disabled={!this.isUnderSupplierSelected()}
                    onClick={_ => this.setState({selectedUnderSupplier: [""],})}
                  />
                </InputGroup.Prepend>
                <Typeahead
                  id="select-under-supplier"
                  onChange={this.handleOnChangeUnderSupplier.bind(this)}
                  options={this.getOptionUnderSuppliers()}
                  selected={this.state.selectedUnderSupplier}
                  disabled={this.isUnderSupplierSelected() || !this.isSupplierSelected()}
                  placeholder="Ricerca per nome"
                />
              </InputGroup>
            </Col>
          </Row>

          <hr/>

          <FromBancalizzazione
            ref={this.innerFormRef}
            selectedSupplier={this.state.selectedSupplier[0]}
            selectedUnderSupplier={this.state.selectedUnderSupplier[0]}
            service={this.palletSupplierService}
            formDisabled={isFormDisabled}
          />

          <hr/>
          {!isFormDisabled && (
            <div>
              <Row className="px-3 mt-4 pb-2">
                <Col xs={2.5} className={"align-middle"}>
                  <h4 style={{margin: 0}}>Elenco Prodotti</h4>
                </Col>
                <Col lg={"auto"} className={"align-middle"} style={{marginTop: "3px", marginBottom: 0}}>
                  (incompleti: <b>{this.getUncompletedArticles(this.state.articleList)}</b>)
                </Col>
                <Col lg={"auto"} className={"align-middle"} style={{marginTop: "8px", marginBottom: 0}}>
                  <p style={{fontSize: 12}}>* Queste colonne, se valorizzate, sostituiscono i valori di default provenienti dall'anagrafica articoli</p>
                </Col>

              </Row>
              <BancalizzazioneTable
                data={this.state.articleList}
                underSupplierList={this.palletSupplierService.getUnderSupplierOptions(
                  this.state.selectedSupplier[0]
                )}
                handler={this.palletArticleService}
              />
            </div>
          )}

        </Container>
      </>
    )
  }
}

export default DataEntryBancalizzazione.withStates([PalletArticleService, PalletSupplierService]);
