/*
DettaglioSelector.jsx
cir_deploy
Author: Jacopo Gasparetto
Date: 05/08/2020
Organization: Energy Way S.r.l
*/
import {Form} from "react-bootstrap";
import React from "react";

const DetailSelector = (props) => {
  return (
    <Form.Group className={"my-0 my-group"}>
      <Form.Check
        inline type={"radio"}
        label="Giorno"
        id="daily"
        name={"selectionPeriod"}
        defaultChecked
        onChange={props.onChange}
      />
      <Form.Check
        inline
        type={"radio"}
        label="Settimana"
        id="weekly"
        name={"selectionPeriod"}
        onChange={props.onChange}
      />
    </Form.Group>
  )
}

export default DetailSelector;