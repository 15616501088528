import ModelService from "../ModelService";
import ApiService from "../ApiService";
import _ from "lodash";

export default class PalletArticleService extends ModelService {
  constructor() {
    super();

    this.updateData = this.updateData.bind(this);
  }

  async retrieveData() {
    try {
      const palletsArticles = await ApiService.getEntries("pallets/articles");

      const newArticles = {};

      palletsArticles.forEach((art) => {
        const supplierKey = `${art["cod_fornitore_alt"]} - ${art["ragione_sociale"]}`;
        const underSupplierKey = art["sotto_fornitore"] || "null";
        const articleKey = art["cod_articolo_alt"];

        newArticles[supplierKey] = {
          ...newArticles[supplierKey],
          [`${underSupplierKey} - ${articleKey}`]: art,
        };
      });

      this.setData(newArticles);
      this.setImmutableData(_.cloneDeep(newArticles));

      return palletsArticles;
    } catch (err) {
      throw err;
    }
  }

  async updateData() {
    if (this.isDataChanged()) {
      const changedData = this.getChangedData();
      _.keys(changedData).forEach((supplierKey) => {
        const supplierChangedData = changedData[supplierKey];

        _.keys(supplierChangedData).forEach((articleKey) => {
          const actualChangedData = supplierChangedData[articleKey];

          const sotto_fornitore = articleKey.split(" - ")[0];
          const cod_articolo_alt = articleKey.split(" - ")[1];

          actualChangedData["cod_fornitore_alt"] = supplierKey.split(" - ")[0];
          actualChangedData["sotto_fornitore"] = sotto_fornitore;

          ApiService.updateEntry(
            "pallets/articles",
            cod_articolo_alt,
            actualChangedData
          );
        });

        this.setImmutableData(_.cloneDeep(this.getData()));
      });
    }
  }

  /* --------- Custom methods --------- */

  retrieveSpecificData(supplier, underSupplier) {
    try {
      const data = [];

      const supplierProducts = this.getData()[supplier];
      _.keys(supplierProducts)
        .filter(
          (o) =>
            o.split(" - ")[0] === underSupplier || o.split(" - ")[0] === "null"
        )
        .forEach((o) => data.push(supplierProducts[o]));

      return data;
    } catch (err) {
      console.debug(
        `[WARN][PalletArticleService.retrievespecificData] - ${supplier} and/or ${underSupplier} not found`
      );
      return [];
    }
  }
}
