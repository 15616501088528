import { Container } from "unstated";
import ApiService from "./ApiService";
import moment from "moment";

export default class AlertService extends Container {

  /* -------- Init ------- */

  serviceType = "inventoryOptimization"
  state = {
    // DATA
    data: {
      riordiniUrgenti: [],
      prodottiEsubero: [],
    },

    // UTILITIES
    datiAl: undefined,
    isLoading: undefined,
    disabledDays: []
  };

  /* -------- Handlers ------- */

  fetchLastAvailableData() {
    const today = moment().format("YYYY-MM-DD")

    const params = `datesAvailability?dati_al=${today}`
    ApiService.getEntries(this.serviceType, params).then(data => {
      const lastAvailableDate = moment(data["last_available_date"]).format("YYYY-MM-DD")
      const notAvailableDates = data["not_available_dates"].map(d => {return new Date(d)})

      const amPm = data["not_available_runs"][lastAvailableDate] === 'pm' ? 'am' : 'pm'

      this.setState({
        datiAl: lastAvailableDate,
        notAvailableRuns: data["not_available_runs"],
        lastAvailableDate: lastAvailableDate,
        disabledDays: notAvailableDates,
        runType: amPm
      })
        .then(_ => this.fetchData(this.state.datiAl, amPm))
    })
      .catch(err => console.log(err))
  }

  fetchData(datiAl, runType) {
    console.log("Fetching data for date:", datiAl, "runType:", runType, "...")
    const params = `alert?dati_al=${datiAl}&am_pm=${runType}`
    ApiService.getEntries(this.serviceType, params)
      .then(result => {
          this.setState({
              data: {
                  riordiniUrgenti: result['under'],
                  prodottiEsubero: result['over']
              }
          }).then(() => console.log("All alerts loaded"))

      })
      .catch(error => console.log(error))
  }


  onChangeDatiAl(date) {
    const datiAl = moment(date).format("YYYY-MM-DD")
    this.setState({
      datiAl: datiAl,
      isLoading: true
    }).then(_ =>
      this.fetchData(datiAl)
    )
  }

  onChangeRunType(runType) {
    console.log("Change runType:", runType)
    this.setState({
      runType: runType,
      isLoading: true
    }).then(_ =>
      this.fetchData(this.datiAl, this.runType)
    )
  }
  /* -------- Getters ------- */

  get lastAvailableDate() {
    return this.state.lastAvailableDate
  }

  get disabledDays() {
    return this.state.disabledDays
  }

  get datiAl() {
    return this.state.datiAl
  }

  get runType() {
    return this.state.runType
  }

  get isAMDisabled() {
    if (this.state.notAvailableRuns) {
      return this.state.notAvailableRuns[this.state.datiAl] === "am"
    }
    return true
  }

  get isPMDisabled() {
    if (this.state.notAvailableRuns) {
      return this.state.notAvailableRuns[this.state.datiAl] === "pm"
    }
    return true
  }
}
